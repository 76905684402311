import { DeviceAlertsProps } from '@api/types';
import { Box, Typography } from '@mui/material';
import { iconSet } from '@assets';
import { Icon } from '@components';
import { ItemInfoContainer } from './ItemInfo.styles';
import moment from 'moment';
import 'moment/locale/pl';

interface ItemInfoProps {
  alert: DeviceAlertsProps;
}

const ItemInfo = ({ alert }: ItemInfoProps) => {
  return (
    <Box>
      <ItemInfoContainer>
        <Box>
          <Typography variant="body2">Godzina</Typography>
        </Box>
        <Box>
          <Typography align={'right'} variant="body1">
            {moment.utc(alert.date).local().format('LTS')}
          </Typography>
        </Box>
      </ItemInfoContainer>

      <ItemInfoContainer>
        <Box>
          <Typography variant="body2">Status</Typography>
        </Box>
        <Box>
          {!alert.isActive ? (
            <Icon iconKey={iconSet.greenAlert} width="32px" height="24px" />
          ) : (
            <Icon iconKey={iconSet.redAlert} width="32px" height="24px" />
          )}
        </Box>
      </ItemInfoContainer>

      <ItemInfoContainer>
        <Box>
          <Typography variant="body2">Treść</Typography>
        </Box>
        <Box>
          {alert.errors.map((error, i) => {
            return (
              <Typography key={i} align={'right'} variant="body1">
                {error}
              </Typography>
            );
          })}
        </Box>
      </ItemInfoContainer>
    </Box>
  );
};

export default ItemInfo;
