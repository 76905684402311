import { UseMutationResult, useMutation } from 'react-query';
import { AxiosResponse } from 'axios';
import { SnackbarActionType, SnackbarContext, SnackbarType } from '@contexts';
import { useContext } from 'react';

import HttpServiceError from '../../../services/httpService/types';
import { queryClient } from '../../../react-query';
import { activateLocator, IActivateLocator } from '../infrastructure/activate-locator';

export const useActivateLocator = (): UseMutationResult<AxiosResponse, HttpServiceError, IActivateLocator> => {
  const { snackbarDispatch } = useContext(SnackbarContext);

  return useMutation<AxiosResponse, HttpServiceError, IActivateLocator>({
    mutationFn: activateLocator,
    onError: (error: HttpServiceError) => {
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: error.response?.data.title || 'Wystąpił błąd podczas aktywacji lokatora',
          type: SnackbarType.ERROR,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['locators']);
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: 'Akcja wykonana pomyślnie',
          type: SnackbarType.SUCCESS,
        },
      });
    },
  });
};
