const isStrigifiedObject = (item: string) => item.charAt(0) === '{' && item.charAt(item.length - 1) === '}';

const getItem = <T>(key: string): T | null => {
  const storedItem = window.localStorage.getItem(key);
  if (!storedItem) return null;
  if (isStrigifiedObject(storedItem)) return JSON.parse(storedItem) as T;
  return null;
};

const setItem = (key: string, item: unknown): void => {
  if (!item || !key) return;
  window.localStorage.setItem(key, typeof item === 'string' ? item : JSON.stringify(item));
};

export { getItem, setItem };
