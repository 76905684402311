import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { Input, Snackbar } from '@components';
import { ModalActionType, ModalContext, UserProps } from '@contexts';
import { useEditClient, useEditLocator } from '@api';
import React, { useContext } from 'react';
import { ConfirmLocatorEmailChangeModal } from './confirm-locator-email-change.modal';

interface IFormProfileInput {
  name: string;
  surname: string;
  email: string;
}

const profileValidationSchema = yup.object().shape({
  name: yup.string().max(255).required('Pole obowiązkowe'),
  surname: yup.string().max(255).required('Pole obowiązkowe'),
  email: yup.string().email('Niepoprawny e-mail').required('Pole obowiązkowe'),
});

interface UserProfileProps {
  user: UserProps;
  variant: 'client' | 'locator';
}

export const UserProfileForm = ({ user, variant }: UserProfileProps): JSX.Element => {
  const theme = useTheme();
  const { modalDispatch } = useContext(ModalContext);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm<IFormProfileInput>({
    mode: 'onBlur',
    defaultValues: {
      name: user.name || '',
      surname: user.surname || '',
      email: user.email || '',
    },
    resolver: yupResolver(profileValidationSchema),
  });

  const { mutateAsync: editClient } = useEditClient();
  const { mutateAsync: editLocator } = useEditLocator();

  const onSubmit: SubmitHandler<IFormProfileInput> = async ({ name, surname, email }) => {
    if (isValid) {
      switch (variant) {
        case 'client':
          try {
            await editClient({
              clientId: user.id,
              name,
              surname,
              email,
            });
          } catch (e) {
            console.log(e);
          }
          break;
        case 'locator':
          if (user.email !== email) {
            modalDispatch({
              type: ModalActionType.SHOW,
              payload: {
                content: (
                  <ConfirmLocatorEmailChangeModal
                    onAccept={async () => {
                      try {
                        await editLocator({
                          locatorId: user.id,
                          name,
                          surname,
                          email,
                        });
                        modalDispatch({
                          type: ModalActionType.DISMISS,
                        });
                      } catch (e) {
                        console.log(e);
                      }
                    }}
                    onCancel={() => {
                      modalDispatch({
                        type: ModalActionType.DISMISS,
                      });
                    }}
                  />
                ),
              },
            });
            return;
          }
          try {
            await editLocator({
              locatorId: user.id,
              name,
              surname,
              email,
            });
          } catch (e) {
            console.log(e);
          }

          break;
      }
    }
    reset({
      name,
      surname,
      email,
    });
  };

  return (
    <Box>
      <form key={'profile'} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '20px',
            marginBottom: '20px',
          }}
        >
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                error={!!errors.name}
                helperText={errors.name && errors.name?.message && errors.name.message}
                label="Imię"
                autoComplete="Imię"
                ref={null}
              />
            )}
          />
          <Controller
            name="surname"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                error={!!errors.surname}
                helperText={errors.surname && errors.surname?.message && errors.surname.message}
                label="Nazwisko"
                autoComplete="Nazwisko"
                ref={null}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                error={!!errors.email}
                helperText={errors.email && errors.email?.message && errors.email.message}
                label="E-mail"
                autoComplete="E-mail"
                ref={null}
              />
            )}
          />
          <Input
            label="Rola"
            value={variant === 'client' ? 'Klient' : 'Lokator'}
            ref={null}
            disabled={true}
            sx={{
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: theme.palette.grey[800],
              },
            }}
          />
          <Button
            variant="contained"
            sx={{
              borderRadius: '8px',
            }}
            type="submit"
            disabled={!isDirty || !isValid}
          >
            <Typography> Aktualizuj profil </Typography>
          </Button>
        </Box>
      </form>
      <Snackbar />
    </Box>
  );
};
