import { useQuery } from 'react-query';

import { ILocationMetersParams } from '../infrastructure/model';
import { getLocationMeters } from '../infrastructure/use-get-locations-meters';

export const useGetLocationMeters = (params: ILocationMetersParams) => {
  return useQuery({
    queryFn: () => getLocationMeters(params),
    queryKey: ['location-meter', params],
    keepPreviousData: true,
  });
};
