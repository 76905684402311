import { TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { iconSet } from '@assets';
import { Icon } from '@components';
import { useContext } from 'react';
import { UserContext } from '@contexts';

export const TableHeader = () => {
  const { userState } = useContext(UserContext);
  return (
    <TableHead>
      <TableRow>
        <TableCell align="left"></TableCell>
        <TableCell align="center">
          <Typography variant="body1" align="center">
            Adres
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Icon iconKey={iconSet.dropBlue} />
          <Typography variant="body1" align="center">
            Woda zimna suma pomiarów
          </Typography>
          {userState.roles === 'Administrator' && (
            <Typography variant="body2" align="center">
              (Data ostatniego pomiaru)
            </Typography>
          )}
        </TableCell>
        <TableCell align="center">
          <Icon iconKey={iconSet.redDrop} />
          <Typography variant="body1" align="center">
            Woda ciepła suma pomiarów
          </Typography>
          {userState.roles === 'Administrator' && (
            <Typography variant="body2" align="center">
              (Data ostatniego pomiaru)
            </Typography>
          )}
        </TableCell>
        <TableCell align="center">
          <Icon iconKey={iconSet.heatMeterRed} />
          <Typography variant="body1" align="center">
            Ogrzewanie suma pomiarów
          </Typography>
          {userState.roles === 'Administrator' && (
            <Typography variant="body2" align="center">
              (Data ostatniego pomiaru)
            </Typography>
          )}
        </TableCell>
        <TableCell align="center">
          <Typography variant="body1" align="center">
            Alert
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
