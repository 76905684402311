import React from 'react';
import { TableBody } from '@mui/material';
import { LocationResult } from '@api';

import { Row } from '../table-row/table-row.component';

interface ITableBodyProps {
  rows: LocationResult[];
}

export const TableBodyComponent = ({ rows }: ITableBodyProps) => {
  return (
    <TableBody>
      {rows.map((row: LocationResult) => (
        <Row key={row.locationId} row={row} />
      ))}
    </TableBody>
  );
};
