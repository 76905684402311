import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';

export interface IAddLocatorRequest {
  name: string;
  surname: string;
  email: string;
}

export const addNewLocator = ({ name, surname, email }: IAddLocatorRequest): Promise<AxiosResponse> => {
  return httpsService.post(Endpoint.ADD_LOCATOR, { name, surname, email });
};
