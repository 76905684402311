import React, { useContext } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Accordion, AccordionSummary, Typography } from '@mui/material';
import { SkeletonLoader } from '@components';
import { useGetCities } from '@api/locations';
import { ModalActionType, ModalContext } from '@contexts';

import { CityAccordion } from './components/city-accordion.component';
import { LocationsListStyles } from './LocationstList.styles';
import { AddCityUpsertForm } from './components/forms/add-city.form';

export const LocationsList = () => {
  const { data: cities, isLoading } = useGetCities();
  const { modalDispatch } = useContext(ModalContext);

  const openAddCityModal = () => {
    modalDispatch({
      type: ModalActionType.SHOW,
      payload: {
        content: <AddCityUpsertForm />,
      },
    });
  };

  return (
    <LocationsListStyles>
      {isLoading || !cities ? (
        <SkeletonLoader loadersCount={1} />
      ) : (
        cities && cities?.data.map((city) => <CityAccordion key={city.id} name={city.name} id={city.id} />)
      )}
      <Accordion disableGutters={true} TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary onClick={openAddCityModal} disableRipple expandIcon={<AddIcon />}>
          <Typography>Dodaj nowe miasto</Typography>
        </AccordionSummary>
      </Accordion>
    </LocationsListStyles>
  );
};
