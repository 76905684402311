import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';

export interface IPatchFlatRequest {
  name: string;
  flatId: string;
}

export const updateFlatName = ({ name, flatId }: IPatchFlatRequest): Promise<AxiosResponse> => {
  return httpsService.patch(Endpoint.FLATS_COMMON, { name, flatId });
};
