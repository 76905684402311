import { Box } from '@mui/material';
import { useGetCustomers } from '@api/customers';

import DataGrid from './DataGrid/DataGrid';

export const CustomerList = () => {
  const { data: customers, isLoading: isCustomerListLoading } = useGetCustomers();

  return (
    <Box>{customers && <DataGrid customers={customers?.data} isCustomerListLoading={isCustomerListLoading} />}</Box>
  );
};
