import { Endpoint } from '@api';
import { httpsService } from '@services';
import { Dispatch, SetStateAction } from 'react';
import { DeviceAlertsProps, Pagination } from '@api/types';

interface DeviceAlerts {
  deviceId: string;
  limit: number;
  offset: number;
  currentData?: DeviceAlertsProps[];
  setDeviceAlertsLoading: Dispatch<SetStateAction<boolean>>;
  setDeviceAlerts: Dispatch<SetStateAction<DeviceAlertsProps[]>>;
  setTotalSize: Dispatch<SetStateAction<number>>;
  type: string;
}

const getDeviceAlerts = async ({
  deviceId,
  limit,
  offset,
  currentData,
  setDeviceAlerts,
  setDeviceAlertsLoading,
  setTotalSize,
  type,
}: DeviceAlerts) => {
  if (!currentData) {
    setDeviceAlerts([]);
  }
  setDeviceAlertsLoading(true);
  try {
    if (type === 'water') {
      const deviceAlerts = await httpsService.get<Pagination<DeviceAlertsProps[]>>(
        `${Endpoint.DEVICE_WATER_ALERTS.replace(':id', deviceId)}/?limit=${limit}&offset=${offset}`
      );

      if (deviceAlerts.status === 200) {
        if (deviceAlerts.data.results.length > 0) {
          const devAlert = deviceAlerts.data.results.map((alert, i) => ({
            ...alert,
            id: `${i}`,
          }));

          setDeviceAlerts(devAlert);
          setTotalSize((prevTotalSize) =>
            deviceAlerts.data.totalSize !== undefined ? deviceAlerts.data.totalSize : prevTotalSize
          );
        }
      } else {
        setDeviceAlerts([]);
        setTotalSize(0);
      }
    } else if (type === 'heater') {
      const deviceAlerts = await httpsService.get<Pagination<DeviceAlertsProps[]>>(
        `${Endpoint.DEVICE_HEATER_ALERTS.replace(':id', deviceId)}/?limit=${limit}&offset=${offset}`
      );

      if (deviceAlerts.status === 200) {
        if (deviceAlerts.data.results.length > 0) {
          const devAlert = deviceAlerts.data.results.map((alert, i) => ({
            ...alert,
            id: `${i}`,
          }));

          setDeviceAlerts(devAlert);
          setTotalSize((prevTotalSize) =>
            deviceAlerts.data.totalSize !== undefined ? deviceAlerts.data.totalSize : prevTotalSize
          );
        }
      } else {
        setDeviceAlerts([]);
        setTotalSize(0);
      }
    }
  } catch (err) {
    console.error(err);
  }
  setDeviceAlertsLoading(false);
};

export default getDeviceAlerts;
