import { UseMutationResult, useMutation } from 'react-query';
import { AxiosResponse } from 'axios';
import { SnackbarActionType, SnackbarContext, SnackbarType } from '@contexts';
import { useContext } from 'react';

import HttpServiceError from '../../../services/httpService/types';
import { queryClient } from '../../../react-query';
import { deleteAssignedLocator, IDeleteAssignedLocatorProps } from '../infrastructure/delete-assigned-locator';

export const useDeleteAssignedLocator = (): UseMutationResult<
  AxiosResponse,
  HttpServiceError,
  IDeleteAssignedLocatorProps
> => {
  const { snackbarDispatch } = useContext(SnackbarContext);

  return useMutation<AxiosResponse, HttpServiceError, IDeleteAssignedLocatorProps>({
    mutationFn: deleteAssignedLocator,
    onError: (error: HttpServiceError) => {
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: error.response?.data.title || 'Wystąpił błąd podczas usuwania lokatora',
          type: SnackbarType.ERROR,
        },
      });
    },
    onSuccess: () => {
      queryClient.refetchQueries('locations-assigment');
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: 'Akcja wykonana pomyślnie',
          type: SnackbarType.SUCCESS,
        },
      });
    },
  });
};
