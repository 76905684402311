import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Typography } from '@mui/material';
import { Icon } from '@components';
import { iconSet } from '@assets';
import { ItemSignatureContainer, IconContainer } from './ItemSignature.styles';
import { DeviceMeasurements } from '../../../../types';
import moment from 'moment';
import 'moment/locale/pl';

interface ItemSignatureProps {
  measurement: DeviceMeasurements;
}

const ItemSignature = ({ measurement }: ItemSignatureProps) => {
  return (
    <ItemSignatureContainer>
      <IconContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: 150,
            justifyContent: 'space-around',
          }}
        >
          <Box>
            <Icon width="22" height="22" iconKey={iconSet.chartIcon} />
          </Box>

          <Typography display="block" variant="subtitle2">
            {moment.utc(measurement.date).local().format('l')}
          </Typography>
        </Box>
        <Box>
          <OpenInNewIcon fontSize="medium" />
        </Box>
      </IconContainer>
    </ItemSignatureContainer>
  );
};

export default ItemSignature;
