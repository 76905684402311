import { useGetLocators } from '@api';
import { CustomNoRowsOverlay, CustomPagination } from '@components';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import { ModalActionType, ModalContext, UserProps } from '@contexts';
import { Chip } from '@mui/material';
import { useContext, useState } from 'react';

import { useStyles } from './locators-list.styles';
import UserProfile from '../Users/UserProfile/UserProfile';

const limit = 10;

export const ListColumns = (): GridColDef[] => {
  return [
    {
      field: 'name',
      headerName: 'Imie',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      flex: 1,
    },
    {
      field: 'surname',
      headerName: 'Nazwisko',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      flex: 1,
    },
    {
      field: 'isActive',
      headerName: 'Aktywny',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<UserProps>) =>
        params.row.isActive ? (
          <Chip
            label="Aktywny"
            style={{
              backgroundColor: '#E9FCD4',
              borderColor: '#AAF27F',
              border: 'solid',
              borderWidth: '1px',
              color: '#229A16',
            }}
          />
        ) : (
          <Chip
            label="Nieaktywny"
            style={{
              backgroundColor: '#FFF7CD',
              borderColor: '#FFE16A',
              border: 'solid',
              borderWidth: '1px',
              color: '#B78103',
            }}
          />
        ),
    },
  ];
};

export const LocatorsList = () => {
  const { classes } = useStyles();
  const [page, setPage] = useState<number>(0);
  const { data, isLoading } = useGetLocators({
    limit,
    offset: page === 0 ? 0 : page * limit,
  });
  const { modalDispatch } = useContext(ModalContext);

  const handleRowClick = (params: GridRowParams) => {
    modalDispatch({
      type: ModalActionType.SHOW,
      payload: {
        content: <UserProfile variant="locator" user={params.row} />,
      },
    });
  };

  const Pagination = () => (
    <CustomPagination totalSize={data?.data.totalSize || 0} page={page} setPage={setPage} limit={limit} />
  );

  return (
    <div className={classes.container}>
      <DataGrid
        rows={data?.data.results || []}
        initialState={{
          pagination: {
            pageSize: limit,
            page: 0,
          },
        }}
        hideFooterSelectedRowCount={true}
        headerHeight={50}
        rowBuffer={0}
        rowHeight={50}
        columnBuffer={0}
        columns={ListColumns()}
        showColumnRightBorder={false}
        autoHeight={true}
        disableColumnMenu={true}
        disableColumnFilter={true}
        checkboxSelection={false}
        disableSelectionOnClick
        onRowClick={handleRowClick}
        loading={isLoading}
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
          Pagination: Pagination,
        }}
        autoPageSize={true}
        paginationMode="server"
        rowCount={data?.data.totalSize || 0}
        rowsPerPageOptions={[limit]}
        page={page}
        onPageChange={(page: number) => {
          setPage(page);
        }}
      />
    </div>
  );
};
