import { useContext } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { FormInputProps, loginValidationSchema } from '@forms';
import { SnackbarContext, UserContext } from '@contexts';
import { Input } from '@components';
import { login } from '@api';
import { Box, Button, Checkbox, FormControlLabel, Grid, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PATH } from '@router';

const LoginForm: React.FC = (): JSX.Element => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormInputProps>({
    resolver: yupResolver(loginValidationSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const theme = useTheme();
  const navigate = useNavigate();

  const { userDispatch } = useContext(UserContext);
  const { snackbarDispatch } = useContext(SnackbarContext);

  const onSubmit: SubmitHandler<FormInputProps> = ({ email, password }) => {
    login({ email, password, userDispatch, snackbarDispatch, navigate });
  };

  const navigateToResetPassword = () => {
    navigate(PATH.RESET_PASSWORD);
  };

  return (
    <Grid container rowSpacing={3} direction={'column'}>
      <Grid item>
        <Typography variant="h4" align="left">
          Logowanie
        </Typography>
      </Grid>
      <Grid item>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              error={!!errors.email}
              helperText={errors.email && errors.email?.message && errors.email.message}
              label="Adres email/login"
              autoComplete="email"
              ref={null}
            />
          )}
        />
      </Grid>
      <Grid item>
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              error={!!errors.password}
              helperText={errors.password && errors.password?.message && errors.password.message}
              label="Hasło"
              type="password"
              autoComplete="current-password"
              ref={null}
            />
          )}
        />
      </Grid>
      <Grid container item direction="row" alignItems={'center'} justifyContent={'space-between'}>
        <FormControlLabel
          value="Zapamiętaj mnie"
          control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />}
          label={
            <Typography variant="subtitle2" color={theme.palette.grey[900]}>
              Zapamiętaj mnie
            </Typography>
          }
          labelPlacement="end"
        />
        <Box>
          <Button variant="text" onClick={navigateToResetPassword}>
            <Typography variant="subtitle2" color={theme.palette.primary.main}>
              Zapomniałeś hasła?
            </Typography>
          </Button>
        </Box>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          sx={{
            width: '100%',
            height: '48px',
            backgroundColor: theme.palette.primary.dark,
            borderRadius: '8px',
          }}
          onClick={handleSubmit(onSubmit)}
        >
          <Typography variant="button">Zaloguj się</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default LoginForm;
