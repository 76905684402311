import { Endpoint } from '@api';
import { SnackbarAction, SnackbarActionType, SnackbarType } from '@contexts';
import { httpsService } from '@services';
import { ResetPasswordInitApiProps } from './types';

interface ResetPasswordProps {
  snackbarDispatch: SnackbarAction;
  email: string;
}

const resetPasswordInit = async ({ snackbarDispatch, email }: ResetPasswordProps) => {
  try {
    const { status } = await httpsService.post<ResetPasswordInitApiProps>(Endpoint.RESET_PASSWORD_INIT, {
      email,
    });

    if (status === 204) {
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: `Wysłano email na adres ${email}`,
          type: SnackbarType.SUCCESS,
        },
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export default resetPasswordInit;
