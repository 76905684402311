import { Select as MuiSelect } from '@mui/material';
import { styled } from '@mui/material';

const Select = styled(MuiSelect)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: 50,
  minWidth: 100,
  width: 200,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export default Select;
