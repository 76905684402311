import { UseMutationResult, useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

import { queryKey } from './query-key.enum';
import { queryClient } from '../../../react-query';
import { IPatchStreetRequest, updateStreetName } from '../infrastructure/patch-street';

interface IUpdateStreetName {
  cityId?: string;
}

export const useUpdateStreetName = ({
  cityId,
}: IUpdateStreetName): UseMutationResult<AxiosResponse, unknown, IPatchStreetRequest> => {
  return useMutation<AxiosResponse, unknown, IPatchStreetRequest>({
    onSuccess: (data) => {
      queryClient.invalidateQueries(queryKey.STREETS_BY_CITY(cityId || ''));
    },
    mutationFn: updateStreetName,
  });
};
