import { Box, Skeleton } from '@mui/material';
import StyledSkeleton from './skeleton.styles';

const SkeletonLoader = () => {
  return (
    <StyledSkeleton>
      <Skeleton animation="wave" />
    </StyledSkeleton>
  );
};

interface CustomSkeletonProps {
  loadersCount: number;
}

const CustomSkeleton = ({ loadersCount }: CustomSkeletonProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      {[...Array(loadersCount)].map((x, i) => (
        <SkeletonLoader key={i} />
      ))}
    </Box>
  );
};

export default CustomSkeleton;
