import { UseMutationResult, useMutation } from 'react-query';
import { AxiosResponse } from 'axios';
import { SnackbarActionType, SnackbarContext, SnackbarType } from '@contexts';
import { useContext } from 'react';

import HttpServiceError from '../../../services/httpService/types';
import { addClient, IAddClientRequest } from '../infrastructure/add-client';

export const useAddClient = (): UseMutationResult<AxiosResponse, HttpServiceError, IAddClientRequest> => {
  const { snackbarDispatch } = useContext(SnackbarContext);

  return useMutation<AxiosResponse, HttpServiceError, IAddClientRequest>({
    mutationFn: addClient,
    onSuccess: () => {
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: 'Utworzono nowego administratora organizacji',
          type: SnackbarType.SUCCESS,
        },
      });
    },
    onError: (error) => {
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: error?.response?.data.title || 'Wystąpił błąd',
          type: SnackbarType.ERROR,
        },
      });
    },
  });
};
