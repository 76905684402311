import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100vh',
  },
  orgNameContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  orgNameText: {
    display: '-webkit-box',
    '-webkit-line-clamp': '1',
    '-webkit-box-orient': 'vertical',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '150px',
    textAlign: 'center',
    boxSizing: 'content-box',
    '-webkit-box-sizing': ' content-box',
    '-moz-box-sizing': 'content-box',
  },
}));
