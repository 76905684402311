import React from 'react';
import { Box } from '@mui/material';

const ShadowSeparator: React.FC = () => {
  return (
    <Box
      width="5px"
      sx={{
        background: 'linear-gradient(270deg, #737A82 -96.67%, rgba(115, 122, 130, 0) 50%)',
        opacity: 0.3,
      }}
    ></Box>
  );
};

export { ShadowSeparator };
