import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';

export interface IPatchCityRequest {
  name: string;
  cityId: string;
}

export const updateCityName = ({ name, cityId }: IPatchCityRequest): Promise<AxiosResponse> => {
  return httpsService.patch(Endpoint.CITIES, { name, cityId });
};
