import { CustomNoRowsOverlay, List } from '@components';
import { ModalContext, UserProps } from '@contexts';
import { useContext, useEffect, useState } from 'react';
import getUsersList from 'src/api/getUsersList';
import { UserGridColumns } from './UserGridColumns';

const UsersList = () => {
  const [users, setUsers] = useState<UserProps[]>([]);
  const { modalState } = useContext(ModalContext);

  useEffect(() => {
    if (!modalState.visible) {
      getUsersList({ setUsersListState: setUsers });
    }
  }, [modalState.visible]);

  return <List users={users} columns={UserGridColumns} CustomNoRows={CustomNoRowsOverlay} />;
};

export { UsersList };
