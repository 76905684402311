import { Button, Divider, ListItem, ListItemText, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';
import {
  useUpdateHeatMeterExpirationDateCommand,
  useUpdateWaterMeterLegalizationDateCommand,
  useUpdateWaterMeterExpirationDateCommand,
  useUpdateHeatMeterLegalizationDateCommand,
} from '@api/device';
import {
  DeviceActionType,
  DeviceContext,
  SnackbarActionType,
  SnackbarContext,
  SnackbarType,
  UserContext,
} from '@contexts';
import 'dayjs/locale/pl';

import { InfoListItem } from './InfoList';

export const InfoEditItem = ({ propertyName, propertyValue }: InfoListItem) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [date, setDate] = useState<string | null>(null);
  const updateHeatMeterExpirationDate = useUpdateHeatMeterExpirationDateCommand();
  const updateHeatMeterLegalizationDate = useUpdateHeatMeterLegalizationDateCommand();
  const updateWaterMeterExpirationDate = useUpdateWaterMeterExpirationDateCommand();
  const updateWaterMeterLegalizationDate = useUpdateWaterMeterLegalizationDateCommand();
  const { snackbarDispatch } = useContext(SnackbarContext);
  const { device, deviceDispatch } = useContext(DeviceContext);
  const { userState } = useContext(UserContext);

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const handleDateChange = (date: Dayjs | null) => {
    setDate(date?.format('YYYY-MM-DD') ?? null);
  };

  const handleSave = () => {
    if (date === null) {
      toggleEditMode();
      return;
    }
    if (device.type === 'wodomierz') {
      if (propertyName === 'Data ważności urządzenia') {
        updateWaterMeterExpirationDate
          .mutateAsync({
            expirationDate: date,
            meterId: device.id,
          })
          .then(() => {
            snackbarDispatch({
              type: SnackbarActionType.SHOW,
              payload: {
                message: `Zaktualizowano`,
                type: SnackbarType.SUCCESS,
              },
            });
            deviceDispatch({
              type: DeviceActionType.SET_DEVICE,
              payload: {
                device: {
                  ...device,
                  expirationDate: date,
                },
              },
            });
          })
          .catch(() => {
            snackbarDispatch({
              type: SnackbarActionType.SHOW,
              payload: {
                message: `Błąd aktualizacji daty`,
                type: SnackbarType.ERROR,
              },
            });
          });
      }
      if (propertyName === 'Data ważności legalizacji') {
        updateWaterMeterLegalizationDate
          .mutateAsync({
            legalizationEndDate: date,
            meterId: device.id,
          })
          .then(() => {
            snackbarDispatch({
              type: SnackbarActionType.SHOW,
              payload: {
                message: `Zaktualizowano`,
                type: SnackbarType.SUCCESS,
              },
            });
            deviceDispatch({
              type: DeviceActionType.SET_DEVICE,
              payload: {
                device: {
                  ...device,
                  legalizationEndDate: date,
                },
              },
            });
          })
          .catch(() => {
            snackbarDispatch({
              type: SnackbarActionType.SHOW,
              payload: {
                message: `Błąd aktualizacji daty`,
                type: SnackbarType.ERROR,
              },
            });
          });
      }
    }

    if (device.type === 'ciepłomierz') {
      if (propertyName === 'Data ważności urządzenia') {
        updateHeatMeterExpirationDate
          .mutateAsync({
            expirationDate: date,
            meterId: device.id,
          })
          .then(() => {
            snackbarDispatch({
              type: SnackbarActionType.SHOW,
              payload: {
                message: `Zaktualizowano`,
                type: SnackbarType.SUCCESS,
              },
            });
            deviceDispatch({
              type: DeviceActionType.SET_DEVICE,
              payload: {
                device: {
                  ...device,
                  expirationDate: date,
                },
              },
            });
          })
          .catch(() => {
            snackbarDispatch({
              type: SnackbarActionType.SHOW,
              payload: {
                message: `Błąd aktualizacji daty`,
                type: SnackbarType.ERROR,
              },
            });
          });
      }
      if (propertyName === 'Data ważności legalizacji') {
        updateHeatMeterLegalizationDate
          .mutateAsync({
            legalizationEndDate: date,
            meterId: device.id,
          })
          .then(() => {
            snackbarDispatch({
              type: SnackbarActionType.SHOW,
              payload: {
                message: `Zaktualizowano`,
                type: SnackbarType.SUCCESS,
              },
            });
            deviceDispatch({
              type: DeviceActionType.SET_DEVICE,
              payload: {
                device: {
                  ...device,
                  legalizationEndDate: date,
                },
              },
            });
          })
          .catch(() => {
            snackbarDispatch({
              type: SnackbarActionType.SHOW,
              payload: {
                message: `Błąd aktualizacji daty`,
                type: SnackbarType.ERROR,
              },
            });
          });
      }
    }

    toggleEditMode();
  };
  return (
    <>
      <ListItem>
        <ListItemText sx={{ minWidth: 220, paddingRight: 5 }}>
          <Typography align="left" variant="body1">
            {propertyName}
          </Typography>
        </ListItemText>
        <ListItemText>
          {isEditMode ? (
            <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pl'}>
                  <DatePicker label={'Wybierz date'} sx={{ width: '100%' }} onChange={handleDateChange} />
                </LocalizationProvider>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Button variant="contained" onClick={handleSave}>
                  <Typography variant="button" color="white">
                    Zapisz
                  </Typography>
                </Button>
                <Button variant="contained" onClick={toggleEditMode}>
                  <Typography variant="button" color="white">
                    Anuluj
                  </Typography>
                </Button>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography align="right" variant="subtitle1">
                {propertyValue}
              </Typography>
              {userState.roles === 'Administrator' && (
                <Button variant="contained" onClick={toggleEditMode}>
                  <Typography variant="button" color="white">
                    {propertyValue === null ? 'Uzupełnij' : 'Edytuj'}
                  </Typography>
                </Button>
              )}
            </div>
          )}
        </ListItemText>
      </ListItem>
      <Divider />
    </>
  );
};
