import Chip from '@mui/material/Chip';
import { ModalActionType, ModalContext } from '@contexts';
import { useContext } from 'react';

import { ConfirmDeleteAssignedLocator } from './confirm-delete-assigned-locator';

interface ILocatorChipsProps {
  locators: {
    locatorId: string;
    locatorName: string;
  }[];
  locationId: string;
  locationName: string;
}

export const LocatorChips = ({ locators, locationId, locationName }: ILocatorChipsProps) => {
  const { modalDispatch } = useContext(ModalContext);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: 4,
        width: '100%',
        flexWrap: 'wrap',
        margin: 10,
        minHeight: 50,
        alignContent: 'center',
      }}
    >
      {locators.map(
        (locator: any) =>
          !!locator.locatorName && (
            <Chip
              key={locator.locatorId}
              label={locator.locatorName}
              onDelete={() => {
                modalDispatch({
                  type: ModalActionType.SHOW,
                  payload: {
                    content: (
                      <ConfirmDeleteAssignedLocator
                        locatorName={locator.locatorName}
                        locatorId={locator.locatorId}
                        locationId={locationId}
                        locationName={locationName}
                      />
                    ),
                  },
                });
              }}
              style={{
                backgroundColor: 'transparent',
                border: 'solid',
                borderWidth: '1px',
                borderColor: 'rgba(0, 0, 0, 0.13)',
                borderRadius: 0,
              }}
            />
          )
      )}
    </div>
  );
};
