import { Box, Divider } from '@mui/material';
import { ListMobileWrapper, ListMobileItem } from './ListMobile.styles';
import { ItemSignature, ItemInfo } from './Item';
import { DeviceMeasurements } from '../../types';
import { CustomNoRowsOverlay } from '@components';
import { DialogContent, Table, TableHead, TableRow, TableBody, TableCell } from '@mui/material';
import { ModalActionType, ModalContext } from 'src/contexts/ModalContext';
import { useContext } from 'react';
import moment from 'moment';
import 'moment/locale/pl';
import { SkeletonLoader } from '@components';

interface ListMobileProps {
  measurements: DeviceMeasurements[];
  isDeviceMeasurementsLoading: boolean;
  deviceType: string;
  pagination: JSX.Element;
}

interface ListMobileItemProps {
  measurement: DeviceMeasurements;
  deviceType: string;
}

const ListItem: React.FC<ListMobileItemProps> = ({ measurement, deviceType }) => {
  return (
    <ListMobileItem>
      <ItemSignature measurement={measurement} />
      <ItemInfo measurement={measurement} deviceType={deviceType} />
    </ListMobileItem>
  );
};

const ListMobile: React.FC<ListMobileProps> = ({
  measurements,
  deviceType,
  isDeviceMeasurementsLoading,
  pagination,
}) => {
  const { modalDispatch } = useContext(ModalContext);

  return (
    <ListMobileWrapper>
      {measurements.length > 0 ? (
        measurements.map((measurement, i) => {
          return (
            <div
              key={measurement.id}
              onClick={() =>
                modalDispatch({
                  type: ModalActionType.SHOW,
                  payload: {
                    content: (
                      <DialogContent>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Data</TableCell>
                              <TableCell>Godzina</TableCell>
                              <TableCell>{deviceType === 'wodomierz' ? 'Przyrost' : 'Stan'}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {deviceType === 'wodomierz'
                              ? measurements[i].deltaValues.map((increase, i) => {
                                  return (
                                    <TableRow key={i} hover>
                                      <TableCell>{moment.utc(increase.item1).local().format('l')}</TableCell>
                                      <TableCell>{moment.utc(increase.item1).local().format('LTS')}</TableCell>
                                      <TableCell>{increase.item2} m³</TableCell>
                                    </TableRow>
                                  );
                                })
                              : measurements[i].energyDeltaValues.map((increase, i) => {
                                  return (
                                    <TableRow key={i} hover>
                                      <TableCell>{moment.utc(increase.item1).local().format('l')}</TableCell>
                                      <TableCell>{moment.utc(increase.item1).local().format('LTS')}</TableCell>
                                      <TableCell>{increase.item2} GJ</TableCell>
                                    </TableRow>
                                  );
                                })}
                          </TableBody>
                        </Table>
                      </DialogContent>
                    ),
                  },
                })
              }
            >
              <Box>
                <ListItem measurement={measurement} deviceType={deviceType} />
                <Divider />
              </Box>
            </div>
          );
        })
      ) : isDeviceMeasurementsLoading ? (
        <SkeletonLoader loadersCount={5} />
      ) : (
        <CustomNoRowsOverlay />
      )}
      {measurements.length > 0 && pagination}
    </ListMobileWrapper>
  );
};

export default ListMobile;
