import { Tabs as MuiTabs } from '@mui/material';
import { Icon } from '@components';
import { TabsWrapper, IconWrapper, StyledTab } from './Tabs.styles';
import { useNavigate } from 'react-router-dom';

export interface TabProps {
  label: string;
  iconKey: string;
  path?: string;
}
interface TabsProps {
  value: number;
  handleChange: (event: React.SyntheticEvent<Element, Event>, value: number) => void;
  tabs: TabProps[];
}

const Tabs: React.FC<TabsProps> = ({ value, tabs, handleChange }) => {
  const navigate = useNavigate();

  return (
    <TabsWrapper>
      <MuiTabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        allowScrollButtonsMobile
        aria-label="icon position tabs"
      >
        {tabs.map((tab) => {
          return (
            <StyledTab
              key={tab.iconKey}
              icon={
                <IconWrapper>
                  <Icon iconKey={tab.iconKey} />
                </IconWrapper>
              }
              onClick={() => {
                if (tab.path) navigate(tab.path);
              }}
              label={tab.label}
              iconPosition="start"
            />
          );
        })}
      </MuiTabs>
    </TabsWrapper>
  );
};

export default Tabs;
