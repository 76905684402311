import { Box, Paper, Typography, useTheme, useMediaQuery } from '@mui/material';
import { ChangePassword } from './ChangePassword.form';
import { PasswordContainer } from './Password.styles';

const Password = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <PasswordContainer>
      <Paper
        elevation={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '20px',
          padding: '70px 40px',
        }}
      >
        <Typography variant="h4" textAlign="center">
          Zmień hasło
        </Typography>
        <Box sx={{ width: isMobile ? '220px' : '500px' }}>
          <ChangePassword />
        </Box>
      </Paper>
    </PasswordContainer>
  );
};

export { Password };
