import { Modal, Snackbar } from '@components';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { wodmarTheme } from '@theme';
import { StyledLayout } from './Layout.styles';

const Layout: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={wodmarTheme}>
      <CssBaseline />
      <StyledLayout>
        {children}
        <Snackbar />
        <Modal />
      </StyledLayout>
    </ThemeProvider>
  );
};

export default Layout;
