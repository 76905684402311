import { Box, styled } from '@mui/material';
import { Column } from '@components';

const ListMobileWrapper = styled(Box)(() => ({
  width: '100%',
  paddingLeft: 10,
  paddingRight: 10,
}));

const ListMobileItem = styled(Column)(() => ({
  height: 180,
  padding: '24px, 16px, 16px, 16px',
}));

export { ListMobileWrapper, ListMobileItem };
