export enum PATH {
  LOGIN = '/',
  RESET_PASSWORD = '/odzyskaj-haslo',
  NEW_PASSWORD = '/password/reset',
  ONBOARDING = '/onboarding',
  DASHBOARD = 'panel',
  NOT_FOUND = '*',
  STYLEGUIDE = 'styles',
  PANEL = 'zarzadzanie',
  DEVICE = 'zarzadzanie/:id/:type',
  DEVICE_DETAILS_OVERVIEW = `informacje-ogolne`,
  DEVICE_DETAILS_ALERTS = 'alerty',
  DEVICE_DETAILS_MEASUREMENTS = 'pomiary',
  ADD_DEVICE = 'dodaj-urzadzenie',
  ADMIN_LIST = 'lista-administratorow',
  ADD_USER = 'dodaj-administratora-systemu',
  MY_PROFILE = 'profil',
  PASSWORD = 'haslo',
  CUSTOMER_LIST = 'lista-organizacji',
  ADD_CUSTOMER = 'dodaj-organizacje',
  LOCATIONS_LIST = `lokalizacje`,
  REPORTS = 'raporty',
  MONTHLY_REPORT = 'miesieczny',
  LOCATORS_LIST = 'lokatorzy',
  ADD_LOCATOR = 'dodaj-lokatora',
  CLIENTS_LIST = 'konta-administratorow',
  ADD_CLIENT = 'dodaj-administratora',
  ASSIGN_LOCATIONS = 'przypisz-lokacje',
  TERMS = 'regulamin',
  EXPIRED_TOKEN = 'wygasly-link-aktywacyjny',
}
