import { useStyles } from './assign-modal.styles';
import { CustomNoRowsOverlay, CustomPagination, Input } from '@components';
import { DataGrid } from '@mui/x-data-grid';
import { useGetLocators } from '@api';
import { Typography } from '@mui/material';
import { useCallback, useContext, useState } from 'react';
import _debounce from 'lodash/debounce';

import { ListColumns } from './columns';
import { ModalActionType, ModalContext } from '@contexts';
import { ConfirmAssignment } from './confirm-assignment';

interface AssignModalProps {
  locationId: string;
  locationName: string;
}

const limit = 10;

export const AssignModal = ({ locationId, locationName }: AssignModalProps) => {
  const { classes } = useStyles();
  const [username, setUserName] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const { data, isLoading } = useGetLocators({
    limit,
    offset: page === 0 ? 0 : page * limit,
    username,
  });
  const { modalDispatch } = useContext(ModalContext);

  const handleRowClick = (params: { name: string; id: string; surname: string }) => {
    modalDispatch({
      type: ModalActionType.SHOW,
      payload: {
        content: (
          <ConfirmAssignment
            locationId={locationId}
            locatorName={params.name + ' ' + params.surname}
            locationName={locationName}
            locatorId={params.id}
          />
        ),
      },
    });
  };

  const handleUserNameFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(_debounce(handleUserNameFilterChange, 500), []);

  const Pagination = () => (
    <CustomPagination onlyArrows totalSize={data?.data.totalSize || 0} page={page} setPage={setPage} limit={limit} />
  );

  return (
    <div className={classes.container}>
      <Typography variant="h6" align="center">
        Przypisz lokatora do lokalizacji: {locationName}
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Input label={'Filtruj lokatorów'} style={{ width: '80%' }} onChange={debounceFn} />
      </div>
      <div style={{ minHeight: 500 }}>
        <DataGrid
          rows={data?.data.results || []}
          loading={isLoading}
          initialState={{
            pagination: {
              pageSize: limit,
              page: 0,
            },
          }}
          hideFooterSelectedRowCount={true}
          headerHeight={50}
          rowBuffer={0}
          rowHeight={50}
          columnBuffer={0}
          columns={ListColumns({ onRowClick: handleRowClick })}
          showColumnRightBorder={false}
          autoHeight={true}
          disableColumnMenu={true}
          disableColumnFilter={true}
          checkboxSelection={false}
          disableSelectionOnClick
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
            Pagination: Pagination,
          }}
          autoPageSize={true}
          paginationMode="server"
          rowCount={data?.data.totalSize || 0}
          rowsPerPageOptions={[limit]}
          page={page}
          onPageChange={(page: number) => {
            setPage(page);
          }}
          sx={{
            '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
          }}
        />
      </div>
    </div>
  );
};
