import { Outlet } from 'react-router-dom';
// import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';

export const ReportsScreen = () => {
  return (
    <div>
      {/*<Typography textAlign="center" variant="h5">*/}
      {/*  Wybierz raport*/}
      {/*</Typography>*/}
      {/*<Card sx={{ maxWidth: 345 }}>*/}
      {/*  <CardContent>*/}
      {/*    <Typography gutterBottom variant="h5" component="div">*/}
      {/*      Generowanie raportu miesięcznego*/}
      {/*    </Typography>*/}
      {/*  </CardContent>*/}
      {/*  <CardActions>*/}
      {/*    <Button size="small">Przedź do generatora</Button>*/}
      {/*  </CardActions>*/}
      {/*</Card>*/}
      <Outlet />
    </div>
  );
};
