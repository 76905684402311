import { ButtonProps } from '@mui/material';
import { ReactNode } from 'react';
import { StyledIconButton, StyledButton } from './button.styles';

interface ButtonProp extends ButtonProps {
  buttonIcon?: boolean;
  icon?: ReactNode;
}
const Button: React.FC<ButtonProp> = ({ buttonIcon = false, icon, ...props }) => {
  return buttonIcon ? (
    !props.disabled ? (
      <StyledIconButton {...props}>{icon}</StyledIconButton>
    ) : (
      <StyledButton variant="contained" {...props}>
        {icon}
      </StyledButton>
    )
  ) : (
    <StyledButton variant="contained" {...props} />
  );
};

export default Button;
