import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';

export interface IPatchLegalizationRequest {
  legalizationEndDate: string;
  meterId: string;
}

export const updateHeatMeterLegalizationDate = ({
  meterId,
  legalizationEndDate,
}: IPatchLegalizationRequest): Promise<AxiosResponse> => {
  return httpsService.patch(Endpoint.HEAT_METER_LEGALIZATION_DATE.replace(':meterId', meterId), {
    legalizationEndDate,
  });
};
