import { WodmarLocationsDashboardTable } from '@components';

import { useStyles } from './location-summary.styles';

export const LocationSummaryList = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <WodmarLocationsDashboardTable />
    </div>
  );
};
