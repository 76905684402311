import { Row } from '@components';
import { Box, styled } from '@mui/material';

const ButtonsContainer = styled(Row)(({ theme }) => ({
  width: '30%',
  justifyContent: 'flex-end',
  columnGap: 10,
  [theme.breakpoints.down('md')]: {
    margin: 0,
    width: '100%',
    justifyContent: 'flex-start',
    rowGap: 5,
  },
  [theme.breakpoints.down('sm')]: {
    margin: 0,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    rowGap: 10,
  },
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  marginLeft: 1,
  marginRight: 1,
  [theme.breakpoints.down('sm')]: {
    marginLeft: 10,
    marginRight: 10,
  },
}));

export { ButtonsContainer, ButtonWrapper };
