import React, { useContext } from 'react';
import * as yup from 'yup';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input } from '@components';
import { Typography, Box, useTheme, Button } from '@mui/material';
import { NewUserProps } from '@api/types';
import { useAddAdmin, useAddClient, useAddLocator } from '@api';
import { ModalActionType, ModalContext, SnackbarActionType, SnackbarContext, SnackbarType } from '@contexts';

import ConfirmationDialog from './ConfirmationDialog';

const passwordRegexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/;

const newAdminAccountValidationSchema = yup.object().shape({
  name: yup.string().max(255).required('Pole obowiązkowe'),
  surname: yup.string().max(255).required('Pole obowiązkowe'),
  email: yup.string().email('Niepoprawny e-mail').required('Pole obowiązkowe'),
  password: yup
    .string()
    .max(100, 'Hasło nie może przekraczać 100 znaków')
    .min(8, 'Hasło musi mieć conajmniej 8 znaków')
    .matches(
      passwordRegexp,
      'Hasło musi zawierać conajmniej osiem znaków, duża i małą literę, cyfrę i znak specjalny. Znaki specjalne - @$!%*?&.'
    )
    .required('Hasło jest wymagane'),
  confirmPassword: yup.string().oneOf([yup.ref('password'), undefined], 'Hasła muszą być takie same'),
});

const newAccountValidationSchema = yup.object().shape({
  name: yup.string().max(255).required('Pole obowiązkowe'),
  surname: yup.string().max(255).required('Pole obowiązkowe'),
  email: yup.string().email('Niepoprawny e-mail').required('Pole obowiązkowe'),
});

interface IAddUserForm {
  variant: 'admin' | 'locator' | 'client';
}

export const AddUserForm = ({ variant }: IAddUserForm): JSX.Element => {
  const theme = useTheme();
  const { modalDispatch } = useContext(ModalContext);
  const { snackbarDispatch } = useContext(SnackbarContext);
  const { mutateAsync: addNewLocator } = useAddLocator();
  const { mutateAsync: addNewAdmin } = useAddAdmin();
  const { mutateAsync: addNewClient } = useAddClient();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm<NewUserProps>({
    resolver: yupResolver(variant === 'admin' ? newAdminAccountValidationSchema : newAccountValidationSchema),
    mode: 'onBlur',
    defaultValues: {
      name: '',
      surname: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
  });

  const onSubmit: SubmitHandler<NewUserProps> = async ({ name, surname, email, password }) => {
    switch (variant) {
      case 'locator':
        try {
          await addNewLocator({ name, surname, email });
        } catch (error) {
          console.log(error);
        }
        break;
      case 'client':
        try {
          await addNewClient({ name, surname, email });
        } catch (error) {
          console.log(error);
        }
        break;
      case 'admin':
        try {
          await addNewAdmin({ name, surname, email, password });
        } catch (error) {
          console.log(error);
        }
        break;
    }

    reset();
  };

  return (
    <form>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '30px',
        }}
      >
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              error={!!errors.name}
              helperText={errors.name && errors.name?.message && errors.name.message}
              label="Imię"
              autoComplete="false"
              ref={null}
            />
          )}
        />
        <Controller
          name="surname"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              error={!!errors.surname}
              helperText={errors.surname && errors.surname?.message && errors.surname.message}
              label="Nazwisko"
              ref={null}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              error={!!errors.email}
              helperText={errors.email && errors.email?.message && errors.email.message}
              label="E-mail"
              autoComplete="false"
              ref={null}
            />
          )}
        />
        {variant === 'admin' && (
          <>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  error={!!errors.password}
                  helperText={errors.password && errors.password?.message && errors.password.message}
                  type="password"
                  autoComplete="off"
                  label="Hasło"
                  ref={null}
                />
              )}
            />
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  error={!!errors.confirmPassword}
                  helperText={
                    errors.confirmPassword && errors.confirmPassword?.message && errors.confirmPassword.message
                  }
                  type="password"
                  label="Potwierdź hasło"
                  ref={null}
                />
              )}
            />
          </>
        )}
        <Button
          variant="contained"
          sx={{
            width: '100%',
            height: '48px',
            borderRadius: '8px',
            backgroundColor: theme.palette.primary.dark,
          }}
          disabled={!isDirty}
          onClick={() => {
            if (isValid) {
              modalDispatch({
                type: ModalActionType.SHOW,
                payload: {
                  content: <ConfirmationDialog variant={variant} resetForm={reset} submitAddUserForm={handleSubmit(onSubmit)} />,
                },
              });
            } else {
              snackbarDispatch({
                type: SnackbarActionType.SHOW,
                payload: {
                  message: 'Niepoprawne dane w formularzu',
                  type: SnackbarType.ERROR,
                },
              });
              handleSubmit(onSubmit);
            }
          }}
        >
          <Typography variant="button" color="white">
            Dodaj nowe konto
          </Typography>
        </Button>
      </Box>
    </form>
  );
};
