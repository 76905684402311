import { Endpoint } from '@api';
import { httpsService } from '@services';
import { DeviceActionType, DeviceAction } from '@contexts';
import { Dispatch, SetStateAction } from 'react';

interface DeviceList {
  deviceId: string;
  deviceDispatch: DeviceAction;
  setDeviceDetailsLoading: Dispatch<SetStateAction<boolean>>;
  type?: string;
}

const getDeviceDetails = async ({ deviceDispatch, deviceId, setDeviceDetailsLoading, type }: DeviceList) => {
  setDeviceDetailsLoading(true);
  try {
    if (type === 'water') {
      const deviceDetails = await httpsService.get(`${Endpoint.DEVICE_WATER_DETAILS.replace(':id', deviceId)}`);
      if (deviceDetails.status === 200) {
        deviceDispatch({
          type: DeviceActionType.SET_DEVICE,
          payload: {
            device: deviceDetails.data,
          },
        });
      }
    } else if (type === 'heater') {
      const deviceDetails = await httpsService.get(`${Endpoint.DEVICE_HEATER_DETAILS.replace(':id', deviceId)}`);
      if (deviceDetails.status === 200) {
        deviceDispatch({
          type: DeviceActionType.SET_DEVICE,
          payload: {
            device: deviceDetails.data,
          },
        });
      }
    }
  } catch (err) {
    console.error(err);
  }
  setDeviceDetailsLoading(false);
};

export default getDeviceDetails;
