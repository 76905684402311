import { Accordion, AccordionDetails, AccordionSummary, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useContext, useState } from 'react';
import { SkeletonLoader } from '@components';
import { ModalActionType, ModalContext } from '@contexts';
import { useGetFlatsQuery } from '@api/locations';

import { FlatAccordion } from './flat-accordion.component';
import { AddBuildingUpsertForm } from './forms/add-building.form';
import { ManageButtons } from './manage-buttons';
import { AddFlatUpsertForm } from './forms/add-flat.form';

interface BuildingAccordionProps {
  name: string;
  id: string;
  streetId: string;
}

export const BuildingAccordion = ({ name, id, streetId }: BuildingAccordionProps) => {
  const { data, isLoading, refetch, isStale } = useGetFlatsQuery({ buildingId: id });
  const [expand, setExpand] = useState(false);
  const theme = useTheme();
  const { modalDispatch } = useContext(ModalContext);

  const toggleAcordion = () => {
    setExpand((prev) => {
      if (prev === false && isStale) {
        refetch();
      }
      return !prev;
    });
  };
  const openAddFlatModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    modalDispatch({
      type: ModalActionType.SHOW,
      payload: {
        content: <AddFlatUpsertForm flat={{ id: '', name: '', buildingId: id, buildingName: name }} />,
      },
    });
  };

  const openEditBuildingModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    modalDispatch({
      type: ModalActionType.SHOW,
      payload: {
        content: <AddBuildingUpsertForm building={{ id, name, streetId }} />,
      },
    });
  };

  return (
    <Accordion
      expanded={expand}
      TransitionProps={{ unmountOnExit: true }}
      sx={{ backgroundColor: theme.palette.grey[300] }}
    >
      <AccordionSummary
        onClick={toggleAcordion}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <ManageButtons name={name} onAdd={openAddFlatModal} onEdit={openEditBuildingModal} />
      </AccordionSummary>
      <AccordionDetails>
        {isLoading ? (
          <SkeletonLoader loadersCount={1} />
        ) : (
          data?.data.map((flat) => <FlatAccordion name={flat.name} id={flat.id} buildingId={id} />)
        )}
      </AccordionDetails>
    </Accordion>
  );
};
