import { useQuery } from 'react-query';

import { getLocationsForAssignment, ILocationsAssignmentParams } from '../infrastructure/location-assign';

export const useGetLocationsAssigment = (params: ILocationsAssignmentParams) => {
  return useQuery({
    queryFn: () => getLocationsForAssignment(params),
    queryKey: ['locations-assigment', params],
    cacheTime: 0,
    staleTime: 0,
  });
};
