import { imageSet } from '@assets';
import { Box } from '@mui/material';

const WodmarLogo = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <img alt="wodmar logo" width="300" src={imageSet.wodmarLogo} />
    </Box>
  );
};

export default WodmarLogo;
