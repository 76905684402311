import { UseMutationResult, useMutation } from 'react-query';
import { AxiosResponse } from 'axios';
import { SnackbarActionType, SnackbarContext, SnackbarType } from '@contexts';
import { useContext } from 'react';

import { onboardUserPassword, IOnboardingPasswordRequest } from '../infrastructure/onboard-user-password';
import HttpServiceError from '../../../services/httpService/types';

export const useOnboardUserPassword = (): UseMutationResult<
  AxiosResponse,
  HttpServiceError,
  IOnboardingPasswordRequest
> => {
  const { snackbarDispatch } = useContext(SnackbarContext);

  return useMutation<AxiosResponse, HttpServiceError, IOnboardingPasswordRequest>({
    mutationFn: onboardUserPassword,
    onSuccess: () => {
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: 'Hasło zostało ustanowione poprawnie',
          type: SnackbarType.SUCCESS,
        },
      });
    },
    onError: (error) => {
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: error?.response?.data.title || 'Wystąpił błąd',
          type: SnackbarType.ERROR,
        },
      });
    },
  });
};
