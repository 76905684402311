import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';

export interface IActivateClient {
  clientId: string;
  active: boolean;
}

export const activateClient = ({ clientId, active }: IActivateClient): Promise<AxiosResponse> => {
  return httpsService.put(Endpoint.ACTIVATE_CLIENT.replace(':clientId', clientId), active);
};
