import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '100%',
    paddingLeft: 8,
    paddingRight: 8,
    marginBottom: 4,
  },
  nestedItemContainer: {
    marginLeft: 20,
    marginTop: 5,
  },
  iconLabelWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 40,
    gap: 10,
    '& > svg': {
      color: theme.palette.primary.main,
    },
  },
}));
