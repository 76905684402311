import { useQuery } from 'react-query';

import { getClients } from '../infrastructure/get-clients';

export const useGetClients = () => {
  return useQuery({
    queryFn: () => getClients(),
    queryKey: ['clients'],
    cacheTime: 0,
    staleTime: 0,
  });
};
