import { Column, Row } from '@components';
import { styled } from '@mui/material';

const ItemSignatureContainer = styled(Row)(() => ({
  justifyContent: 'space-between',
  marginBottom: 10,
}));

const IconContainer = styled(Row)(() => ({
  width: '100%',
  justifyContent: 'space-between',
}));

const CenterIcon = styled(Column)(() => ({
  justifyContent: 'center',
}));

export { ItemSignatureContainer, IconContainer, CenterIcon };
