import { useOnboardInit } from '@api';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography, Button, useTheme } from '@mui/material';
import React from 'react';

import { Logo } from '../Dashboard/Menu/MenuContainer/MenuOptions/components/logo';
import { PATH } from "@router";

export const ExpiredToken = () => {
  const { mutateAsync: resendActivationEmail } = useOnboardInit();
  const { search } = useLocation();
  const queryToken = new URLSearchParams(search).get('token');
  const theme = useTheme();
  const navigate = useNavigate();

  const handleResendActivationEmail = () => {
    resendActivationEmail({ oldToken: queryToken ?? '' })
      .then(() => {
        navigate(PATH.LOGIN, { replace: true });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: '0 auto',
        width: '75%',
        height: '100vh',
        gap: '20px',
      }}
    >
      <div style={{ marginBottom: -40 }}>
        <Logo />
      </div>
      <Typography variant="h2" style={{ textAlign: 'center' }}>
        Twój link aktywacyjny wygasł!
      </Typography>
      <Button
        sx={{
          width: '50%',
          height: '48px',
          borderRadius: '8px',
          alignSelf: 'center',
          backgroundColor: theme.palette.primary.dark,
        }}
        variant="contained"
        onClick={handleResendActivationEmail}
      >
        <Typography variant="body1"> Wyślij ponownie e-mail z linkiem aktywacyjnym</Typography>
      </Button>
    </div>
  );
};
