import dropLogo from './images/drop.png';
import wodmarLogo from './images/logo-wodmar.png';
import avatar from './images/avatar.webp';

interface ImagesSetKey {
  dropLogo: string;
  wodmarLogo: string;
  avatar: string;
}

const imageSet: ImagesSetKey = {
  dropLogo,
  wodmarLogo,
  avatar,
};

export default imageSet;
