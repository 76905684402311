import { UseMutationResult, useMutation } from 'react-query';
import { AxiosResponse } from 'axios';
import { getStoredCurrentCustomerData } from '@helpers';

import { queryKey } from './query-key.enum';
import { queryClient } from '../../../react-query';
import { addNewCity, IPostCityRequest } from '../infrastructure/post-city';

export const useAddCity = (): UseMutationResult<AxiosResponse, unknown, IPostCityRequest> => {
  return useMutation<AxiosResponse, unknown, IPostCityRequest>({
    onSuccess: (data) => {
      const storedCustomer = getStoredCurrentCustomerData();
      queryClient.invalidateQueries([queryKey.CITIES(), storedCustomer?.id]);
    },
    mutationFn: addNewCity,
  });
};
