import { Grid, Typography } from '@mui/material';
import React from 'react';

const StyleGuide: React.FC = () => {
  return (
    <Grid wrap="nowrap">
      <Typography variant="h1"> h1 WODMAR SYSTEM </Typography>
      <Typography variant="h2"> h2 WODMAR SYSTEM </Typography>
      <Typography variant="h3"> h3 WODMAR SYSTEM </Typography>
      <Typography variant="h4"> h4 WODMAR SYSTEM </Typography>
      <Typography variant="h5"> h5 WODMAR SYSTEM </Typography>
      <Typography variant="h6"> h6 WODMAR SYSTEM </Typography>
      <Typography variant="subtitle1"> subtitle1 WODMAR SYSTEM </Typography>
      <Typography variant="subtitle2"> subtitle2 WODMAR SYSTEM </Typography>
      <Typography variant="body1"> body1 WODMAR SYSTEM </Typography>
      <Typography variant="body2"> body2 WODMAR SYSTEM </Typography>
      <Typography variant="button"> button WODMAR SYSTEM </Typography>
      <Typography variant="caption"> caption WODMAR SYSTEM </Typography>
      <Typography variant="overline"> overline WODMAR SYSTEM </Typography>
    </Grid>
  );
};

export { StyleGuide };
