import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { SnackbarContext, UserContext } from '@contexts';
import { Grid } from '@mui/material';
import { logout } from '@api';

import { MenuOptions, ShadowSeparator } from './MenuOptions';

export const MenuContainer = () => {
  const { userDispatch } = useContext(UserContext);
  const { snackbarDispatch } = useContext(SnackbarContext);
  const navigate = useNavigate();

  const logoutUser = useCallback(() => {
    logout({ userDispatch, snackbarDispatch, navigate });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid sx={{ position: 'sticky', top: 0 }} container item height="100vh">
      <MenuOptions logoutUser={logoutUser} />
      <ShadowSeparator />
    </Grid>
  );
};
