import { styled, Box } from '@mui/material';

const PaginationContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  columnGap: '60px',
  paddingTop: 2,
  paddingBottom: 2,
  marginTop: 10,
  marginBottom: 10,
}));

const PaginationContainerMobile = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  rowGap: '20px',
  marginTop: 10,
  marginBottom: 10,
}));

const PaginationItem = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignSelf: 'center',
}));

const PaginationInputBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  columnGap: '10px',
}));

export { PaginationContainer, PaginationItem, PaginationInputBox, PaginationContainerMobile };
