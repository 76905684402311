import React from 'react';
import { imageSet } from '@assets';
import { Box } from '@mui/material';

import { useStyles } from './logo.styles';

export const Logo: React.FC = () => {
  const { classes } = useStyles();

  return (
    <Box mb={5}>
      <Box className={classes.containerLogo}>
        <img alt="wodmar logo" width="200" src={imageSet.wodmarLogo} />
      </Box>
    </Box>
  );
};
