import { Box, styled, Alert as MuiAlert } from '@mui/material';

const Snackbar = styled(Box)(() => ({
  position: 'fixed',
  bottom: '10px',
  width: '284px',
  height: '64px',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));

const Alert = styled(MuiAlert)(() => ({
  width: '100%',
  borderRadius: '8px',
}));

export { Snackbar, Alert };
