import { useGetCustomers } from '@api/customers';
import { SkeletonLoader } from '@components';
import { ICustomer } from '@api/types';

import { CustomerSwitcherContent, CustomerSwitcherDetailsContainer } from './customerSwitcherDetails.styles';
import { OrganizationItem } from './customerItem';

interface IOrganizationDetailsProps {
  open: boolean;
  anchor: null | HTMLElement;
  onClose: () => void;
  currentCustomer: ICustomer | undefined;
}

export const OrganizationDetails = ({ open, anchor, onClose, currentCustomer }: IOrganizationDetailsProps) => {
  const { data: customers, isFetched, isLoading: isCustomerListLoading } = useGetCustomers();

  return (
    <CustomerSwitcherDetailsContainer
      anchorEl={anchor}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <CustomerSwitcherContent>
        {isCustomerListLoading ? (
          <SkeletonLoader loadersCount={2} />
        ) : (
          isFetched &&
          customers?.data.map((customer) => (
            <OrganizationItem
              key={customer.id}
              customer={customer}
              isCurrentOrg={currentCustomer?.id === customer.id}
            />
          ))
        )}
      </CustomerSwitcherContent>
    </CustomerSwitcherDetailsContainer>
  );
};
