import { GridColDef } from '@mui/x-data-grid';
import { JSXElementConstructor } from 'react';
import { UserProps } from '@contexts';
import { Grid } from './list.styles';

const limit = 100;

interface ListProps {
  columns: GridColDef[];
  CustomNoRows: JSXElementConstructor<any> | undefined;
  users: UserProps[];
}

export const List = ({ columns, CustomNoRows, users }: ListProps) => {
  return (
    <Grid
      initialState={{
        pagination: {
          pageSize: limit,
          page: 0,
        },
      }}
      rows={users}
      hideFooterSelectedRowCount={true}
      headerHeight={50}
      rowBuffer={0}
      rowHeight={100}
      columnBuffer={0}
      columns={columns}
      showColumnRightBorder={false}
      autoHeight={true}
      disableColumnMenu={true}
      disableColumnFilter={true}
      checkboxSelection={false}
      disableSelectionOnClick
      rowsPerPageOptions={[limit]}
      components={{
        NoRowsOverlay: CustomNoRows,
      }}
      rowCount={limit}
    />
  );
};
