import { httpsService } from '@services';
import { Endpoint } from '@api';

interface IReportProps {
  meterId: string;
}

export const getWaterMetersColdReport = ({ meterId }: IReportProps) => {
  return httpsService.get(Endpoint.WATER_METER_REPORT_COLD.replace(':meterId', meterId || ''));
};
