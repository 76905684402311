import { Menu, MenuItem, Box, styled } from '@mui/material';

const CustomerSwitcherDetailsContainer = styled(Menu)((theme) => ({
  '& .MuiPaper-root': {
    display: 'inline-table',
  },
  '& .MuiList-root': {
    padding: 0,
  },
}));

const CustomerSwitcherContent = styled(Box)((theme) => ({
  width: 360,
  padding: 4,
}));

const CustomerItemContainer = styled(MenuItem)((theme) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  columnGap: 8,
  paddingTop: 8,
  paddingBottom: 8,
}));

export { CustomerSwitcherDetailsContainer, CustomerSwitcherContent, CustomerItemContainer };
