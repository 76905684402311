interface IconProps {
  iconKey: string;
  width?: string;
  height?: string;
}

const Icon = ({ iconKey, width, height }: IconProps) => {
  return width && height ? (
    <img src={iconKey} width={width} height={height} alt={iconKey} />
  ) : (
    <img src={iconKey} alt={iconKey} />
  );
};

export default Icon;
