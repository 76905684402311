import { Box, Typography } from '@mui/material';
import { Icon } from '@components';
import { iconSet } from '@assets';
import { DeviceAlertsProps } from '@api/types';
import { ItemSignatureContainer, IconContainer } from './ItemSignature.styles';
import moment from 'moment';
import 'moment/locale/pl';

interface ItemSignatureProps {
  alert: DeviceAlertsProps;
}

const ItemSignature = ({ alert }: ItemSignatureProps) => {
  return (
    <ItemSignatureContainer>
      <IconContainer sx={{ marginTop: 1 }}>
        <Box>
          <Icon width="22" height="22" iconKey={iconSet.chartIcon} />
        </Box>
        <Box>
          <Typography variant="subtitle2">{moment.utc(alert.date).local().format('l')}</Typography>
        </Box>
      </IconContainer>
    </ItemSignatureContainer>
  );
};

export default ItemSignature;
