import axios from 'axios';
import { getConfig } from '@config';

const { api } = getConfig();

export const httpsService = axios.create({
  baseURL: api.toString(),
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});
