import { UseMutationResult, useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

import {
  IPatchExpirationRequest,
  updateWaterMeterExpirationDate,
} from '../infrastructure/update-water-meter-expiration-date';

export const useUpdateWaterMeterExpirationDateCommand = (): UseMutationResult<
  AxiosResponse,
  unknown,
  IPatchExpirationRequest
> => {
  return useMutation<AxiosResponse, unknown, IPatchExpirationRequest>({
    mutationFn: updateWaterMeterExpirationDate,
  });
};
