import { GridColDef } from '@mui/x-data-grid';
import { Button } from '@mui/material';

interface IListColumnsProps {
  onRowClick: (params: { name: string; id: string; surname: string }) => void;
}

export const ListColumns = ({ onRowClick }: IListColumnsProps): GridColDef[] => {
  return [
    {
      field: 'name',
      headerName: 'Imie i nazwisko',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (params) => `${params.row.name} ${params.row.surname}`,
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Akcja',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Button variant="contained" sx={{ width: 150 }} onClick={() => onRowClick(params.row)}>
            Przypisz
          </Button>
        );
      },
    },
  ];
};
