import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import UnfoldMoreTwoToneIcon from '@mui/icons-material/UnfoldMoreTwoTone';
import { Typography, useTheme } from '@mui/material';
import React, { useContext, useState } from 'react';
import { UserContext } from '@contexts';
import { ICustomer } from '@api/types';

import { OrganizationDetails } from './customerSwitcherDetails/customerSwitcherDetails';
import { CustomerSwitcherContainer } from './customerSwitcher.styles';

interface IOrganizationSwitcherProps {
  currentCustomer: ICustomer | undefined;
}

export const OrganizationSwitcher = ({ currentCustomer }: IOrganizationSwitcherProps) => {
  const { userState } = useContext(UserContext);
  const [organizationsListAnchor, setOrganizationListAnchor] = useState<HTMLElement | null>(null);
  const theme = useTheme();
  const organizationListOpen = Boolean(organizationsListAnchor);

  const handleOpenOrganizationDetails = (event: React.MouseEvent<HTMLElement>) => {
    setOrganizationListAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setOrganizationListAnchor(null);
  };

  if (userState.roles !== 'Administrator') {
    return null;
  }

  return (
    <div>
      <CustomerSwitcherContainer
        onClick={handleOpenOrganizationDetails}
        sx={{ backgroundColor: theme.palette.primary.main }}
      >
        <CorporateFareIcon sx={{ color: 'white' }} />
        <Typography
          sx={{
            display: '-webkit-box',
            '-webkit-line-clamp': '1',
            '-webkit-box-orient': 'vertical',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            fontSize: theme.typography.pxToRem(14),
            width: '80%',
            boxSizing: 'content-box',
            '-webkit-box-sizing': ' content-box',
            '-moz-box-sizing': 'content-box',
          }}
          color="white"
        >
          {currentCustomer?.name}
        </Typography>
        <UnfoldMoreTwoToneIcon sx={{ color: 'white' }} />
      </CustomerSwitcherContainer>
      <OrganizationDetails
        currentCustomer={currentCustomer}
        open={organizationListOpen}
        anchor={organizationsListAnchor}
        onClose={handleClose}
      />
    </div>
  );
};
