import { Endpoint } from '@api';
import { SnackbarAction, SnackbarActionType, SnackbarType } from '@contexts';
import { httpsService } from '@services';

import { ICreateCustomerProps } from './types';

interface AddCustomerProps {
  snackbarDispatch: SnackbarAction;
  name: string;
}

const addCustomer = async ({ snackbarDispatch, name }: AddCustomerProps) => {
  try {
    const res = await httpsService.post<ICreateCustomerProps>(Endpoint.CUSTOMERS_LIST, {
      name,
    });

    if (res?.status === 204) {
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: `Dodano nową organizacje`,
          type: SnackbarType.SUCCESS,
        },
      });
    }
  } catch (err) {
    snackbarDispatch({
      type: SnackbarActionType.SHOW,
      payload: {
        message: 'Błąd dodawania organizacji',
        type: SnackbarType.ERROR,
      },
    });
  }
};

export default addCustomer;
