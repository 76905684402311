import { GridColDef } from '@mui/x-data-grid';

export const CustomerListColumns = (): GridColDef[] => {
  return [
    {
      field: 'name',
      headerName: 'Organizacje',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      flex: 1,
    },
  ];
};
