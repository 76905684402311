import { UserProps, UserState } from '@contexts';
import { getItem } from '@services';
import { ICustomer, LocalStorageKey } from '@api/types';

const getStoredSessionData = (): null | UserState => {
  const storedSessionData = getItem<UserState>(LocalStorageKey.LOGIN_RESPONSE_BODY);

  if (typeof storedSessionData === 'string' || !storedSessionData) return null;
  return storedSessionData;
};

const getStoredProfileData = (): null | UserProps => {
  const storedProfileData = getItem<UserProps>(LocalStorageKey.MY_PROFILE_DATA);

  if (typeof storedProfileData === 'string' || !storedProfileData) return null;
  return storedProfileData;
};

const getStoredCurrentCustomerData = (): null | ICustomer => {
  const storedCustomerData = getItem<ICustomer>(LocalStorageKey.CURRENT_CUSTOMER);

  if (typeof storedCustomerData === 'string' || !storedCustomerData) return null;
  return storedCustomerData;
};

export { getStoredSessionData, getStoredProfileData, getStoredCurrentCustomerData };
