import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';

export interface IPatchStreetRequest {
  name: string;
  streetId: string;
}

export const updateStreetName = ({ name, streetId }: IPatchStreetRequest): Promise<AxiosResponse> => {
  return httpsService.patch(Endpoint.STREETS_COMMON, { name, streetId });
};
