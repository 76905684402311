import { Row, SkeletonLoader } from '@components';
import { ModalActionType, ModalContext, UserProps } from '@contexts';
import { DialogContent, useTheme, Box, Typography, Button, useMediaQuery } from '@mui/material';
import { useActivateClient, useActivateLocator } from '@api';
import { useContext } from 'react';

import { UserProfileForm } from './UserProfile.form';
import { UserProfileContainer } from './UserProfile.styles';

interface IUserProfileProps {
  variant: 'client' | 'locator';
  user: UserProps;
}

const UserProfile = ({ variant, user }: IUserProfileProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { mutateAsync: activateClient } = useActivateClient();
  const { mutateAsync: activateLocator } = useActivateLocator();
  const { modalDispatch } = useContext(ModalContext);

  const activation = async () => {
    if (variant === 'locator') {
      try {
        await activateLocator({ locatorId: user.id, active: !user.isActive });
        modalDispatch({
          type: ModalActionType.DISMISS,
        });
      } catch (error) {
        console.error(error);
      }
    }
    if (variant === 'client') {
      try {
        await activateClient({ clientId: user.id, active: !user.isActive });
        modalDispatch({
          type: ModalActionType.DISMISS,
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <DialogContent
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        rowGap: '20px',
      }}
    >
      {Object.keys(user).length > 0 ? (
        <UserProfileContainer>
          <Box>
            <Typography variant="h4" textAlign="center">
              Profil użytkownika {user.name}
            </Typography>
          </Box>

          <Box sx={{ alignSelf: 'center', width: '100%' }}>
            <UserProfileForm variant={variant} user={user} />
          </Box>
          <Box>
            {user.roles[0] !== 'Administrator' && (
              <Row
                sx={{
                  flexDirection: isMobile ? 'column' : 'row',
                  rowGap: '10px',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    width: '40%',
                    height: '48px',
                    borderRadius: '8px',
                    backgroundColor: user.isActive ? theme.palette.primary.dark : theme.palette.primary.dark,
                    '&:hover': {
                      backgroundColor: user.isActive ? theme.palette.primary.main : theme.palette.primary.main,
                    },
                  }}
                  onClick={activation}
                >
                  <Typography variant="button" color="white">
                    {user.isActive ? 'Deaktywuj' : 'Aktywuj'}
                  </Typography>
                </Button>
              </Row>
            )}
          </Box>
        </UserProfileContainer>
      ) : (
        <Box>
          <SkeletonLoader loadersCount={6} />
        </Box>
      )}
    </DialogContent>
  );
};

export default UserProfile;
