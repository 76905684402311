import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';

export interface IChangeNetworkRequest {
  joinEui?: string | null;
  newNetwork: 'TTN' | 'Emitel';
  meterId: string;
}

export const changeNetworkWaterMeter = ({
  newNetwork,
  joinEui,
  meterId,
}: IChangeNetworkRequest): Promise<AxiosResponse> => {
  return httpsService.patch(Endpoint.WATER_METER_NETWORK.replace(':meterId', meterId), { joinEui, newNetwork });
};
