import React, { useEffect, useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { Input } from '@components';
import { useOnboardUserPassword } from '@api';
import { PATH } from '@router';

import { WodmarLogo } from '../Login/components';

const passwordRegexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/;

interface IFormInput {
  password: string;
  confirmPassword: string;
}

const newPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .max(100, 'Hasło nie może przekraczać 100 znaków')
    .min(8, 'Hasło musi mieć conajmniej 8 znaków')
    .matches(
      passwordRegexp,
      'Hasło musi zawierać conajmniej osiem znaków, duża i małą literę, cyfrę i znak specjalny. Znaki specjalne - @$!%*?&.'
    )
    .required('Hasło jest wymagane'),
  confirmPassword: yup.string().oneOf([yup.ref('password'), undefined], 'Hasła muszą być takie same'),
});

export const Onboarding = () => {
  const theme = useTheme();
  const [token, setToken] = useState('');
  const { search } = useLocation();
  const queryToken = new URLSearchParams(search).get('token');
  const navigate = useNavigate();
  const { mutateAsync: onboardUserPassword } = useOnboardUserPassword();

  useEffect(() => {
    if (queryToken) {
      setToken(queryToken);
    }
  }, [queryToken]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm<IFormInput>({
    resolver: yupResolver(newPasswordSchema),
    mode: 'onChange',
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const onSubmit: SubmitHandler<IFormInput> = ({ password, confirmPassword }) => {
    if (isValid && password === confirmPassword) {
      onboardUserPassword({ password, confirmPassword, token })
        .then(() => {
          navigate(PATH.LOGIN, { replace: true });
        })
        .catch((e) => {
          if (e.response?.data?.code === 'TOKEN_EXPIRED') {
            navigate(`/${PATH.EXPIRED_TOKEN}?token=${token}`, { replace: true });
          }
        });
    }
    reset();
  };

  return (
    <Grid height={'100vh'} container justifyContent="center" alignContent="center">
      {token ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '10px',
              padding: '10px',
              maxWidth: '300px',
            }}
          >
            <WodmarLogo />
            <Typography align="center" variant="body1">
              Ustaw hasło
            </Typography>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  error={!!errors.password}
                  helperText={errors.password && errors.password?.message && errors.password.message}
                  type="password"
                  label="Hasło"
                  autoComplete="Hasło"
                  ref={null}
                />
              )}
            />
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  error={!!errors.confirmPassword}
                  helperText={
                    errors.confirmPassword && errors.confirmPassword?.message && errors.confirmPassword.message
                  }
                  type="password"
                  label="Potwierdź hasło"
                  autoComplete="Potwierdź hasło"
                  ref={null}
                />
              )}
            />
            <Button
              variant="contained"
              sx={{
                width: '100%',
                height: '48px',
                backgroundColor: theme.palette.primary.dark,
                borderRadius: '8px',
              }}
              type="submit"
              disabled={!isDirty}
            >
              <Typography variant="body1">Ustaw nowe hasło</Typography>
            </Button>
          </Box>
        </form>
      ) : (
        <Box>
          <WodmarLogo />
          <Typography textAlign={'center'} variant="h1">
            Niepoprawny link
          </Typography>
        </Box>
      )}
    </Grid>
  );
};
