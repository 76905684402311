import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';

export interface IActivateLocator {
  locatorId: string;
  active: boolean;
}

export const activateLocator = ({ locatorId, active }: IActivateLocator): Promise<AxiosResponse> => {
  return httpsService.put(Endpoint.ACTIVATE_LOCATOR.replace(':locatorId', locatorId), active);
};
