import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';

export interface IAddClientRequest {
  name: string;
  surname: string;
  email: string;
}

export const addClient = ({ name, surname, email }: IAddClientRequest): Promise<AxiosResponse> => {
  return httpsService.post(Endpoint.ADD_CLIENT, { name, surname, email });
};
