import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, Divider, useTheme, useMediaQuery } from '@mui/material';
import { UserActionType, UserContext } from '@contexts';
import { getStoredProfileData, getStoredSessionData } from '@helpers';
import { LoginForm } from '@forms';
import { PATH } from '@router';

import { ContactUs, WodmarLogo } from './components';

interface LocationProps {
  from?: {
    pathname: string;
  };
}

export const Login: React.FC = () => {
  const { userDispatch } = useContext(UserContext);
  const location = useLocation();
  const state = location.state as LocationProps;
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const storedSessionData = getStoredSessionData();
    if (storedSessionData) {
      userDispatch({
        type: UserActionType.LOG_IN,
        payload: storedSessionData,
      });
      const storedProfileData = getStoredProfileData();
      if (storedProfileData)
        userDispatch({
          type: UserActionType.GET_USER_DATA,
          payload: storedProfileData,
        });
      if (state?.from?.pathname) {
        navigate(state.from.pathname);
      } else {
        navigate(`${PATH.DASHBOARD}/${PATH.PANEL}`);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <Grid container direction="column">
      {!isMobile && (
        <Grid item justifyContent={isMobile ? 'center' : 'right'} sx={{ height: '10vh' }}>
          <ContactUs />
        </Grid>
      )}

      <Grid container alignContent="center" justifyContent={'center'} sx={{ height: '90vh' }}>
        <Grid
          item
          width="480px"
          minWidth="290px"
          marginBottom={isMobile ? '0' : '15vh'}
          marginLeft={isMobile ? 1 : 0}
          marginRight={isMobile ? 1 : 0}
        >
          <WodmarLogo />
          <Divider sx={{ marginTop: '43px', marginBottom: '33px' }} />
          <LoginForm />
        </Grid>
      </Grid>
    </Grid>
  );
};
