import { Box } from '@mui/material';
import { SkeletonLoader } from '@components';
import { useGetLocationMeters } from '@api';

import { TableDetailsWater } from './components/table-details/table-details-water.component';
import { TableDetailsHeater } from './components/table-details/table-details-heater.component';

export const TableRowDetails = ({ locationId }: { locationId: string }) => {
  const { data, isLoading } = useGetLocationMeters({ locationId });

  if (isLoading) {
    return <SkeletonLoader loadersCount={5} />;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', margin: 1, width: '100%', gap: '16px' }}>
      {!!data?.data?.waterMeters.length && <TableDetailsWater meter={data?.data.waterMeters} />}
      {!!data?.data?.heatMeters.length && <TableDetailsHeater meter={data?.data.heatMeters} />}
    </Box>
  );
};
