import { TextFieldProps } from '@mui/material';
import StyledInput from './input.styles';

const Input: React.FC<TextFieldProps> = (props) => {
  return (
    <StyledInput
      inputProps={{
        autoComplete: 'off',
      }}
      variant="outlined"
      {...props}
    />
  );
};

export default Input;
