import { SxProps, Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  listActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(16),
  },

  dataGridWrapper: {
    height: theme.typography.pxToRem(370),
    width: '100%',
  },

  column: {
    '&.MuiDataGrid-columnHeader': {
      padding: theme.typography.pxToRem(16),
      backgroundColor: '#f5f5f5',
    },
    '& > .MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

export const useDataGridSx = (): SxProps<Theme> => {
  const theme = useTheme<Theme>();

  return {
    border: 0,
    '& .MuiDataGrid-cell': {
      padding: theme.typography.pxToRem(16),
    },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: '#f5f5f5',
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
  };
};
