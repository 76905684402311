import { DeviceMeasurements } from '../../types';
import { Box } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { iconSet } from '@assets';
import { Icon } from '@components';
import moment from 'moment';
import 'moment/locale/pl';

const MeasurementGridColumnsHeatMeter: GridColDef[] = [
  {
    field: 'date-day',
    headerName: 'Data',
    headerAlign: 'center',
    align: 'center',
    valueGetter: (params: GridValueGetterParams<any, DeviceMeasurements>) =>
      String(moment.utc(params.row.date).local().format('l')),
    width: 150,
    sortable: false,
  },
  {
    field: 'date-hours',
    headerName: 'Godzina',
    headerAlign: 'center',
    align: 'center',
    valueGetter: (params: GridValueGetterParams<any, DeviceMeasurements>) =>
      String(moment.utc(params.row.date).local().format('LTS')),
    width: 150,
    sortable: false,
  },
  {
    field: 'energyValue',
    headerName: 'Stan licznika',
    headerAlign: 'center',
    align: 'center',
    valueGetter: (params: GridValueGetterParams<any, DeviceMeasurements>) => `${params.row.energyValue} GJ`,
    flex: 1,
    sortable: false,
  },
  {
    field: 'details',
    headerName: 'Szczegóły',
    headerAlign: 'center',
    align: 'center',
    renderCell: (params: GridRenderCellParams<any, DeviceMeasurements, any>) => (
      <Box sx={{ width: 50 }}>
        <Icon iconKey={iconSet.measurementDetails} />
      </Box>
    ),
    flex: 1,
    sortable: false,
  },
];

export { MeasurementGridColumnsHeatMeter };
