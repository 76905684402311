import { Box, styled } from '@mui/material';

const Row = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

const Column = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export { Row, Column };
