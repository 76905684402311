export const html = `
<!DOCTYPE html>
<html>
<head>
\t<meta http-equiv="content-type" content="text/html; charset=utf-8"/>
\t<title></title>
\t<meta name="generator" content="LibreOffice 7.6.4.1 (Linux)"/>
\t<meta name="author" content="Konrad Grussy"/>
\t<meta name="created" content="2024-06-18T13:59:00"/>
\t<meta name="changedby" content="Mikołaj Orzechowski (261418)"/>
\t<meta name="changed" content="2024-06-18T14:04:00"/>
\t<meta name="AppVersion" content="16.0000"/>
\t<meta name="GrammarlyDocumentId" content="12ac9821fca56628f0cb330133d08cb7cfcc6d456b75508c8e37fa18efdb0a1e"/>
\t<style type="text/css">
\t\t@page { size: 8.27in 11.69in; margin: 0.98in }
\t\tp { line-height: 115%; text-align: left; orphans: 2; widows: 2; margin-bottom: 0.1in; direction: ltr; background: transparent }
\t\ta:link { color: #0563c1; text-decoration: underline }
\t\tstrong { font-weight: bold }
\t\ta:visited { color: #954f72; text-decoration: underline }
\t</style>
</head>
<body lang="pl-PL" link="#0563c1" vlink="#954f72" dir="ltr"><p align="center" style="line-height: 150%; margin-bottom: 0in">
<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><b>Polityka
prywatności i plików cookies serwisu internetwego www.wodmar-systems.pl dalej jako "Polityka"</b></font></font></font></font></font></p>

</p>
<ol>
\t<li value="1"><p align="justify" style="line-height: 150%; margin-bottom: 0in">
\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><b>Postanowienia
\togólne</b></font></font></font></font></font></p></li>
</ol>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 11pt">Niniejsza
Polityka określa zasady przetwarzania danych osobowych pozyskanych
za&nbsp;pośrednictwem serwisu internetowego <!-- Lub/oraz aplikacji mobilnej --></font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><b>wodmar-systems.pl</b></font></font></font>
<font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 11pt">(„</font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><b>Serwis</b></font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 11pt">”)
oraz w ramach świadczenia powiązanych z Serwisem usług przez
Administratora. </font></font></font></font></font>
</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0.11in"><font color="#000000"><font face="Arial, serif">Administratorem
danych osobowych w rozumieniu przepisów o ochronie danych osobowych
jest Beata Pajdowska prowadząca działalność gospodarczą pod
firmą Beata Pajdowska WODMAR, ul. Jana Długosza 3/A, 51-162
Wrocław, NIP: 8951420119, REGON: 020430185 </font></font><font face="Arial, serif">(</font><font color="#000000"><font face="Arial, serif"><span style="letter-spacing: 0.2pt">dalej
jako „</span></font></font><font color="#000000"><font face="Arial, serif"><span style="letter-spacing: 0.2pt"><b>Administrator</b></span></font></font><font color="#000000"><font face="Arial, serif"><span style="letter-spacing: 0.2pt">”).</span></font></font></p>
<p align="justify" style="letter-spacing: 0.2pt; line-height: 150%; margin-bottom: 0in">
<br/>

</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 11pt">Podstawą
prawną przetwarzania danych osobowych przez Administratora jest:</font></font></font></font></font></p>
<ol>
\t<li value="1"><p align="justify" style="line-height: 150%; margin-bottom: 0in">
\t<span style="display: inline-block; border: none; padding: 0in"><font size="3" style="font-size: 12pt"><strong><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-weight: normal"><span style="background: #ffffff"><font face="Times New Roman, serif">Rozporządzenie
\tParlamentu Europejskiego i&nbsp;Rady (UE) 2016/679 z dnia 27
\tkwietnia 2016 r. w&nbsp;sprawie ochrony osób fizycznych w związku
\tz&nbsp;przetwarzaniem danych osobowych i&nbsp;w&nbsp;sprawie
\tswobodnego przepływu takich danych oraz uchylenia dyrektywy
\t95/46/WE - ogólne rozporządzenie o ochronie danych, (dalej jako
\t„</span></span></font></font></strong><strong><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="background: #ffffff">Rozporządzenie</span></font></font></strong><strong><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-weight: normal"><span style="background: #ffffff">”
\tlub „</span></span></font></font></strong><strong><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="background: #ffffff">RODO</span></font></font></strong><strong><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-weight: normal"><span style="background: #ffffff">”).
\tOficjalny tekst Rozporządzenia:
\t</span></span></font></font></strong><font color="#0563c1"><u><a href="http://eur-lex.europa.eu/legal-content/PL/TXT/?uri=CELEX%3A32016R0679"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="background: #ffffff">http://eur-lex.europa.eu/legal-content/PL/TXT/?uri=CELEX%3A32016R0679</span></font></font></a></u></font><strong><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-weight: normal"><span style="background: #ffffff">;</span></span></span></font></font></strong></font></font></p></li>
\t<li><p align="justify" style="line-height: 150%; margin-bottom: 0in">
\t<span style="display: inline-block; border: none; padding: 0in"><font size="3" style="font-size: 12pt"><strong><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-weight: normal"><span style="background: #ffffff"><font face="Times New Roman, serif">Ustawa
\tz dnia 10 maja 2018 r. o ochronie danych osobowych Dz.U. 2019, poz.
\t1781, (dalej jako „</span></span></font></font></strong><strong><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="background: #ffffff">Ustawa</span></font></font></strong><strong><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-weight: normal"><span style="background: #ffffff">”).</span></span></span></font></font></strong></font></font></p></li>
</ol>
<p align="justify" style="line-height: 150%; margin-left: 0.5in; margin-bottom: 0in">
<br/>

</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="letter-spacing: 0.2pt">Z
uwagi na brak spełnienia przez Administratora przesłanek wskazanych
w art. 37 ust. 1 Rozporządzenia, Administrator nie wyznaczył
Inspektora Ochrony Danych Osobowych. W związku z powyższym wszelki
kontakt w zakresie ochrony danych osobowych należy kierować
bezpośrednio do Administratora za pośrednictwem poczty
elektronicznej na adres e-mail: </span></font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="letter-spacing: 0.2pt"><b>biuro@wodmar.biz.pl</b></span></font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="letter-spacing: 0.2pt"><i>
</i></span></font></font></font></font></font>
</p>
<p align="justify" style="letter-spacing: 0.2pt; line-height: 150%; margin-bottom: 0in">
<br/>

</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="letter-spacing: 0.2pt">Administrator
</span></font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 11pt">dokłada
szczególnej staranności do poszanowania prywatności użytkowników
Serwisu (dalej jako „</font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><b>Użytkownicy</b></font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 11pt">”).
Administrator gwarantuje poufność wszelkich przekazanych danych
osobowych oraz zapewnia podjęcie wszelkich środków bezpieczeństwa
i ochrony danych osobowych wymaganych przez przepisy o ochronie
danych osobowych w tym przepisy Rozporządzenia i Ustawy. Dane
osobowe są gromadzone z należytą starannością i odpowiednio
chronione przed dostępem do nich przez osoby do tego nieupoważnione.</font></font></font></font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 11pt">Administrator
może podejmować decyzje w indywidualnych przypadkach w sposób
zautomatyzowany oraz&nbsp; stosować profilowanie Użytkowników.</font></font></font></font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><a name="OLE_LINK2"></a><a name="OLE_LINK1"></a>
<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="background: #ffffff">Administrator
nie gromadzi ani nie przetwarza danych wrażliwych, zwanych w
Rozporządzeniu „szczególnymi kategoriami danych osobowych”. W
przypadku przesłania przez Użytkownika danych wrażliwych,
Administrator nie będzie ich przetwarzał i natychmiast je usunie.
Administrator nie chce również zbierać ani przetwarzać danych
osób nieletnich w wieku poniżej 16 lat.</span></font></font></font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><br/>

</p>
<ol start="2">
\t<li><p style="line-height: 150%; margin-bottom: 0in"><font face="Arial, serif"><b>Uprawnienia
\tużytkownika</b></font></p></li>
</ol>
<p style="line-height: 150%; margin-left: 0.5in; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Arial, serif">Użytkownik
ma następujące prawa wynikające z przetwarzania jego danych
osobowych:</font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><br/>

</p>
<ol type="a">
\t<li value="1"><p align="justify" style="line-height: 150%; margin-bottom: 0in">
\t<font face="Arial, serif"><b>prawo żądania dostępu do własnych
\tdanych osobowych </b></font><font face="Arial, serif">– Użytkownik
\tma prawo do uzyskania potwierdzenia czy Administrator przetwarza
\tjego dane osobowe a jeżeli ma to miejsce, jest uprawniony do
\tuzyskania dostępu do danych oraz otrzymania informacji dotyczących
\tdanych takich jak np.: cel przetwarzania, kategorie danych,
\tinformacje o odbiorcach lub kategoriach odbiorców, którym dane
\tzostały lub zostaną ujawnione, planowany okres przechowywania
\tdanych osobowych bądź kryteria ustalania tego okresu; powyższe
\tprawo</font><font face="Arial, serif"> obejmuje również prawo do
\totrzymania bezpłatnie kopii danych (za wszelkie kolejne kopie, o
\tktóre zwróci się Użytkownik, Administrator może pobrać opłatę
\tw rozsądnej wysokości wynikającej z kosztów administracyjnych),</font></p></li>
\t<li><p align="justify" style="line-height: 150%; margin-bottom: 0in">
\t<font face="Arial, serif"><b>prawo do sprostowania danych
\tosobowych</b></font><font face="Arial, serif">&nbsp;(gdy są one
\tnieprawidłowe), co obejmuje także wniesienie żądania
\tuzupełnienia niekompletnych danych osobowych,</font></p></li>
\t<li><p align="justify" style="line-height: 150%; margin-bottom: 0in">
\t<font face="Arial, serif"><b>prawo do usunięcia danych
\tosobowych</b></font><font face="Arial, serif">&nbsp;(tzw. „prawo
\tdo bycia zapomnianym”), w następujących okolicznościach: gdy
\tdane osobowe Użytkownika nie są już niezbędne do celów, dla
\tktórych zostały zebrane, Użytkownik cofnął zgodę na ich
\tprzetwarzanie i nie istnieje inna podstawa przetwarzania danych,
\tUżytkownik wniósł sprzeciw wobec przetwarzania, dane osobowe były
\tprzetwarzane niezgodnie z prawem, dane osobowe muszą zostać
\tusunięte w celu wywiązania się z obowiązku prawnego
\tprzewidzianego w Prawie Unii lub prawie państwa członkowskiego,
\tktóremu podlega Administrator, dane osobowe zostały zebrane w
\tzwiązku z oferowaniem usług społeczeństwa informacyjnego,</font></p></li>
\t<li><p align="justify" style="line-height: 150%; margin-bottom: 0in">
\t<font face="Arial, serif"><b>prawo do ograniczenia przetwarzania
\tdanych</b></font><font face="Arial, serif">, gdy: Użytkownik
\tkwestionuje prawidłowość danych osobowych (na okres pozwalający
\tAdministratorowi sprawdzenie prawidłowości tych danych),
\tprzetwarzanie jest niezgodne z prawem a Użytkownik sprzeciwia się
\tusunięciu danych, Administrator nie potrzebuje już danych
\tosobowych do celów przetwarzania ale są jednak potrzebne
\tUżytkownikowi do ustalenia, dochodzenia lub obrony roszczeń,
\tUżytkownik wniósł sprzeciw wobec przetwarzania do&nbsp;czasu
\tstwierdzenia czy prawnie uzasadnione podstawy po stronie
\tAdministratora są nadrzędne wobec podstaw sprzeciwu Użytkownika,</font></p></li>
\t<li><p align="justify" style="line-height: 150%; margin-bottom: 0in">
\t<font face="Arial, serif"><b>prawo do wniesienia sprzeciwu wobec
\tprzetwarzania</b></font><font face="Arial, serif">, gdy: Użytkownik
\tnie chce aby Administrator przetwarzał dane osobowe Użytkownika, </font>
\t</p></li>
\t<li><p align="justify" style="line-height: 150%; margin-bottom: 0in">
\t<font face="Arial, serif"><b>prawo do przenoszenia danych</b></font><font face="Arial, serif">,
\tw tym otrzymania od Administratora w ustrukturyzowanym, powszechnie
\tużywanym formacie nadającym się do odczytu maszynowego danych
\tosobowych, które dostarczył Użytkownik, w tym prawo żądania by
\tdane osobowe zostały przesłane przez Administratora bezpośrednio
\tinnemu Administratorowi (o ile jest to technicznie możliwe),</font></p></li>
\t<li><p align="justify" style="line-height: 150%; margin-bottom: 0in">
\t<font face="Arial, serif"><b>prawo do cofnięcia wyrażanej zgody</b></font><font face="Arial, serif">&nbsp;na
\tprzetwarzanie danych w dowolnym momencie (przy czym cofnięcie zgody
\tnie wpływa na zgodność z prawem przetwarzania, którego dokonano
\tna podstawie wyrażonej zgody przed jej cofnięciem),</font></p></li>
\t<li><p align="justify" style="line-height: 150%; margin-bottom: 0in">
\t<font face="Arial, serif"><b>prawo wniesienia skargi do Prezesa
\tUrzędu Ochrony Danych Osobowych</b></font><font face="Arial, serif">,
\tgdy Użytkownik uzna, iż&nbsp;przetwarzanie danych osobowych
\tnarusza przepisy Rozporządzenia.</font></p></li>
</ol>
<p align="justify" style="line-height: 150%; margin-left: 0.5in; margin-bottom: 0in">
<br/>

</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 11pt">W
razie jakichkolwiek pytań związanych z przetwarzaniem danych
osobowych oraz w celu realizacji powyższych uprawnień należy
skontaktować się z Administratorem poprzez wiadomość e-mail
wysłaną na adres: </font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="letter-spacing: 0.2pt"><b>biuro@wodmar.biz.pl</b></span></font></font></font></font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><br/>

</p>
<ol start="3">
\t<li><p align="justify" style="line-height: 150%; margin-bottom: 0in">
\t<font face="Arial, serif"><b>Powierzenie danych</b></font></p></li>
</ol>
<p align="justify" style="line-height: 150%; margin-left: 0.5in; margin-bottom: 0in">
<br/>

</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Arial, serif">Dla
prawidłowego funkcjonowania Serwisu oraz profesjonalnego świadczenia
swoich usług, konieczne jest korzystanie przez Administratora z
usług podmiotów zewnętrznych. Administrator korzysta wyłącznie z
usług takich podmiotów przetwarzających, którzy zapewniają
wystarczające gwarancje wdrożenia odpowiednich środków
technicznych i organizacyjnych, tak by przetwarzanie spełniało
wymogi Rozporządzenia i chroniło prawa osób, których dane
dotyczą, zgodnie z art. 28 Rozporządzenia. </font>
</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font color="#000000"><font face="Arial, serif">Administrator
powierza przetwarzanie danych osobowych następującym kategoriom
podmiotów:</font></font></p>
<ol type="a">
\t<li value="1"><p align="justify" style="line-height: 150%; margin-bottom: 0in">
\t<span style="display: inline-block; border: none; padding: 0in"><font face="Arial, serif"><b><font color="#111111">dostawcy
\tusług księgowych, prawnych i doradczych </span></b></font></font><font color="#111111"><font face="Arial, serif"><b>(w
\tszczególności biuro księgowe, kancelaria prawna, firma
\twindykacyjna)</b></font></font><font color="#111111"><font face="Arial, serif">,</font></font></p></li>
\t<li><p align="justify" style="line-height: 150%; margin-bottom: 0in">
\t<span style="display: inline-block; border: none; padding: 0in"><font face="Arial, serif"><b><font color="#111111">dostawcy
\tusług hostingowych,</span></b></font></font></p></li>
\t<li><p align="justify" style="line-height: 150%; margin-bottom: 0in">
\t<span style="display: inline-block; border: none; padding: 0in"><font face="Arial, serif"><b><font color="#111111">dostawcy
\tusług chmury obliczeniowej,</span></b></font></font></p></li>
\t<li><p align="justify" style="line-height: 150%; margin-bottom: 0in">
\t<span style="display: inline-block; border: none; padding: 0in"><font face="Arial, serif"><b><font color="#111111">dostawcy
\tsystemu CRM,</span></b></font></font></p></li>
\t<li><p align="justify" style="line-height: 150%; margin-bottom: 0in">
\t<span style="display: inline-block; border: none; padding: 0in"><font face="Arial, serif"><b><font color="#111111">dostawcy
\tsystemu do fakturowania,</span></b></font></font></p></li>
\t<li><p align="justify" style="line-height: 150%; margin-bottom: 0in">
\t<span style="display: inline-block; border: none; padding: 0in"><font face="Arial, serif"><b><font color="#111111">dostawcy
\tusług zaopatrujący Administratora w rozwiązania techniczne,
\tinformatyczne oraz organizacyjne (</span></b></font></font><font color="#111111"><font face="Arial, serif"><b>w
\tszczególności dostawcy oprogramowania komputerowego do prowadzenia
\tSerwisu i świadczenia usług, dostawcy poczty elektronicznej oraz
\tdostawcy oprogramowania do zarządzania firmą i udzielania pomocy
\ttechnicznej Administratorom)</b></font></font><font color="#111111"><font face="Arial, serif">
\t</font></font>
\t</p></li>
\t<li><p align="justify" style="line-height: 150%; margin-bottom: 0in">
\t<font color="#111111"><font face="Arial, serif"><b>inne podmioty
\tjeśli korzystanie z ich usług konieczne jest do prawidłowego
\tdziałania Serwisu oraz świadczenia usług przez Administratora.</b></font></font></p></li>
</ol>
<p align="justify" style="line-height: 150%; margin-left: 0.5in; margin-bottom: 0in">
<br/>

</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Arial, serif">Wszystkie
podmioty, którym Administrator powierza przetwarzanie danych
osobowych gwarantują stosowanie odpowiednich środków w ochrony i
bezpieczeństwa danych osobowych wymaganych przez przepisy prawa.</font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Arial, serif">Administrator
może przekazywać dane osobowe do państw trzecich, tj. podmiotów
przetwarzających mających siedzibę poza Europejskim Obszarem
Gospodarczym. </font>
</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Arial, serif">Z
każdym z podmiotów przetwarzających, stosownie do art. 28 ust. 3
Rozporządzenia, ma podpisaną umowę powierzenia przetwarzania
danych osobowych.</font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Arial, serif">Administrator
mogą mieć obowiązek udzielania informacji zebranych przez Serwis
upoważnionym organom na podstawie zgodnych z prawem żądań w
zakresie wynikającym z żądania.</font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><br/>

</p>
<ol start="4">
\t<li><p align="justify" style="line-height: 150%; margin-bottom: 0in">
\t<font face="Arial, serif"><b>Cele przetwarzania, okres
\tprzechowywania oraz zakres danych<!-- Podajemy przykładowe cele i podstawy przetwarzania. Do analizy i dodania pozostałe cele i podstawy. --></b></font></p></li>
</ol>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><br/>

</p>
<ol type="a">
\t<li value="1"><p align="justify" style="line-height: 150%; margin-bottom: 0in">
\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><u><b><span style="background: #ffffff">Cel
\tprzetwarzania: </span></b></u></font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><u><span style="background: #ffffff">nawiązanie
\tkontaktu z Administratorem poprzez wiadomość e-mail.</span></u></font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><u><b><span style="background: #ffffff">
\t</span></b></u></font></font></font></font></font>
\t</p></li>
</ol>
<p align="justify" style="line-height: 150%; margin-left: 0.5in; margin-bottom: 0in">
<br/>

</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><b>Podstawa
prawna:</b></font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt">
</font></font><font color="#111111"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="background: #ffffff">Artykuł
6 ust. 1 lit. a) Rozporządzenia.</span></font></font></font></font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><b>Okres
przechowywania: </b></font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt">do
momentu odwołania zgody przez Użytkownika a&nbsp;po&nbsp;odwołaniu
zgody przez okres czasu odpowiadający okresowi przedawnienia
roszczeń jakie może podnosić Administrator i jakie mogą być
podnoszone wobec niego.</font></font></font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><b>Zakres
danych:</b></font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt">
imię, nazwisko, adres e-mail + dane dobrowolnie zamieszczone w
treści wiadomości przez Użytkownika.</font></font></font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="background: #ffffff">Konsekwencją
niepodania powyższych danych może być brak możliwości nawiązania
kontaktu z Administratorem poprzez wiadomość e-mail.</span></font></font></font></font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in; background: #ffffff">
<br/>

</p>
<ol type="a" start="2">
\t<li><p align="justify" style="line-height: 150%; margin-bottom: 0in; background: #ffffff">
\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><u><b>Cel
\tprzetwarzania: </b></u></font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt"><u>prowadzenie
\tksiąg rachunkowych</u></font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt"><u><b>
\t</b></u></font></font></font></font>
\t</p></li>
</ol>
<p align="justify" style="line-height: 150%; margin-left: 0.5in; margin-bottom: 0in; background: #ffffff">
<br/>

</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><b>Podstawa
prawna: </b></font></font><font color="#111111"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="background: #ffffff">Artykuł
6 ust. 1 lit. c) Rozporządzenia w zw. z art. 86 § 1 ustawy z dnia
29 stycznia 1997 roku Ordynacja Podatkowa (tj. Dz.U. 2019, poz. 900)
lub art. 74 ust. 2 ustawy z dnia 29 września 1994 r. o rachunkowości
(tj. Dz.U. z 2019 r. poz. 351)</span></font></font></font></font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><b>Okres
przechowywania: </b></font></font><font color="#111111"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="background: #ffffff">Dane
są przechowywane przez okres wymagany przepisami prawa nakazującymi
Administratorowi&nbsp; przechowywanie ksiąg podatkowych (do czasu
upływu okresu przedawnienia zobowiązania podatkowego, chyba że
ustawy podatkowe stanowią inaczej) lub rachunkowych (5 lat, licząc
od początku roku następującego po roku obrotowym, którego dane
dotyczą).</span></font></font></font></font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><b>Zakres
danych: </b></font></font><font color="#111111"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="background: #ffffff">imię,
nazwisko, numer nip, adres e-mail, numer telefonu.</span></font></font></font></font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><br/>

</p>
<ol type="a" start="3">
\t<li><p align="justify" style="line-height: 150%; margin-bottom: 0in">
\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font color="#111111"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><u><b><span style="background: #ffffff">Cel
\tprzetwarzania:</span></b></u></font></font></font><font color="#111111"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><u><span style="background: #ffffff">
\tustalenie, dochodzenie lub obrona roszczeń jakie może podnosić
\tAdministrator lub jakie mogą być podnoszone wobec Administratora</span></u></font></font></font></font></font></p></li>
</ol>
<p align="justify" style="line-height: 150%; margin-left: 0.5in; margin-bottom: 0in">
<br/>

</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font color="#111111"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><b><span style="background: #ffffff">Podstawa
prawna: </span></b></font></font></font><font color="#111111"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="background: #ffffff">Artykuł
6 ust. 1 lit. f) Rozporządzenia</span></font></font></font></font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font color="#111111"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><b><span style="background: #ffffff">Okres
przechowywania:</span></b></font></font></font><font color="#111111"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="background: #ffffff">
Dane są przechowywane przez okres istnienia prawnie uzasadnionego
interesu realizowanego przez Administratora, nie dłużej jednak niż
przez okres przedawnienia roszczeń w stosunku do osoby, której dane
dotyczą, z tytułu prowadzonej  przez Administratora działalności
gospodarczej. Okres przedawnienia określają przepisy prawa, w
szczególności kodeksu cywilnego. </span></font></font></font></font></font>
</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font color="#111111"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><b>Zakres
danych:</b></font></font></font><font color="#111111"><font face="Arial, serif"><font size="2" style="font-size: 11pt">
imię, nazwisko, adres e-mail, adres zamieszkania, numer telefonu.</font></font></font></font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><br/>

</p>
<ol type="a" start="4">
\t<li><p align="justify" style="line-height: 150%; margin-bottom: 0in">
\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><u><b>Cel
\tprzetwarzania: </b></u></font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><u>prowadzenie
\tmarketingu usług własnych oraz zapewnienie jak najszerszej
\tobecności w Internecie.</u></font></font></font></font></font></p></li>
</ol>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><b>Podstawa
prawna:</b></font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt">
</font></font><font color="#111111"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="background: #ffffff">Artykuł
6 ust. 1 lit. f) Rozporządzenia.</span></font></font></font></font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><b>Okres
przechowywania: </b></font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt">Dane
są przechowywane przez okres istnienia prawnie uzasadnionego
interesu realizowanego przez Administratora, nie dłużej jednak niż
przez okres przedawnienia roszczeń w stosunku do osoby, której dane
dotyczą, z tytułu prowadzonej  przez Administratora działalności
gospodarczej. Okres przedawnienia określają przepisy prawa, w
szczególności kodeksu cywilnego. </font></font><font color="#111111"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="background: #ffffff">Administrator
nie może przetwarzać danych w celu marketingu bezpośredniego w
przypadku wyrażenia skutecznego sprzeciwu w tym zakresie przez
osobę, której dane dotyczą.</span></font></font></font></font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><b>Zakres
danych: </b></font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt">adres
e-mail, imię, nazwisko, numer telefonu. </font></font></font></font>
</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><br/>

</p>
<ol start="5">
\t<li value="5"><p style="line-height: 150%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><b><span style="background: #ffffff">Pliki
\tcookies</span></b></font></font></font></font></p></li>
</ol>
<p style="line-height: 150%; margin-left: 0.5in; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="background: #ffffff">Serwis
wykorzystuje pliki cookies tj. </span></font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt">niewielkie
informacje tekstowe, przechowywane na urządzeniu końcowym
Użytkownika (np. komputerze, tablecie, smartfonie), które mogą być
odczytywane przez system teleinformatyczny Administratora.</font></font></font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="background: #ffffff">Podczas
pierwszej wizyty na stronie wyświetlana jest informacja na temat
stosowania plików cookies wraz z pytaniem o zgodę na
wykorzystywanie plików cookies. Dzięki specjalnemu narzędziu
Użytkownik ma możliwość zarządzania plikami cookies z poziomu
Serwisu. Użytkownik może zmienić ustawienia cookies z poziomu
swojej przeglądarki albo w ogóle usunąć pliki cookies. Należy
pamiętać, że wyłączenie plików cookies może powodować
trudności w korzystaniu z Serwisu.</span></font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt"><b><span style="background: #ffffff">
</span></b></font></font></font></font>
</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="background: #ffffff">Administrator
wykorzystuje pliki cookies własne w celu zapewnienia prawidłowego
działania Serwisu.</span></font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt">
</font></font></font></font>
</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="background: #ffffff">Serwis
wykorzystuje funkcje zapewniane przez podmioty trzecie, co wiąże
się z wykorzystywaniem plików cookies pochodzących od podmiotów
trzecich. </span></font></font></font></font>
</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="background: #ffffff">Pliki
cookies są wykorzystywane w celu: identyfikacji Użytkowników;
zapamiętywania aktywności Użytkownika w Serwisie; zapamiętywania
danych z wypełnianych formularzy lub danych logowania, prowadzenia
statystyk.</span></font></font></font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="background: #ffffff">Ustawienia
przeglądarki internetowej w zakresie plików cookies są istotne z
punktu widzenia zgody na korzystanie z plików cookies przez Serwis –
zgodnie z przepisami taka zgoda może być również wyrażona
poprzez ustawienia przeglądarki internetowej. W braku wyrażenia
takiej zgody należy odpowiednio zmienić ustawienia przeglądarki
internetowej w zakresie plików cookies. </span></font></font></font></font>
</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="background: #ffffff">Administrator
korzysta z narzędzia [</span></font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="background: #ffffff">xxx</span></font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="background: #ffffff">]
w celu tworzenia statystyk i ich analizy oraz optymalizacji działania
Serwisu. [</span></font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="background: #ffffff">xxx</span></font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="background: #ffffff">]
</span></font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt">w
sposób automatyczny gromadzi informacje o korzystaniu Serwisu.
Zgromadzone w ten sposób informacje są najczęściej przekazywane
do serwerów </font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="background: #ffffff">[</span></font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="background: #ffffff">xxx</span></font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="background: #ffffff">]</span></font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt">.
W ramach korzystania z </font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="background: #ffffff">[</span></font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="background: #ffffff">xxx</span></font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="background: #ffffff">]
</span></font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt">Administrator
nie gromadzi jakichkolwiek danych osobowych.</font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="background: #ffffff">
<!-- Do potwierdzenia. --><!-- Na tym etapie nie gromadzimy danych w celu tworzenia statystyk, ale gromadzone dane umożliwiają tworzenie statystyk. W związku z tym, czy ten fragment należy usunąć? --></span></font></font></font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><br/>

</p>
<ol start="6">
\t<li value="6"><p style="line-height: 150%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><b><span style="background: #ffffff">Logi
\tserwera</span></b></font></font></font></font></p></li>
</ol>
<p style="line-height: 150%; margin-left: 0.5in; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt">Korzystanie
z Serwisu wiąże się z przesyłaniem zapytań do serwera, na którym
przechowywany jest Serwis. Każde zapytanie skierowane do serwera
zapisywane jest w logach serwera. </font></font></font></font>
</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt">Logi
obejmują m.in. adres IP Użytkownika, datę i czas serwera,
informacje o przeglądarce internetowej i systemie operacyjnym
Użytkownika. Logi zapisywane i przechowywane są na serwerze. </font></font></font></font>
</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt">Dane
zapisane w logach serwera nie są kojarzone z konkretnymi osobami
korzystającymi z Serwisu i&nbsp;nie są wykorzystywane przez
Administratora w celu identyfikacji Użytkownika.</font></font></font></font></p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 150%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Arial, serif"><font size="2" style="font-size: 11pt">Dane
powyższe są wykorzystywane jedynie dla celów administrowania
serwerem. Logi serwera stanowią wyłącznie materiał pomocniczy
służący do administrowania stroną, a ich zawartość nie jest
ujawniana nikomu poza osobami upoważnionymi do administrowania
serwerem. </font></font></font></font>
</p>
<p style="line-height: 106%; margin-bottom: 0.11in"><br/>
<br/>

</p>
</body>
</html>
`