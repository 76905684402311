import React, { useContext, useEffect, useState } from 'react';
import { useParams, Params } from 'react-router-dom';
import { Box, Divider, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { DeviceContext, ModalActionType, ModalContext, UserContext } from '@contexts';
import { Icon, SkeletonLoader } from '@components';
import { iconSet } from '@assets';
import getDeviceDetails from 'src/api/getDeviceDetails';

import { NetworkType } from '@api/types';

import { InfoListItem } from '../../components/InfoList/InfoList';
import { InfoList } from '../../components';
import { AddEuiDialog } from './add-eui-ttn';

const Resume = () => {
  const params = useParams<Params>();
  const [isDeviceDetailsLoading, setDeviceDetailsLoading] = useState(false);
  const { deviceDispatch, device } = useContext(DeviceContext);
  const { userState } = useContext(UserContext);
  const [network, setNetwork] = useState<NetworkType | undefined>(
    device.radioParameters?.networkType === 0 ? NetworkType.TTN : NetworkType.Emitel
  );
  const { modalDispatch } = useContext(ModalContext);

  const handleNetworkChange = async (event: SelectChangeEvent<number>) => {
    modalDispatch({
      type: ModalActionType.SHOW,
      payload: {
        content: (
          <AddEuiDialog
            retrieveJoinEui={event.target.value === 0}
            meterId={device.id}
            newNetwork={event.target.value === 0 ? 'TTN' : 'Emitel'}
            deviceType={device.type}
            setDeviceDetailsLoading={setDeviceDetailsLoading}
            deviceJoinEui={device?.radioParameters?.joinEui}
          />
        ),
      },
    });
  };

  useEffect(() => {
    if (params.deviceId && params.type) {
      getDeviceDetails({
        deviceDispatch,
        deviceId: params.deviceId,
        setDeviceDetailsLoading,
        type: params.type,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.deviceId]);

  useEffect(() => {
    if (isDeviceDetailsLoading) return;
    if (device?.radioParameters?.networkType === 0) {
      setNetwork(NetworkType.TTN);
    } else if (device?.radioParameters?.networkType === 1) {
      setNetwork(NetworkType.Emitel);
    } else if (device?.radioParameters?.networkType === 2) {
      setNetwork(undefined);
    }
  }, [isDeviceDetailsLoading, device]);

  const generalInformation: InfoListItem[] = [
    {
      propertyName: 'Numer seryjny',
      propertyValue: device?.name ?? '',
    },
    {
      propertyName: 'Urządzenie',
      propertyValue: device?.type ?? '',
    },
    {
      propertyName: 'Alert',
      propertyValue: device?.alert?.errors ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            flexDirection: 'column',
          }}
        >
          <Icon width="40" height="40" iconKey={iconSet.redAlert} />
          <Typography align="right" variant="subtitle1">
            {device.alert.errors[0]}
          </Typography>
        </Box>
      ) : null,
    },
    {
      propertyName: 'Ostatni pomiar',
      propertyValue:
        device.type === 'wodomierz'
          ? device?.lastMeasurement?.value
            ? `${device?.lastMeasurement?.value} m³`
            : ''
          : device?.lastMeasurement?.energyValue
          ? `${device?.lastMeasurement?.energyValue} GJ`
          : '',
    },
    {
      propertyName: 'Interwał wysyłania pakietów',
      propertyValue: String(device?.messageGenerationPeriod) ?? '',
    },
    {
      propertyName: 'Interwał pomiaru',
      propertyValue: String(device?.measurementPeriod) ?? '',
    },
    {
      propertyName: 'Data ważności urządzenia',
      propertyValue: device.expirationDate,
      isEditable: true,
    },
    {
      propertyName: 'Data ważności legalizacji',
      propertyValue: device.legalizationEndDate,
      isEditable: true,
    },
  ];

  const deviceInformation: InfoListItem[] = [
    {
      propertyName: device.type === 'wodomierz' ? 'Rodzaj' : 'Typ montażu',
      propertyValue: device.type === 'wodomierz' ? device.parameters?.type : device.parameters?.assemblyMethod,
    },
    {
      propertyName: 'Średnica',
      propertyValue: device.parameters?.diameter ?? '',
    },
    {
      propertyName: 'Klasa pomiarowa',
      propertyValue: device.parameters?.measurementClass ?? '',
    },
    {
      propertyName: 'Przepływ',
      propertyValue: device.parameters?.flow ?? '',
    },
    {
      propertyName: 'Producent',
      propertyValue: device.parameters?.producer ?? '',
    },
  ];

  const radioInformation: InfoListItem[] = [
    {
      propertyName: 'Dev EUI',
      propertyValue: device.radioParameters?.deviceEui || '-',
    },
    {
      propertyName: 'App key',
      propertyValue: device.radioParameters?.appKey || '-',
    },
    {
      propertyName: 'Join EUI',
      propertyValue: device.radioParameters?.joinEui || '-',
    },
  ];

  return (
    <Box>
      <Box sx={{ marginTop: 5, marginBottom: 5 }}>
        <Typography align="left" variant="h5">
          Informacje ogólne
        </Typography>
      </Box>
      {isDeviceDetailsLoading ? <SkeletonLoader loadersCount={3} /> : <InfoList item={generalInformation} />}
      <Box sx={{ marginTop: 5, marginBottom: 5 }}>
        <Typography align="left" variant="h5">
          Urządzenie
        </Typography>
      </Box>

      {isDeviceDetailsLoading ? <SkeletonLoader loadersCount={3} /> : <InfoList item={deviceInformation} />}
      {userState.roles === 'Administrator' && (
        <>
          <Box sx={{ marginTop: 5, marginBottom: 5 }}>
            <Typography align="left" variant="h5">
              Parametry radiowe
            </Typography>
          </Box>

          {isDeviceDetailsLoading ? (
            <SkeletonLoader loadersCount={3} />
          ) : (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: 512,
                }}
              >
                <div style={{ width: '100%', paddingLeft: 16, paddingRight: 16 }}>
                  <Typography> Typ sieci </Typography>
                </div>
                <div
                  style={{
                    width: '100%',
                    paddingLeft: 16,
                    paddingRight: 16,
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Select
                    style={{ width: '100%' }}
                    value={network}
                    label="Sieć"
                    placeholder={'Sieć'}
                    onChange={handleNetworkChange}
                  >
                    <MenuItem value={NetworkType.TTN}>TTN</MenuItem>
                    <MenuItem value={NetworkType.Emitel}>Emitel</MenuItem>
                  </Select>
                </div>
              </Box>
              <Divider style={{ width: 512, marginTop: 10 }} />
              <InfoList item={radioInformation} />
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default Resume;
