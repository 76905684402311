import { Box, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';

import { useStyles } from './add-locator.styles';
import { AddUserForm } from '../../Users/AddUser/AddUser.form';

export const AddLocator = () => {
  const { classes } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={classes.container}>
      <Paper
        elevation={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '20px',
          padding: '70px 40px',
        }}
      >
        <Typography variant="h4" textAlign="center">
          Dodaj lokatora
        </Typography>
        <Box sx={{ width: isMobile ? '220px' : '500px' }}>
          <AddUserForm variant="locator" />
        </Box>
      </Paper>
    </div>
  );
};
