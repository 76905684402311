import { UseMutationResult, useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

import {
  IPatchLegalizationRequest,
  updateHeatMeterLegalizationDate,
} from '../infrastructure/update-heat-meter-legalization-date';

export const useUpdateHeatMeterLegalizationDateCommand = (): UseMutationResult<
  AxiosResponse,
  unknown,
  IPatchLegalizationRequest
> => {
  return useMutation<AxiosResponse, unknown, IPatchLegalizationRequest>({
    mutationFn: updateHeatMeterLegalizationDate,
  });
};
