import React from 'react';
import { Grid, Typography, useTheme, useMediaQuery } from '@mui/material';

const ContactUs: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid
      container
      justifyContent={isMobile ? 'center' : 'flex-end'}
      paddingTop={isMobile ? '20px' : '40px'}
      paddingRight={isMobile ? '0px' : '40px'}
    >
      <Grid item>
        <Typography
          variant="body2"
          align="right"
          color={theme.palette.grey[900]}
          paddingRight={isMobile ? '5px' : '8px'}
        >
          Nie masz jeszcze konta?
        </Typography>
      </Grid>
      <Grid item>
        <a href="mailto:biuro@wodmar.biz.pl">
          <Typography
            variant="body2"
            align="right"
            color={theme.palette.primary.main}
            paddingRight={isMobile ? '0px' : '40px'}
          >
            Skontaktuj się z nami
          </Typography>
        </a>
      </Grid>
    </Grid>
  );
};
export default ContactUs;
