import { useReducer, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { initialState, userReducer } from './UserReducer';
import { PATH } from '@router';
import { UserContext } from '@contexts';

export const UserProvider: React.FC = ({ children }) => {
  const [userState, userDispatch] = useReducer(userReducer, initialState);
  const navigate = useNavigate();

  useEffect(() => {
    if (userState.sessionExpired) {
      navigate(PATH.LOGIN, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.sessionExpired]);

  return <UserContext.Provider value={{ userState, userDispatch }}>{children}</UserContext.Provider>;
};
