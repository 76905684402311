import { Endpoint } from '@api';
import { SnackbarAction, SnackbarActionType, SnackbarType } from '@contexts';
import { httpsService } from '@services';
import { ResetPasswordApiProps } from './types';

interface ResetPasswordProps {
  snackbarDispatch: SnackbarAction;
  password: string;
  token: string;
}

const resetPassword = async ({ snackbarDispatch, password, token }: ResetPasswordProps) => {
  try {
    const { status } = await httpsService.post<ResetPasswordApiProps>(Endpoint.RESET_PASSWORD, {
      token,
      password,
      confirmPassword: password,
    });

    if (status === 204) {
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: `Hasło zostało zmienione`,
          type: SnackbarType.SUCCESS,
        },
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export default resetPassword;
