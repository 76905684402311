import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useMatch, useNavigate, useLocation } from 'react-router-dom';

import { StyledMenuOption } from './MenuOptions/components/logo/logo.styles';
import { ISideNavOption } from './types';
import { useStyles } from './menu-item.styles';

interface IMenuItem {
  label: string;
  icon: JSX.Element;
  handleClick: () => void;
  to: string;
  disabled?: boolean;
  nestedOptions?: ISideNavOption[];
}

export const MenuItem = ({ label, icon, to, handleClick, nestedOptions, disabled }: IMenuItem) => {
  const [nestedOptionsOpen, setNestedOptionOpen] = useState(false);
  const match = useMatch({ path: to });
  const { pathname } = useLocation();
  const { classes } = useStyles();
  const navigate = useNavigate();

  const handleMenuItemClick = (to: string) => {
    navigate(to);
  };
  const toggleNestedOptions = () => {
    setNestedOptionOpen((opt) => !opt);
  };

  useEffect(() => {
    if (nestedOptions?.some((link) => link.to === pathname)) {
      setNestedOptionOpen(true);
    }
  }, [nestedOptions, pathname]);

  return (
    <Box className={classes.container}>
      <StyledMenuOption
        onClick={() => {
          toggleNestedOptions();
          if (disabled) return;
          handleClick();
        }}
        selected={!!match}
      >
        <div className={classes.iconLabelWrapper}>
          {icon}
          <Typography variant="subtitle1">{label}</Typography>
        </div>
        <div>{nestedOptions && <IconButton>{nestedOptionsOpen ? <ExpandLess /> : <ExpandMore />}</IconButton>}</div>
      </StyledMenuOption>
      {nestedOptions &&
        nestedOptionsOpen &&
        nestedOptions.map((link) => (
          <div key={link.to} className={classes.nestedItemContainer}>
            <MenuItem
              label={link.title}
              icon={link.Icon}
              to={link.to}
              disabled={link.disabled}
              handleClick={() => handleMenuItemClick(link.to)}
            />
          </div>
        ))}
    </Box>
  );
};
