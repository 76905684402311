import { createContext, Dispatch, ReactNode, Reducer } from 'react';
import { ModalActionType } from './types';

type Action =
  | {
      type: ModalActionType.SHOW;
      payload: { content: ReactNode };
    }
  | {
      type: ModalActionType.DISMISS;
    };

type ModalState = {
  visible: boolean;
  content: ReactNode;
};

type ModalAction = Dispatch<Action>;

interface ModalContextProps {
  modalState: ModalState;
  modalDispatch: ModalAction;
}

const initialState: ModalState = {
  visible: false,
  content: null,
};

const ModalContext = createContext<ModalContextProps>({
  modalState: initialState,
  modalDispatch: () => initialState,
});

const modalReducer: Reducer<ModalState, Action> = (state, action) => {
  console.log('Action', action.type);

  switch (action.type) {
    case ModalActionType.SHOW: {
      const { content } = action.payload;
      return {
        ...state,
        visible: true,
        content,
      };
    }
    case ModalActionType.DISMISS: {
      return {
        ...state,
        visible: false,
        content: null,
      };
    }
  }
};

export { modalReducer, ModalContext, initialState };
export { ModalAction, ModalState };
