import * as UserCtx from './UserContext';
import { UserAction as Action, UserState as State } from './UserContext/UserReducer';
import { HttpProvider } from './HttpContext';
import { SnackbarActionType, SnackbarType } from './SnackbarContext/types';
import { SnackbarContext, SnackbarProvider } from './SnackbarContext';
import { SnackbarAction as SnackAction } from './SnackbarContext/SnackbarReducer';
import { DeviceActionType, DeviceContext, DeviceProvider, DeviceAction as DAction } from './DeviceContext';

const { UserProvider, UserContext, UserActionType } = UserCtx;

type UserAction = Action;
type UserState = State;
type SnackbarAction = SnackAction;
type DeviceAction = DAction;

export {
  UserProvider,
  UserActionType,
  UserContext,
  UserAction,
  UserState,
  HttpProvider,
  SnackbarActionType,
  SnackbarProvider,
  SnackbarType,
  SnackbarContext,
  SnackbarAction,
  DeviceProvider,
  DeviceActionType,
  DeviceContext,
  DeviceAction,
};
export * from './ModalContext';
export * from './UserContext';
