import { Collapse, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useContext, useState } from 'react';
import { LocationResult } from '@api';
import moment from 'moment/moment';
import Chip from '@mui/material/Chip';
import { UserContext } from '@contexts';

import { TableRowDetails } from './table-row.details.component';

const renderWaterMeasurementValue = (measurement: number | null, hasMeasurement: boolean) => {
  if (hasMeasurement && measurement === undefined) return 'Brak odczytów';
  if (!hasMeasurement && measurement === undefined) return '-';
  if (measurement === 0) return `0 m³`;
  if (hasMeasurement && measurement !== undefined) return `${measurement} m³`;
};

const renderMeasurementEnergyValue = (measurement: number | null, hasMeasurement: boolean) => {
  if (hasMeasurement && measurement === undefined) return 'Brak odczytów';
  if (!hasMeasurement && measurement === undefined) return '-';
  if (measurement === 0) return `0 GJ`;
  if (hasMeasurement && measurement !== undefined) return `${measurement} GJ`;
};

export const Row = ({ row }: { row: LocationResult }) => {
  const [open, setOpen] = useState(false);
  const { userState } = useContext(UserContext);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Typography variant="body2" align="center">
            {row.address}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" align="center">
            {renderWaterMeasurementValue(row.coldWaterMeasurementSum?.value, row.hasColdWaterMeters)}
          </Typography>
          {userState.roles === 'Administrator' && (
            <Typography variant="body2" align="center">
              {row.coldWaterMeasurementSum?.lastMeasurementDate &&
                `${moment
                  .utc(row.coldWaterMeasurementSum?.lastMeasurementDate)
                  .local()
                  .format('lll')}`}
            </Typography>
          )}
        </TableCell>
        <TableCell>
          <Typography variant="body2" align="center">
            {renderWaterMeasurementValue(row.hotWaterMeasurementSum?.value, row.hasHotWaterMeters)}
          </Typography>
          {userState.roles === 'Administrator' && (
            <Typography variant="body2" align="center">
              {row.hotWaterMeasurementSum?.lastMeasurementDate &&
                `${moment
                  .utc(row.hotWaterMeasurementSum?.lastMeasurementDate)
                  .local()
                  .format('lll')}`}
            </Typography>
          )}
        </TableCell>
        <TableCell>
          <Typography variant="body2" align="center">
            {renderMeasurementEnergyValue(row.heatMeasurementSum?.energyValue, row.hasHeatMeters)}
          </Typography>
          {userState.roles === 'Administrator' && (
            <Typography variant="body2" align="center">
              {row.heatMeasurementSum?.lastMeasurementDate &&
                `${moment
                  .utc(row.heatMeasurementSum?.lastMeasurementDate)
                  .local()
                  .format('lll')}`}
            </Typography>
          )}
        </TableCell>
        <TableCell>
          <Typography variant="body2" align="center">
            {row.alertAppeared ? (
              <Chip
                label="Alarm"
                style={{
                  backgroundColor: '#FFE7D9',
                  borderColor: '#F03738',
                  border: 'solid',
                  borderWidth: '1px',
                  color: '#B72136',
                }}
              />
            ) : (
              <Chip
                label="Brak"
                style={{
                  backgroundColor: '#E9FCD4',
                  borderColor: '#AAF27F',
                  border: 'solid',
                  borderWidth: '1px',
                  color: '#229A16',
                }}
              />
            )}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <TableRowDetails locationId={row.locationId} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
