import { useQuery } from 'react-query';
import { getStoredCurrentCustomerData } from '@helpers';

import { queryKey } from './query-key.enum';
import { getCities } from '../infrastructure/get-cities';

export const useGetCities = () => {
  const storedCustomer = getStoredCurrentCustomerData();

  return useQuery({
    queryKey: [queryKey.CITIES(), storedCustomer?.id],
    queryFn: () => getCities(),
    enabled: !!storedCustomer?.id,
  });
};
