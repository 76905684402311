import { UseMutationResult, useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

import {
  IPatchExpirationRequest,
  updateHeatMeterExpirationDate,
} from '../infrastructure/update-heat-meter-expiration-date';

export const useUpdateHeatMeterExpirationDateCommand = (): UseMutationResult<
  AxiosResponse,
  unknown,
  IPatchExpirationRequest
> => {
  return useMutation<AxiosResponse, unknown, IPatchExpirationRequest>({
    mutationFn: updateHeatMeterExpirationDate,
  });
};
