import { Accordion, AccordionDetails, AccordionSummary, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BuildingAccordion } from './building-accordion.component';
import { useGetBuildingsQuery } from '@api/locations';
import React, { useContext, useState } from 'react';
import { ModalActionType, ModalContext } from '@contexts';
import { SkeletonLoader } from '@components';

import { AddStreetUpsertForm } from './forms/add-street.form';
import { AddBuildingUpsertForm } from './forms/add-building.form';
import { ManageButtons } from './manage-buttons';

interface StreetsAccordionProps {
  name: string;
  id: string;
  cityId: string;
}

export const StreetAccordion = ({ name, id, cityId }: StreetsAccordionProps) => {
  const { data, isLoading, refetch, isStale } = useGetBuildingsQuery({ streetId: id });
  const [expand, setExpand] = useState(false);
  const theme = useTheme();
  const { modalDispatch } = useContext(ModalContext);

  const toggleAcordion = () => {
    setExpand((prev) => {
      if (prev === false && isStale) {
        refetch();
      }
      return !prev;
    });
  };

  const openEditStreetModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    modalDispatch({
      type: ModalActionType.SHOW,
      payload: {
        content: <AddStreetUpsertForm street={{ id, name, cityId }} />,
      },
    });
  };

  const openAddBuildingModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    modalDispatch({
      type: ModalActionType.SHOW,
      payload: {
        content: <AddBuildingUpsertForm building={{ id: '', name: '', streetId: id, streetName: name }} />,
      },
    });
  };

  return (
    <Accordion
      expanded={expand}
      TransitionProps={{ unmountOnExit: true }}
      sx={{ backgroundColor: theme.palette.grey[200] }}
    >
      <AccordionSummary
        onClick={toggleAcordion}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <ManageButtons name={name} onAdd={openAddBuildingModal} onEdit={openEditStreetModal} />
      </AccordionSummary>
      <AccordionDetails>
        {isLoading ? (
          <SkeletonLoader loadersCount={1} />
        ) : (
          data?.data.map((building) => <BuildingAccordion name={building.name} id={building.id} streetId={id} />)
        )}
      </AccordionDetails>
    </Accordion>
  );
};
