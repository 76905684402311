import { makeStyles } from 'tss-react/mui';
import { Box, Paper, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';

import { AddUserForm } from '../Users/AddUser/AddUser.form';

export const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    margin: '50px 10px',
  },
}));

export const AddClient = () => {
  const { classes } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={classes.container}>
      <Paper
        elevation={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '20px',
          padding: '70px 40px',
        }}
      >
        <Typography variant="h4" textAlign="center">
          Dodaj administratora organizacji
        </Typography>
        <Box sx={{ width: isMobile ? '220px' : '500px' }}>
          <AddUserForm variant="client" />
        </Box>
      </Paper>
    </div>
  );
};
