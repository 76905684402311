enum QUERY_KEY {
  GET_CITIES = 'cities',
  GET_STREETS = 'streets',
  GET_BUILDINGS = 'buildings',
  GET_FLATS = 'flats',
}

export const queryKey = {
  CITIES: () => [QUERY_KEY.GET_CITIES],
  STREETS_BY_CITY: (cityId: string) => [QUERY_KEY.GET_STREETS, cityId],
  BUILDINGS_BY_STREET: (streetId: string) => [QUERY_KEY.GET_BUILDINGS, streetId],
  FLATS_BY_BUILDING: (buildingId: string) => [QUERY_KEY.GET_FLATS, buildingId],
};
