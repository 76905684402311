import { Box, styled, Tab } from '@mui/material';
import { Row } from '@components';

const TabsWrapper = styled(Row)(({ theme }) => ({
  justifyContent: 'flex-start',
  width: '100%',
  marginBottom: 30,
  paddingTop: 10,
  [theme.breakpoints.down('md')]: {
    margin: 0,
  },
  [theme.breakpoints.down('sm')]: {
    margin: 0,
  },
  [theme.breakpoints.down('xs')]: {
    margin: 0,
    maxWidth: '100vw',
    minWidth: '300px',
  },
}));

const IconWrapper = styled(Box)(() => ({
  pl: 3,
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.common.black,
  '&.Mui-selected': {
    color: theme.palette.common.black,
  },
  '&.Mui-focusVisible': {
    color: theme.palette.common.black,
  },
}));

export { TabsWrapper, IconWrapper, StyledTab };
