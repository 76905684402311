import { UseMutationResult, useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

import { addNewStreet, IPostStreetRequest } from '../infrastructure/post-street';

export const useAddStreet = (): UseMutationResult<AxiosResponse, unknown, IPostStreetRequest> => {
  return useMutation<AxiosResponse, unknown, IPostStreetRequest>({
    mutationFn: addNewStreet,
  });
};
