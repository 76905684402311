import { Fade, Dialog, Box, useTheme, useMediaQuery, Drawer } from '@mui/material';
import { useContext } from 'react';
import { ModalActionType, ModalContext } from 'src/contexts/ModalContext';
import CloseIcon from '@mui/icons-material/Close';
import { Snackbar } from '@components';

const Modal = () => {
  const {
    modalState: { visible, content },
    modalDispatch,
  } = useContext(ModalContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return isMobile ? (
    <Drawer anchor={'bottom'} open={visible}>
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: 2,
          }}
        >
          <div
            onClick={() =>
              modalDispatch({
                type: ModalActionType.DISMISS,
              })
            }
          >
            <CloseIcon />
          </div>
        </Box>
        <Box>{content}</Box>
      </Box>
      <Snackbar />
    </Drawer>
  ) : (
    <Dialog
      onClose={() =>
        modalDispatch({
          type: ModalActionType.DISMISS,
        })
      }
      open={visible}
      fullWidth={true}
      maxWidth={'md'}
      PaperProps={{ sx: { width: '580px' } }}
    >
      <Fade in={visible}>
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: 2,
            }}
          >
            <CloseIcon
              onClick={() =>
                modalDispatch({
                  type: ModalActionType.DISMISS,
                })
              }
            />
          </Box>
          <Box>{content}</Box>
        </Box>
      </Fade>
    </Dialog>
  );
};

export default Modal;
