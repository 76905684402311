import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';

export interface IOnboardingPasswordRequest {
  token: string;
  password: string;
  confirmPassword: string;
}

export const onboardUserPassword = ({
  token,
  confirmPassword,
  password,
}: IOnboardingPasswordRequest): Promise<AxiosResponse> => {
  return httpsService.post(Endpoint.ONBOARD_USER_PASSWORD, { token, confirmPassword, password });
};
