import { useQuery } from 'react-query';
import { SnackbarActionType, SnackbarContext, SnackbarType, UserContext } from '@contexts';
import { useContext } from 'react';
import { logout } from '@api';
import { useNavigate } from 'react-router-dom';

import { getCustomers } from '../infrastructure/get-customers';

export const useGetCustomers = () => {
  const { userState, userDispatch } = useContext(UserContext);
  const { snackbarDispatch } = useContext(SnackbarContext);
  const navigate = useNavigate();

  return useQuery({
    queryFn: () => getCustomers(),
    onError: () => {
      logout({ userDispatch, snackbarDispatch, navigate });
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: 'Brak organizacji',
          type: SnackbarType.ERROR,
        },
      });
    },
    queryKey: ['customers', userState.id],
    keepPreviousData: false,
    cacheTime: 0,
    staleTime: 0,
    enabled: userState.isAutheticated && userState.roles === 'Administrator',
  });
};
