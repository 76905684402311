import { IHookBaseList } from '@components';
import { useMemo } from 'react';
import { ILocationDashboardParams, LocationResult, useGetLocationsDashboard } from '@api';

export type TLocationDashboardList = IHookBaseList<LocationResult>;

export const useLocationSummary = ({ limit, offset, params }: ILocationDashboardParams): TLocationDashboardList => {
  const { data, isLoading, isError } = useGetLocationsDashboard({ limit, offset, params });

  return useMemo(
    () => ({
      data:
        data?.data.results.map((elem) => {
          return {
            id: elem.locationId,
            ...elem,
          };
        }) || [],
      isLoading,
      isError,
      pagination: {
        limit: data?.data.limit,
        offset: data?.data.offset,
        totalSize: data?.data.totalSize,
      },
    }),
    [data?.data.totalSize, data?.data.limit, data?.data.offset, isLoading, isError, data?.data.results]
  );
};
