import { getStoredCurrentCustomerData } from '@helpers';
import { useContext, useEffect } from 'react';
import { UserActionType, UserContext } from '@contexts';

import { useGetCustomers } from './queries/use-get-customers.query';

export const useCurrentCustomer = () => {
  const { data: customers, isSuccess } = useGetCustomers();
  const { userDispatch, userState } = useContext(UserContext);

  useEffect(() => {
    const storedCustomer = getStoredCurrentCustomerData();

    if (
      storedCustomer &&
      isSuccess &&
      customers &&
      customers.data.find((customer) => customer.id === storedCustomer.id)
    ) {
      userDispatch({
        type: UserActionType.SET_CUSTOMER,
        payload: {
          currentCustomer: storedCustomer,
        },
      });
      return;
    } else if (isSuccess && customers) {
      userDispatch({
        type: UserActionType.SET_CUSTOMER,
        payload: {
          currentCustomer: customers.data[0],
        },
      });
      return;
    }

    if (!storedCustomer) {
      if (isSuccess && customers) {
        userDispatch({
          type: UserActionType.SET_CUSTOMER,
          payload: {
            currentCustomer: customers.data[0],
          },
        });
        return;
      } else {
        userDispatch({
          type: UserActionType.SET_CUSTOMER,
          payload: {
            currentCustomer: undefined,
          },
        });
        return;
      }
    }
  }, [userDispatch, customers, isSuccess, userState.roles]);
};
