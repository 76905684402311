import { Box } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { iconSet } from '@assets';
import { Icon } from '@components';
import { DeviceAlertsProps } from '@api/types';
import moment from 'moment';
import 'moment/locale/pl';

const AlertGridColumns: GridColDef[] = [
  {
    field: 'date-day',
    headerName: 'Data',
    headerAlign: 'center',
    align: 'center',
    valueGetter: (params) => String(moment.utc(params.row.date).local().format('l')),
    width: 150,
    sortable: false,
  },
  {
    field: 'date-hours',
    headerName: 'Godzina',
    headerAlign: 'center',
    align: 'center',
    valueGetter: (params) => String(moment.utc(params.row.date).local().format('LTS')),
    width: 150,
    sortable: false,
  },
  {
    field: 'isActive',
    headerName: 'Aktywny',
    headerAlign: 'center',
    align: 'center',
    renderCell: (params: GridRenderCellParams<DeviceAlertsProps>) => (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          width: 150,
        }}
      >
        {params.row.isActive ? <Icon iconKey={iconSet.redAlert} /> : <Icon iconKey={iconSet.greyAlert} />}
      </Box>
    ),
    width: 150,
    sortable: false,
  },
  {
    field: 'errors',
    headerName: 'Treść błędu',
    headerAlign: 'left',
    align: 'left',
    valueGetter: (params) =>
      `${params.row.errors[0] ? `${params.row.errors[0]}` : ''}
       ${params.row.errors[1] ? ` | ${params.row.errors[1]}` : ''} 
       ${params.row.errors[2] ? ` | ${params.row.errors[2]}` : ''} 
       ${params.row.errors[3] ? ` | ${params.row.errors[3]}` : ''} 
       ${params.row.errors[4] ? ` | ${params.row.errors[4]}` : ''}`,
    flex: 1,
    sortable: false,
  },
];

export { AlertGridColumns };
