import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';

export interface IPostBuildingRequest {
  name: string;
  streetId: string;
}

export const addNewBuilding = ({ name, streetId }: IPostBuildingRequest): Promise<AxiosResponse> => {
  return httpsService.post(Endpoint.BUILDINGS_COMMON, { name, streetId });
};
