import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';

import { Pagination } from '../../types';

export interface IClientResponse {
  id: string;
  email: string;
  name: string;
  surname: string;
  roles: string[];
  isActive: boolean;
}

export const getClients = (): Promise<AxiosResponse<Pagination<IClientResponse[]>>> => {
  return httpsService.get(Endpoint.GET_CLIENTS.replace(':limit', '1000'));
};
