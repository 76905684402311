import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';

interface ICitiesResponse {
  name: string;
  id: string;
}

export const getCities = (): Promise<AxiosResponse<ICitiesResponse[]>> => {
  return httpsService.get(Endpoint.CITIES);
};
