import { Box, styled } from '@mui/material';

const CustomerSwitcherContainer = styled(Box)((theme) => ({
  maxWidth: 260,
  paddingRight: 16,
  paddingLeft: 16,
  height: "100%",
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  columnGap: 12,
  cursor: 'pointer',
  overflow: 'hidden',
}));

export { CustomerSwitcherContainer };
