import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';

import { Pagination } from '../../types';

export interface ILocatorResponse {
  id: string;
  name: string;
  surname: string;
  email: string;
  roles: string[];
  isActive: boolean;
}

export interface ILocatorParams {
  limit: number;
  offset: number;
  username?: string;
}

export const getLocators = ({
  limit,
  offset,
  username,
}: ILocatorParams): Promise<AxiosResponse<Pagination<ILocatorResponse[]>>> => {
  return httpsService.get(
    Endpoint.LOCATORS.replace(':limit', limit.toString())
      .replace(':offset', offset.toString())
      .replace(':username', username || '')
  );
};
