import { ICustomer } from '@api/types';
import { DataGridContainer, StyledDatGrid } from './DataGrid.styles';
import { CustomNoRowsOverlay, SkeletonLoader } from '@components';

import { CustomerListColumns } from './CustomerListColumns';

interface DataGridProps {
  customers: ICustomer[];
  isCustomerListLoading: boolean;
}

export const GridLoader = () => {
  return <SkeletonLoader loadersCount={6} />;
};

const DataGrid: React.FC<DataGridProps> = ({ customers, isCustomerListLoading }) => {
  return (
    <DataGridContainer>
      <StyledDatGrid
        rows={customers}
        hideFooterSelectedRowCount={true}
        hideFooterPagination={true}
        headerHeight={50}
        rowBuffer={0}
        rowHeight={100}
        columnBuffer={0}
        columns={CustomerListColumns()}
        showColumnRightBorder={false}
        autoHeight={true}
        disableColumnMenu={true}
        disableColumnFilter={true}
        checkboxSelection={false}
        disableSelectionOnClick
        loading={isCustomerListLoading}
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
          LoadingOverlay: GridLoader,
        }}
      />
    </DataGridContainer>
  );
};

export default DataGrid;
