import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';

interface IGetCitiesRequest {
  cityId: string;
}

interface IStreetResponse {
  name: string;
  id: string;
}

export const getStreets = ({ cityId }: IGetCitiesRequest): Promise<AxiosResponse<IStreetResponse[]>> => {
  return httpsService.get(Endpoint.STREETS.replace(':cityId', cityId));
};
