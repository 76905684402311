import { Box, Typography } from '@mui/material';
import { ItemInfoContainer } from './ItemInfo.styles';
import { DeviceMeasurements } from '../../../../types';
import moment from 'moment';
import 'moment/locale/pl';

interface ItemInfoProps {
  measurement: DeviceMeasurements;
  deviceType: string;
}

const ItemInfo = ({ measurement, deviceType }: ItemInfoProps) => {
  return (
    <Box>
      <ItemInfoContainer>
        <Box>
          <Typography variant="body2">Godzina</Typography>
        </Box>
        <Box>
          <Typography align={'right'} variant="body1">
            {moment.utc(measurement.date).local().format('LTS')}
          </Typography>
        </Box>
      </ItemInfoContainer>

      <ItemInfoContainer>
        <Box>
          <Typography variant="body2">Stan licznika</Typography>
        </Box>
        <Box>
          {deviceType === 'wodomierz' ? (
            <Typography>{measurement.value} m³</Typography>
          ) : (
            <Typography> {measurement.energyValue} GJ</Typography>
          )}
        </Box>
      </ItemInfoContainer>
    </Box>
  );
};

export default ItemInfo;
