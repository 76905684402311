import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';

export interface IAssignLocationToLocatorProps {
  locatorId: string;
  locationId: string;
}

export const assignLocationToLocator = ({
  locatorId,
  locationId,
}: IAssignLocationToLocatorProps): Promise<AxiosResponse> => {
  return httpsService.patch(
    Endpoint.ASSIGN_LOCATOR.replace(':locatorId', locatorId).replace(':locationId', locationId)
  );
};
