import {
  DeviceContext,
  ModalActionType,
  ModalContext,
  SnackbarActionType,
  SnackbarContext,
  SnackbarType,
} from '@contexts';
import { Box, Typography, useTheme, Button, useMediaQuery } from '@mui/material';
import { ChangeEvent, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Input } from '@components';
import { useChangeHeatMeterNetwork, useChangeWaterMeterNetwork } from '@api';
import getDeviceDetails from 'src/api/getDeviceDetails';

interface IAddEuiDialog {
  meterId: string;
  newNetwork: 'TTN' | 'Emitel';
  deviceType: string;
  setDeviceDetailsLoading: Dispatch<SetStateAction<boolean>>;
  retrieveJoinEui: boolean;
  deviceJoinEui?: string | null;
}

export const AddEuiDialog = ({
  meterId,
  newNetwork,
  deviceType,
  setDeviceDetailsLoading,
  retrieveJoinEui,
  deviceJoinEui,
}: IAddEuiDialog) => {
  const theme = useTheme();
  const { snackbarDispatch } = useContext(SnackbarContext);
  const { modalDispatch } = useContext(ModalContext);
  const { deviceDispatch } = useContext(DeviceContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [joinEui, setJoinEui] = useState<string>('');
  const { mutateAsync: changeWaterMeterNetwork } = useChangeWaterMeterNetwork();
  const { mutateAsync: changeHeatMeterNetwork } = useChangeHeatMeterNetwork();

  const onConfirm = async () => {
    try {
      if (deviceType === 'wodomierz') {
        await changeWaterMeterNetwork({
          meterId,
          newNetwork,
          joinEui,
        });
      } else {
        await changeHeatMeterNetwork({
          meterId,
          newNetwork,
          joinEui,
        });
      }
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: 'Zmieniono sieć na ' + newNetwork,
          type: SnackbarType.SUCCESS,
        },
      });
    } catch (error) {
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: 'Błąd zmiany sieci',
          type: SnackbarType.ERROR,
        },
      });
    }

    await getDeviceDetails({
      deviceDispatch,
      deviceId: meterId,
      setDeviceDetailsLoading,
      type: deviceType === 'wodomierz' ? 'water' : 'heater',
    });

    modalDispatch({
      type: ModalActionType.DISMISS,
    });
  };

  const onDecline = () => {
    modalDispatch({
      type: ModalActionType.DISMISS,
    });
  };

  useEffect(() => {
    setJoinEui(deviceJoinEui ? deviceJoinEui : '');
  }, [deviceJoinEui]);

  if (!retrieveJoinEui) {
    return (
      <Box sx={{ padding: '20px' }}>
        <Typography variant="body1" textAlign={'center'}>
          Potwierdź zmianę sieci na {newNetwork}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'center',
            columnGap: '10px',
            rowGap: '10px',
            padding: '20px',
          }}
        >
          <Button
            variant="contained"
            sx={{
              height: '48px',
              borderRadius: '8px',
              backgroundColor: theme.palette.success.dark,
              '&:hover': {
                backgroundColor: theme.palette.success.main,
              },
            }}
            onClick={onConfirm}
          >
            <Typography variant="button" color="white">
              Zatwierdź
            </Typography>
          </Button>
          <Button
            variant="contained"
            sx={{
              height: '48px',
              borderRadius: '8px',
              backgroundColor: theme.palette.error.dark,
              '&:hover': {
                backgroundColor: theme.palette.error.main,
              },
            }}
            onClick={onDecline}
          >
            <Typography variant="button" color="white">
              Rezygnuje
            </Typography>
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: '20px' }}>
      <Typography variant="body1" textAlign={'center'}>
        Uzupełnij JoinEUI przed zmianą sieci na TTN
      </Typography>
      <Input
        label={'JoinEUI'}
        value={joinEui}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setJoinEui(event.target.value);
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'center',
          columnGap: '10px',
          rowGap: '10px',
          padding: '20px',
        }}
      >
        <Button
          variant="contained"
          sx={{
            height: '48px',
            borderRadius: '8px',
            backgroundColor: theme.palette.success.dark,
            '&:hover': {
              backgroundColor: theme.palette.success.main,
            },
          }}
          disabled={joinEui.length < 8 || joinEui.length > 64}
          onClick={onConfirm}
        >
          <Typography variant="button" color="white">
            Zatwierdź
          </Typography>
        </Button>
        <Button
          variant="contained"
          sx={{
            height: '48px',
            borderRadius: '8px',
            backgroundColor: theme.palette.error.dark,
            '&:hover': {
              backgroundColor: theme.palette.error.main,
            },
          }}
          onClick={onDecline}
        >
          <Typography variant="button" color="white">
            Rezygnuje
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};
