import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';

import { Pagination } from '../../types';

interface IFilters {
  cityId?: string;
  streetId?: string;
  buildingId?: string;
  flatId?: string;
}

export interface ILocationsAssignmentParams {
  limit: number;
  offset: number;
  params: IFilters;
}

export interface ILocationsAssignmentResponse {
  address: string;
  locationId: string;
  locators: {
    locatorId: string;
    locatorName: string;
  }[];
}

export const getLocationsForAssignment = (
  payload: ILocationsAssignmentParams
): Promise<AxiosResponse<Pagination<ILocationsAssignmentResponse[]>>> => {
  return httpsService.get(
    Endpoint.LOCATIONS_ASSIGNEMENT.replace(':limit', payload.limit.toString())
      .replace(':offset', payload.offset.toString())
      .replace(':cityId', payload.params.cityId || '')
      .replace(':streetId', payload.params.streetId || '')
      .replace(':buildingId', payload.params.buildingId || '')
      .replace(':flatId', payload.params.flatId || '')
  );
};
