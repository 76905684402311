import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';

interface IGetBuildingsRequest {
  streetId: string;
}

interface IBuildingResponse {
  name: string;
  id: string;
}

export const getBuildings = ({ streetId }: IGetBuildingsRequest): Promise<AxiosResponse<IBuildingResponse[]>> => {
  return httpsService.get(Endpoint.BUILDINGS.replace(':streetId', streetId));
};
