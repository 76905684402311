import { useCallback, useContext, useState } from 'react';
import { Button } from '@components';
import ShareIcon from '@mui/icons-material/Share';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { DeviceContext } from '@contexts';

import { ButtonsContainer, ButtonWrapper } from './Buttons.styles';
import { useReports } from './use-reports.hook';

interface ButtonsProp {
  deviceId: string;
}

const Buttons = ({ deviceId }: ButtonsProp) => {
  const [isCopied, setIsCopied] = useState(false);
  const { device } = useContext(DeviceContext);
  const { getWaterMetersColdReport, getWaterMetersReportHot, getHeatMetersReport } = useReports({ deviceId });

  const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  };

  const handleCopyClick = () => {
    copyTextToClipboard(window.location.href)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 500);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleGenerateReport = useCallback(() => {
    if (device.type === 'ciepłomierz') {
      getHeatMetersReport();
      return;
    }
    if (device.parameters?.type === 'Zimna woda') {
      getWaterMetersColdReport();
    }
    if (device.parameters?.type === 'Ciepła woda') {
      getWaterMetersReportHot();
    }
  }, [getHeatMetersReport, device?.type, getWaterMetersColdReport, getWaterMetersReportHot, device.parameters?.type]);

  return (
    <ButtonsContainer>
      <ButtonWrapper>
        <Button disabled={isCopied} onClick={handleCopyClick} startIcon={<ShareIcon />}>
          Udostępnij
        </Button>
      </ButtonWrapper>
      <ButtonWrapper>
        <Button onClick={handleGenerateReport} startIcon={<FileDownloadOutlinedIcon />}>
          Generuj raport
        </Button>
      </ButtonWrapper>
    </ButtonsContainer>
  );
};

export default Buttons;
