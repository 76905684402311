import * as yup from 'yup';
import React, { useContext } from 'react';
import { SnackbarContext } from '@contexts';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { WodmarLogo } from '../Login/components';
import { Input } from '@components';
import { useNavigate } from 'react-router-dom';
import { PATH } from '@router';
import resetPasswordInit from 'src/api/resetPasswordInit';

interface IFormInput {
  email: string;
}

const resetPasswordEmailSchema = yup.object().shape({
  email: yup.string().email('Niepoprawny e-mail').max(255).required('Pole obowiązkowe'),
});

interface ResetPasswordProps {}

export const ResetPassword: React.FC<ResetPasswordProps> = () => {
  const { snackbarDispatch } = useContext(SnackbarContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm<IFormInput>({
    mode: 'onChange',
    resolver: yupResolver(resetPasswordEmailSchema),
    defaultValues: {
      email: '',
    },
  });

  const onSubmit: SubmitHandler<IFormInput> = ({ email }) => {
    if (isValid) {
      resetPasswordInit({ snackbarDispatch, email });
    }
    reset();
  };

  const backToLoginPage = () => {
    navigate(PATH.LOGIN);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          rowGap: '20px',
          padding: '10px',
          height: '100vh',
        }}
      >
        <Box>
          <WodmarLogo />
          <Typography align="center" variant="body1">
            Przypomnij hasło
          </Typography>
        </Box>

        <Box sx={{ width: '290px' }}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                error={!!errors.email}
                helperText={errors.email && errors.email?.message && errors.email.message}
                label="Email"
                autoComplete="email"
                ref={null}
              />
            )}
          />
        </Box>
        <Box sx={{ width: '290px' }}>
          <Button
            variant="contained"
            sx={{
              width: '100%',
              height: '48px',
              backgroundColor: theme.palette.primary.dark,
              borderRadius: '8px',
            }}
            disabled={!isDirty}
            type="submit"
          >
            <Typography variant="body2">Przeslij link do zmiany hasła</Typography>
          </Button>
        </Box>

        <Box sx={{ width: '290px' }}>
          <Button
            variant="contained"
            sx={{
              width: '100%',
              height: '48px',
              backgroundColor: theme.palette.primary.dark,
              borderRadius: '8px',
            }}
            onClick={backToLoginPage}
          >
            <Typography variant="body2">Wróc do logowania</Typography>
          </Button>
        </Box>
      </Box>
    </form>
  );
};
