import React, { useContext } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Box, Button, Typography } from '@mui/material';
import { Input } from '@components';
import { SnackbarContext } from '@contexts';
import { addNewCustomer } from '@api';

interface IFormInput {
  name: string;
}

const addCustomerSchema = yup.object().shape({
  name: yup
    .string()
    .min(3, 'Nazwa organizacji musi zawierać conajmniej 3 znaki')
    .max(80, 'Nazwa organizacji nie może mieć więcej niż 80 znaków')
    .required('Pole obowiązkowe'),
});

export const AddCustomerForm: React.FC = () => {
  const { snackbarDispatch } = useContext(SnackbarContext);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm<IFormInput>({
    mode: 'onBlur',
    resolver: yupResolver(addCustomerSchema),
    defaultValues: {
      name: '',
    },
  });

  const onSubmit: SubmitHandler<IFormInput> = ({ name }) => {
    if (isValid) {
      addNewCustomer({
        snackbarDispatch,
        name,
      });
    }
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="false">
      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '20px' }}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              error={!!errors.name}
              helperText={errors.name && errors.name?.message && errors.name.message}
              label="Nazwa organizacji"
              autoComplete={'off'}
              ref={null}
            />
          )}
        />
        <Button
          variant="contained"
          sx={{
            borderRadius: '8px',
          }}
          disabled={!isDirty}
          type="submit"
        >
          <Typography variant="body1">Dodaj organizacje</Typography>
        </Button>
      </Box>
    </form>
  );
};
