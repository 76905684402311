import { MenuItem, styled, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const StyledMenuOption = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  '&:hover': {
    borderRadius: '6px',
    backgroundColor: 'rgba(2, 84, 145, 0.1)',
  },
}));

export const useStyles = makeStyles()((theme: Theme) => ({
  containerLogo: {
    display: 'flex',
    justifyContent: 'center',
  },
}));
