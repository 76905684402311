import { saveAs } from 'file-saver';
import { useContext } from 'react';
import { SnackbarActionType, SnackbarContext, SnackbarType } from '@contexts';

import { getGeneralReport, IReportProps } from '../infrastructure/get-reports';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

export const useGeneralReport = () => {
  const { snackbarDispatch } = useContext(SnackbarContext);

  return useMutation<AxiosResponse, unknown, IReportProps>({
    mutationFn: getGeneralReport,
    onError: () => {
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: 'Nie można wygenerować raportu',
          type: SnackbarType.ERROR,
        },
      });
    },
    onSuccess: (data) => {
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: 'Rozpoczęto pobieranie raportu',
          type: SnackbarType.SUCCESS,
        },
      });
      const blob = new Blob([data?.data], {
        type: 'application/octet-stream',
      });
      const filename = `raporty-${new Date().toISOString()}.zip`;
      saveAs(blob, filename);
    },
  });
};
