import { Box, Divider, ListItem, ListItemText, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { ListContainer } from './InfoList.styles';
import { InfoEditItem } from './info-edit-item';

export interface InfoListItem {
  propertyName: string;
  propertyValue: string | ReactNode;
  isEditable?: boolean;
}

interface InfoListProp {
  item: InfoListItem[];
}

const InfoList = ({ item }: InfoListProp) => {
  return (
    <ListContainer>
      {item.map((info) => {
        return (
          <Box key={info.propertyName}>
            {info.isEditable ? (
              <InfoEditItem propertyName={info.propertyName} propertyValue={info.propertyValue} />
            ) : (
              <ListItem>
                <ListItemText sx={{ minWidth: 220, paddingRight: 5 }}>
                  <Typography align="left" variant="body1">
                    {info.propertyName}
                  </Typography>
                </ListItemText>
                <ListItemText sx={{ paddingRight: 5 }}>
                  {info.propertyName === 'Alert' ? (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      {info.propertyValue}
                    </Box>
                  ) : (
                    <Typography sx={{ wordBreak: 'break-word' }} align="right" variant="subtitle1">
                      {info.propertyValue}
                    </Typography>
                  )}
                </ListItemText>
              </ListItem>
            )}
            <Divider />
          </Box>
        );
      })}
    </ListContainer>
  );
};

export default InfoList;
