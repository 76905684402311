import { Box, Button, Typography, useTheme } from '@mui/material';
import React from 'react';

interface IConfirmLocatorEmailChangeProps {
  onAccept: () => void;
  onCancel: () => void;
}

export const ConfirmLocatorEmailChangeModal = ({ onAccept, onCancel }: IConfirmLocatorEmailChangeProps) => {
  const theme = useTheme();
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, padding: 4 }}>
      <Typography variant="h5" textAlign="center">
        Lokator otrzyma e-mail z inforamcją o zmianie adresu e-mail. Czy na pewno chcesz kontynuować?
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Button
          variant="contained"
          sx={{
            height: '48px',
            borderRadius: '8px',
            alignSelf: 'flex-end',
          }}
          onClick={onAccept}
        >
          <Typography variant="button" color="white">
            Zatwierdź
          </Typography>
        </Button>
        <Button
          variant="contained"
          sx={{
            height: '48px',
            borderRadius: '8px',
            alignSelf: 'flex-end',
            backgroundColor: theme.palette.error.main,
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: theme.palette.grey[800],
            },
            ':hover': {
              backgroundColor: theme.palette.error.dark,
              color: 'white',
            },
          }}
          onClick={onCancel}
        >
          <Typography variant="button" color="white">
            Anuluj
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};
