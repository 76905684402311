import { useCallback, useState } from 'react';
import { FilterBar } from '@components';

import { useStyles } from './month.styles';

type MeasurementType = 'Heat' | 'ColdWater' | 'HotWater';

interface IFilters {
  cityId?: string;
  streetId?: string;
  buildingId?: string;
  flatId?: string;
  onlyAlerts?: string;
  forDay?: number;
  measurementTypes?: MeasurementType[];
}

const defaultParams: IFilters = {
  cityId: '',
  streetId: '',
  buildingId: '',
  measurementTypes: [],
};

export const ReportMonthScreen = () => {
  const { classes } = useStyles();
  const [params, setParams] = useState<IFilters>(defaultParams);

  const handleParamsChange = useCallback(
    (newParams: IFilters) => {
      setParams(newParams);
    },
    [setParams]
  );

  return (
    <div className={classes.container}>
      <FilterBar
        onChangeParams={handleParamsChange}
        initialParams={defaultParams}
        isReportBar={true}
        isLocatorAssign={false}
        filters={params}
      />
    </div>
  );
};
