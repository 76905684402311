import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Input } from '@components';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Typography, useTheme, Box } from '@mui/material';
import React, { useContext } from 'react';
import * as yup from 'yup';
import { useUpdateStreetName, useGetStreetsQuery, useAddStreet } from '@api/locations';
import { ModalActionType, ModalContext, SnackbarActionType, SnackbarContext, SnackbarType } from '@contexts';

interface FormInputProps {
  name: string;
}

interface IAddStreetFormProps {
  street?: {
    name: string;
    id: string;
    cityId: string;
    cityName?: string;
  };
}

const newStreetyValidationSchema = yup.object().shape({
  name: yup.string().max(255).required('Pole obowiązkowe'),
});

export const AddStreetUpsertForm = ({ street }: IAddStreetFormProps) => {
  const theme = useTheme();
  const { snackbarDispatch } = useContext(SnackbarContext);
  const { modalDispatch } = useContext(ModalContext);
  const { refetch } = useGetStreetsQuery({ cityId: street?.cityId || '' });
  const { mutateAsync: updateStreetName } = useUpdateStreetName({
    cityId: street?.cityId,
  });
  const { mutateAsync: addNewStreet } = useAddStreet();

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm<FormInputProps>({
    resolver: yupResolver(newStreetyValidationSchema),
    defaultValues: {
      name: street?.name || '',
    },
  });

  const onUpdateSubmit: SubmitHandler<FormInputProps> = async ({ name }) => {
    if (!street) return;
    try {
      const res = await updateStreetName({ name, streetId: street?.id });
      if (res.status === 204) {
        refetch();
        snackbarDispatch({
          type: SnackbarActionType.SHOW,
          payload: {
            message: `Zaktualizowano nazwę ulicy ${name}`,
            type: SnackbarType.SUCCESS,
          },
        });
      }
    } catch (error) {
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: `Błąd aktualizowania ulicy ${name}`,
          type: SnackbarType.ERROR,
        },
      });
    }
    modalDispatch({ type: ModalActionType.DISMISS });
  };

  const onAddSubmit: SubmitHandler<FormInputProps> = async ({ name }) => {
    try {
      const res = await addNewStreet({ name, cityId: street?.cityId || '' });
      if (res.status === 204) {
        refetch();
        snackbarDispatch({
          type: SnackbarActionType.SHOW,
          payload: {
            message: `Dodano nową ulice ${name}`,
            type: SnackbarType.SUCCESS,
          },
        });
      }
    } catch (error) {
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: `Błąd dodawania ulicy ${name}`,
          type: SnackbarType.ERROR,
        },
      });
    }
    modalDispatch({ type: ModalActionType.DISMISS });
  };
  return (
    <form>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, padding: 4 }}>
        <Typography variant="h5" textAlign="center">
          {!!street?.name ? `Edytuj nazwę ulicy` : `Dodaj nową ulice do miasta ${street?.cityName} `}
        </Typography>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              error={!!errors.name}
              helperText={errors.name && errors.name?.message && errors.name.message}
              label="Nazwa ulicy"
              type="text"
              ref={null}
            />
          )}
        />
        <Button
          variant="contained"
          sx={{
            height: '48px',
            borderRadius: '8px',
            backgroundColor: theme.palette.primary.dark,
            alignSelf: 'flex-end',
          }}
          onClick={!!street?.name ? handleSubmit(onUpdateSubmit) : handleSubmit(onAddSubmit)}
          disabled={!isDirty}
        >
          <Typography variant="button" color="white">
            Zatwierdź
          </Typography>
        </Button>
      </Box>
    </form>
  );
};
