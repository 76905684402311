import { UseMutationResult, useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

import { addNewBuilding, IPostBuildingRequest } from '../infrastructure/post-building';

export const useAddBuilding = (): UseMutationResult<AxiosResponse, unknown, IPostBuildingRequest> => {
  return useMutation<AxiosResponse, unknown, IPostBuildingRequest>({
    mutationFn: addNewBuilding,
  });
};
