import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Input } from '@components';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Typography, useTheme, Box } from '@mui/material';
import React, { useContext } from 'react';
import * as yup from 'yup';
import { useUpdateFlatName, useGetFlatsQuery, useAddFlat } from '@api/locations';
import { ModalActionType, ModalContext, SnackbarActionType, SnackbarContext, SnackbarType } from '@contexts';

interface FormInputProps {
  name: string;
}

interface IAddFlatFormProps {
  flat?: {
    name: string;
    id: string;
    buildingId: string;
    buildingName?: string;
  };
}

const newFlatValidationSchema = yup.object().shape({
  name: yup.string().max(255).required('Pole obowiązkowe'),
});

export const AddFlatUpsertForm = ({ flat }: IAddFlatFormProps) => {
  const theme = useTheme();
  const { modalDispatch } = useContext(ModalContext);
  const { snackbarDispatch } = useContext(SnackbarContext);
  const { mutateAsync: updateFlat } = useUpdateFlatName();
  const { refetch } = useGetFlatsQuery({ buildingId: flat?.buildingId || '' });
  const { mutateAsync: addFlat } = useAddFlat();
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm<FormInputProps>({
    resolver: yupResolver(newFlatValidationSchema),
    defaultValues: {
      name: flat?.name || '',
    },
  });

  const onUpdateSubmit: SubmitHandler<FormInputProps> = async ({ name }) => {
    if (!flat) return;
    try {
      const res = await updateFlat({ name, flatId: flat?.id });
      if (res.status === 204) {
        refetch();
        snackbarDispatch({
          type: SnackbarActionType.SHOW,
          payload: {
            message: `Zaktualizowano nazwę lokalu ${name}`,
            type: SnackbarType.SUCCESS,
          },
        });
      }
    } catch (error) {
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: `Błąd aktualizowania lokalu ${name}`,
          type: SnackbarType.ERROR,
        },
      });
    }
    modalDispatch({ type: ModalActionType.DISMISS });
  };

  const onAddSubmit: SubmitHandler<FormInputProps> = async ({ name }) => {
    try {
      const res = await addFlat({ name, buildingId: flat?.buildingId || '' });
      if (res.status === 204) {
        refetch();
        snackbarDispatch({
          type: SnackbarActionType.SHOW,
          payload: {
            message: `Dodano nowy lokal ${name}`,
            type: SnackbarType.SUCCESS,
          },
        });
      }
    } catch (error) {
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: `Błąd dodawania lokalu ${name}`,
          type: SnackbarType.ERROR,
        },
      });
    }
    modalDispatch({ type: ModalActionType.DISMISS });
  };

  return (
    <form>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, padding: 4 }}>
        <Typography variant="h5" textAlign="center">
          {!!flat?.name ? `Edytuj nazwę lokalu` : `Dodaj nowy lokal do budynku ${flat?.buildingName}`}
        </Typography>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              error={!!errors.name}
              helperText={errors.name && errors.name?.message && errors.name.message}
              label="Nazwa lokoalu"
              type="text"
              ref={null}
            />
          )}
        />
        <Button
          variant="contained"
          sx={{
            height: '48px',
            borderRadius: '8px',
            backgroundColor: theme.palette.primary.dark,
            alignSelf: 'flex-end',
          }}
          onClick={!!flat?.name ? handleSubmit(onUpdateSubmit) : handleSubmit(onAddSubmit)}
          disabled={!isDirty}
        >
          <Typography variant="button" color="white">
            Zatwierdź
          </Typography>
        </Button>
      </Box>
    </form>
  );
};
