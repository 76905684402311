import { useGetHeatMetersReport, useGetWaterMetersColdReport, useGetWaterMetersHotReport } from '@api/reports';

interface IUseReports {
  deviceId: string;
}

export const useReports = ({ deviceId }: IUseReports) => {
  const { refetch: getHeatMetersReport } = useGetHeatMetersReport({
    meterId: deviceId,
  });
  const { refetch: getWaterMetersReportHot } = useGetWaterMetersHotReport({
    meterId: deviceId,
  });
  const { refetch: getWaterMetersColdReport } = useGetWaterMetersColdReport({
    meterId: deviceId,
  });

  return {
    getWaterMetersReportHot,
    getWaterMetersColdReport,
    getHeatMetersReport,
  };
};
