import { Box, styled, Avatar } from '@mui/material';

const ProfileContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignContent: 'center',
  margin: '50px 10px',
}));

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: '220px',
  height: '220px',
  alignSelf: 'center',
}));

export { ProfileContainer, ProfileAvatar };
