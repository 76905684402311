import { useContext } from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Typography, useTheme } from '@mui/material';
import { ICustomer, LocalStorageKey } from '@api/types';
import { UserActionType, UserContext } from '@contexts';
import { useNavigate } from 'react-router-dom';
import { PATH } from '@router';

import { CustomerItemContainer } from './customerSwitcherDetails.styles';

interface IOrganizationItemProps {
  isCurrentOrg: boolean;
  customer: ICustomer;
  onCustomerChange?: () => void;
}

export const OrganizationItem = ({ isCurrentOrg, customer }: IOrganizationItemProps) => {
  const theme = useTheme();
  const { userDispatch } = useContext(UserContext);
  const navigate = useNavigate();

  return (
    <CustomerItemContainer
      onClick={() => {
        userDispatch({
          type: UserActionType.SET_CUSTOMER,
          payload: {
            currentCustomer: customer,
          },
        });
        navigate(`/${PATH.DASHBOARD}/${PATH.PANEL}`);
        window.localStorage.removeItem(LocalStorageKey.FILTERS);
      }}
    >
      {customer.name && (
        <Typography textAlign="left" noWrap>
          {customer.name}
        </Typography>
      )}
      {isCurrentOrg && (
        <FiberManualRecordIcon
          sx={{
            color: theme.palette.primary.main,
            fontSize: 14,
          }}
        />
      )}
    </CustomerItemContainer>
  );
};
