import { Accordion, AccordionSummary, AccordionDetails, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useContext, useState } from 'react';
import { SkeletonLoader } from '@components';
import { useGetStreetsQuery } from '@api/locations';
import { ModalActionType, ModalContext } from '@contexts';

import { StreetAccordion } from './street-accordion.component';
import { AddCityUpsertForm } from './forms/add-city.form';
import { AddStreetUpsertForm } from './forms/add-street.form';
import { ManageButtons } from './manage-buttons';

interface CityAccordionProps {
  name: string;
  id: string;
}

export const CityAccordion = ({ name, id }: CityAccordionProps) => {
  const { data, refetch, isLoading, isStale } = useGetStreetsQuery({ cityId: id });
  const [expand, setExpand] = useState(false);
  const theme = useTheme();
  const { modalDispatch } = useContext(ModalContext);

  const toggleAcordion = () => {
    setExpand((prev) => {
      if (prev === false && isStale) {
        refetch();
      }
      return !prev;
    });
  };

  const openEditCityModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    modalDispatch({
      type: ModalActionType.SHOW,
      payload: {
        content: <AddCityUpsertForm city={{ id, name }} />,
      },
    });
  };
  const openAddStreetModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    modalDispatch({
      type: ModalActionType.SHOW,
      payload: {
        content: <AddStreetUpsertForm street={{ id: '', name: '', cityId: id, cityName: name }} />,
      },
    });
  };

  return (
    <Accordion
      expanded={expand}
      TransitionProps={{ unmountOnExit: true }}
      sx={{ backgroundColor: theme.palette.grey[100] }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={toggleAcordion} aria-controls="panel1a-content">
        <ManageButtons name={name} onAdd={openAddStreetModal} onEdit={openEditCityModal} />
      </AccordionSummary>
      <AccordionDetails>
        {isLoading ? (
          <SkeletonLoader loadersCount={1} />
        ) : (
          data?.data.map((street) => <StreetAccordion name={street.name} id={street.id} cityId={id} />)
        )}
      </AccordionDetails>
    </Accordion>
  );
};
