import { UseMutationResult, useMutation } from 'react-query';
import { AxiosResponse } from 'axios';
import { SnackbarActionType, SnackbarContext, SnackbarType } from '@contexts';
import { useContext } from 'react';

import { onboardUserInit, IOnboardingInitRequest } from '../infrastructure/onboard-user-init';
import HttpServiceError from '../../../services/httpService/types';

export const useOnboardInit = (): UseMutationResult<AxiosResponse, HttpServiceError, IOnboardingInitRequest> => {
  const { snackbarDispatch } = useContext(SnackbarContext);

  return useMutation<AxiosResponse, HttpServiceError, IOnboardingInitRequest>({
    mutationFn: onboardUserInit,
    onSuccess: () => {
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: 'Link aktywacyjny został wysłany ponownie',
          type: SnackbarType.SUCCESS,
        },
      });
    },
    onError: (error) => {
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: error?.response?.data.title || 'Wystąpił błąd',
          type: SnackbarType.ERROR,
        },
      });
    },
  });
};
