import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ModalActionType, ModalContext } from '@contexts';
import { useContext } from 'react';
import { useDeleteAssignedLocator } from '@api';

interface IConfirmAssignmentProps {
  locationId: string;
  locatorId: string;
  locationName: string;
  locatorName: string;
}

export const ConfirmDeleteAssignedLocator = ({
  locatorName,
  locationName,
  locatorId,
  locationId,
}: IConfirmAssignmentProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { modalDispatch } = useContext(ModalContext);
  const { mutateAsync: deleteAssignedLocator } = useDeleteAssignedLocator();

  const onConfirm = async () => {
    try {
      await deleteAssignedLocator({ locatorId, locationId });
    } catch (error) {
      console.error(error);
    }
    modalDispatch({
      type: ModalActionType.DISMISS,
    });
  };

  const onDecline = () => {
    modalDispatch({
      type: ModalActionType.DISMISS,
    });
  };

  return (
    <Box sx={{ padding: '20px' }}>
      <Typography variant="body1" textAlign={'center'}>
        Czy na pewno chcesz usunąć lokatora <b>{locatorName}</b> przypisanego obecnie lokalizacji <b>{locationName}</b>?
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'center',
          columnGap: '10px',
          rowGap: '20px',
          padding: '20px',
        }}
      >
        <Button
          variant="contained"
          sx={{
            height: '48px',
            borderRadius: '8px',
            backgroundColor: theme.palette.success.dark,
            '&:hover': {
              backgroundColor: theme.palette.success.main,
            },
          }}
          onClick={onConfirm}
        >
          <Typography variant="button" color="white">
            Potwierdzam
          </Typography>
        </Button>
        <Button
          variant="contained"
          sx={{
            height: '48px',
            borderRadius: '8px',
            backgroundColor: theme.palette.error.dark,
            '&:hover': {
              backgroundColor: theme.palette.error.main,
            },
          }}
          onClick={onDecline}
        >
          <Typography variant="button" color="white">
            Rezygnuje
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};
