import { Row } from '@components';
import { styled, Box } from '@mui/material';

const DeviceContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  paddingLeft: 50,
  [theme.breakpoints.down('md')]: {
    paddingLeft: 20,
  },
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    flexDirection: 'column',
    paddingLeft: 0,
  },
}));

const DeviceContainerHeader = styled(Row)(({ theme }) => ({
  justifyContent: 'space-between',
  paddingTop: 20,
  rowGap: 20,
  [theme.breakpoints.down('md')]: {
    justifyContent: 'flex-end',
    flexDirection: 'column',
  },
}));

const DeviceSignature = styled(Row)(({ theme }) => ({
  width: 300,
  [theme.breakpoints.down('md')]: {
    justifyContent: 'flex-start',
  },
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
  },
}));

export { DeviceContainer, DeviceContainerHeader, DeviceSignature };
