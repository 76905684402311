import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';

export interface ICustomerResponse {
  id: string;
  name: string;
}

export const getCustomers = (): Promise<AxiosResponse<ICustomerResponse[]>> => {
  return httpsService.get(Endpoint.CUSTOMERS_LIST);
};
