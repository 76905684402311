import { Grid } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { OrganizationSwitcher } from '@components';
import { ICustomer } from '@api/types';

interface MobileTopbarProps {
  handleMenu: (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  currentCustomer: ICustomer | undefined;
}

const MobileTopbar: React.FC<MobileTopbarProps> = ({ handleMenu, currentCustomer }) => {
  return (
    <Grid container justifyContent="space-between" flexDirection={'row'}>
      <Grid container alignContent="center" justifyContent="space-between">
        <Grid item>
          <div onClick={handleMenu(true)}>
            <MenuIcon fontSize="medium" />
          </div>
        </Grid>
        <OrganizationSwitcher currentCustomer={currentCustomer} />
      </Grid>
    </Grid>
  );
};

export default MobileTopbar;
