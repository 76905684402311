import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';

interface IGetFlatsRequest {
  buildingId: string;
}

interface IFlatResponse {
  name: string;
  id: string;
}

export const getFlats = ({ buildingId }: IGetFlatsRequest): Promise<AxiosResponse<IFlatResponse[]>> => {
  return httpsService.get(Endpoint.FLATS.replace(':buildingId', buildingId));
};
