import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider, UserProvider, HttpProvider, DeviceProvider } from '@contexts';
import { Router } from '@router';
import { ModalProvider } from '@contexts';
import { QueryClientProvider } from 'react-query';

import { Layout } from './screens/Layout';
import { queryClient } from './react-query';

export const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <UserProvider>
          <DeviceProvider>
            <SnackbarProvider>
              <ModalProvider>
                <HttpProvider>
                  <Layout>
                    <Router />
                  </Layout>
                </HttpProvider>
              </ModalProvider>
            </SnackbarProvider>
          </DeviceProvider>
        </UserProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
};
