import { DeviceMeasurements } from './types';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Params, useParams } from 'react-router-dom';
import getDeviceMeasurement from 'src/api/getDeviceMeasurements';
import { MeasurementsGrid } from './components';
import { DeviceContext } from '@contexts';
import { SkeletonLoader } from '@components';

const limit = 10;

const Measurements = () => {
  const theme = useTheme();
  const [deviceMeasurements, setDeviceMeasurements] = useState<DeviceMeasurements[]>([]);
  const { id, type } = useParams<Params>();
  const [isDeviceMeasurementsLoading, setDeviceMeasurementsLoading] = useState(false);

  const { device } = useContext(DeviceContext);
  const [page, setPage] = useState<number>(0);
  const [pageMobile, setPageMobile] = useState<number>(0);
  const [totalSize, setTotalSize] = useState<number>(0);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (isMobile) return;
    if (id && type) {
      const offset = page === 0 ? 0 : page * limit;
      getDeviceMeasurement({
        deviceId: id,
        limit: limit,
        offset,
        setDeviceMeasurementsLoading,
        setDeviceMeasurements,
        setTotalSize,
        type
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, page]);

  useEffect(() => {
    if (id && type && isMobile) {
      const offset = pageMobile === 0 ? 0 : pageMobile * limit;
      getDeviceMeasurement({
        deviceId: id,
        limit: limit,
        offset,
        currentData: deviceMeasurements,
        setDeviceMeasurementsLoading,
        setDeviceMeasurements,
        setTotalSize,
        type
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, pageMobile]);

  return (
    <Box>
      <Box sx={{ marginTop: 5, marginBottom: 5 }}>
        <Typography variant="h5"> Tabela Pomiarów</Typography>
      </Box>

      <Box sx={{ width: '100%' }}>
        {isDeviceMeasurementsLoading && !isMobile ? (
          <Box sx={{ marginLeft: 10, marginRight: 10 }}>
            <SkeletonLoader loadersCount={16} />
          </Box>
        ) : (
          <MeasurementsGrid
            deviceType={device.type}
            measurements={deviceMeasurements}
            limit={limit}
            totalSize={totalSize}
            page={page}
            pageMobile={pageMobile}
            isDeviceMeasurementsLoading={isDeviceMeasurementsLoading}
            setPageMobile={setPageMobile}
            setPage={setPage}
          />
        )}
      </Box>
    </Box>
  );
};

export default Measurements;
