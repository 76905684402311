import { Endpoint } from '@api';
import { SnackbarAction, SnackbarActionType, SnackbarType } from '@contexts';
import { httpsService } from '@services';
import { ChangeMyPasswordApiProps } from './types';

interface ChangeMyPasswordProps {
  snackbarDispatch: SnackbarAction;
  oldPassword: string;
  newPassword: string;
}

const updateMyUserPassword = async ({ snackbarDispatch, oldPassword, newPassword }: ChangeMyPasswordProps) => {
  try {
    const { status } = await httpsService.put<ChangeMyPasswordApiProps>(Endpoint.MY_USER_PASSWORD, {
      oldPassword,
      password: newPassword,
      confirmPassword: newPassword,
    });

    if (status === 204) {
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: `Zaktualizowano hasło`,
          type: SnackbarType.SUCCESS,
        },
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export default updateMyUserPassword;
