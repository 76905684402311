import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';

export interface IDeleteAssignedLocatorProps {
  locatorId: string;
  locationId: string;
}

export const deleteAssignedLocator = ({
  locatorId,
  locationId,
}: IDeleteAssignedLocatorProps): Promise<AxiosResponse> => {
  return httpsService.delete(
    Endpoint.ASSIGN_LOCATOR.replace(':locatorId', locatorId).replace(':locationId', locationId)
  );
};
