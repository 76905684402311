import { useQuery } from 'react-query';
import { SnackbarActionType, SnackbarContext, SnackbarType } from '@contexts';
import { useContext } from 'react';

import { getWaterMetersHotReport } from '../infrastructure/get-water-meters-hot';

interface IReportProps {
  meterId: string;
}

export const useGetWaterMetersHotReport = ({ meterId }: IReportProps) => {
  const { snackbarDispatch } = useContext(SnackbarContext);
  return useQuery({
    queryKey: ['waterMetersHotReport', meterId],
    queryFn: () => getWaterMetersHotReport({ meterId }),
    keepPreviousData: false,
    enabled: false,
    onSuccess: (data) => {
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: 'Rozpoczęto pobieranie raportu - hot',
          type: SnackbarType.SUCCESS,
        },
      });
      const url = window.URL.createObjectURL(new Blob([data?.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `wodmar-report-${new Date().toISOString()}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    onError: () => {
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: 'Nie można wygenerować raportu',
          type: SnackbarType.ERROR,
        },
      });
    },
  });
};
