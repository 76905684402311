import { useMediaQuery, useTheme } from '@mui/material';
import { DeviceAlertsProps } from '@api/types';
import { StyledAlertGrid } from './AlertGrid.styles';
import { AlertGridColumns } from './AlertGridColumns';
import { ListMobile } from '../ListMobile';
import { CustomNoRowsOverlay, CustomPagination } from '@components';
import { Dispatch, SetStateAction } from 'react';

interface AlertGridProps {
  alerts: DeviceAlertsProps[];
  limit: number;
  totalSize: number;
  page: number;
  pageMobile: number;
  isDeviceAlertsLoading: boolean;
  setPage: Dispatch<SetStateAction<number>>;
  setPageMobile: Dispatch<SetStateAction<number>>;
}

const AlertGrid = ({
  alerts,
  limit,
  totalSize,
  page,
  pageMobile,
  isDeviceAlertsLoading,
  setPage,
  setPageMobile,
}: AlertGridProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const Pagination = () => <CustomPagination totalSize={totalSize} page={page} setPage={setPage} limit={limit} />;

  return (
    <>
      {isMobile ? (
        <ListMobile
          alerts={alerts}
          isDeviceAlertsLoading={isDeviceAlertsLoading}
          pagination={
            <CustomPagination totalSize={totalSize} page={pageMobile} setPage={setPageMobile} limit={limit} />
          }
        />
      ) : (
        <StyledAlertGrid
          initialState={{
            pagination: {
              pageSize: limit,
              page: 0,
            },
          }}
          rows={alerts}
          hideFooterSelectedRowCount={true}
          headerHeight={50}
          rowBuffer={0}
          rowHeight={100}
          columnBuffer={0}
          columns={AlertGridColumns}
          pageSize={limit}
          showColumnRightBorder={false}
          autoHeight={true}
          disableColumnMenu={true}
          disableColumnFilter={true}
          checkboxSelection={false}
          disableSelectionOnClick
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
            Pagination: Pagination,
          }}
          onPageChange={(page: number) => {
            setPage(page);
          }}
          page={page}
          rowCount={totalSize}
          autoPageSize={false}
          paginationMode="server"
          rowsPerPageOptions={[limit]}
        />
      )}
    </>
  );
};

export default AlertGrid;
