import { UseMutationResult, useMutation } from 'react-query';
import { AxiosResponse } from 'axios';
import { SnackbarActionType, SnackbarContext, SnackbarType } from '@contexts';
import { useContext } from 'react';

import { acceptTerms } from '../infrastructure/accept-terms';
import HttpServiceError from '../../../services/httpService/types';

interface IAcceptTermsRequest {}

export const useAcceptTerms = (): UseMutationResult<AxiosResponse, HttpServiceError, IAcceptTermsRequest> => {
  const { snackbarDispatch } = useContext(SnackbarContext);

  return useMutation<AxiosResponse, HttpServiceError, IAcceptTermsRequest>({
    mutationFn: acceptTerms,
    onSuccess: () => {
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: 'Zaakceptowano regulamin',
          type: SnackbarType.SUCCESS,
        },
      });
    },
    onError: (error) => {
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: error?.response?.data.title || 'Wystąpił błąd',
          type: SnackbarType.ERROR,
        },
      });
    },
  });
};
