import tabError from './icons/bledy.svg';
import tabOverview from './icons/ogolne.svg';
import tabDrop from './icons/kropla.svg';
import tabHeatMeter from './icons/cieplomierz.svg';
import dropBlue from './icons/drop-blue.svg';
import heatMeterRed from './icons/cieplomierz-red.svg';
import greenAlert from './icons/green-alert.svg';
import redAlert from './icons/red-alert.svg';
import orangeAlert from './icons/orange-alert.svg';
import resume from './icons/podsumowanie.svg';
import measurement from './icons/pomiary.svg';
import alert from './icons/alerty.svg';
import redDrop from './icons/red-drop.svg';
import greyAlert from './icons/grey-alert.svg';
import measurementDetails from './icons/measurement-details.svg';
import chartIcon from './icons/chart-icon.svg';

interface IconSetKeys {
  tabError: string;
  tabOverview: string;
  tabDrop: string;
  tabHeatMeter: string;
  dropBlue: string;
  heatMeterRed: string;
  greenAlert: string;
  redAlert: string;
  orangeAlert: string;
  tabDeviceResume: string;
  tabDeviceMeasurement: string;
  tabDeviceAlert: string;
  redDrop: string;
  greyAlert: string;
  measurementDetails: string;
  chartIcon: string;
}

const iconSet: IconSetKeys = {
  tabError,
  tabOverview,
  tabDrop,
  dropBlue,
  tabHeatMeter,
  heatMeterRed,
  greenAlert,
  redAlert,
  orangeAlert,
  tabDeviceAlert: alert,
  tabDeviceResume: resume,
  tabDeviceMeasurement: measurement,
  redDrop,
  greyAlert,
  measurementDetails,
  chartIcon,
};

export default iconSet;
