import { Box, Typography } from '@mui/material';
import { Button } from '@components';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';

interface IManageButtons {
  name: string;
  onEdit: (e: React.MouseEvent) => void;
  onAdd?: (e: React.MouseEvent) => void;
  isFlat?: boolean;
}

export const ManageButtons = ({ name, onAdd, onEdit, isFlat = false }: IManageButtons) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          minWidth: '40%',
        }}
      >
        <Typography>{name}</Typography>
      </Box>
      {isFlat ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
            justifyContent: 'flex-end',
            alignContent: 'center',
            alignItems: 'center',
            width: '60%',
            marginRight: '20px',
          }}
        >
          <Button sx={{ width: '100px' }} onClick={onEdit} startIcon={<EditIcon />}>
            Edytuj
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
            justifyContent: 'flex-end',
            alignContent: 'center',
            alignItems: 'center',
            width: '100%',
            marginRight: '20px',
          }}
        >
          <Button sx={{ width: '100px' }} onClick={onEdit} startIcon={<EditIcon />}>
            Edytuj
          </Button>
          <Button sx={{ width: '100px' }} onClick={onAdd} startIcon={<AddIcon />}>
            Dodaj
          </Button>
        </Box>
      )}
    </>
  );
};
