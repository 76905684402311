import { styled, Box } from '@mui/material';

const UserProfileContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  rowGap: '40px',
}));

export { UserProfileContainer };
