import { Endpoint } from '@api';
import { SnackbarAction, SnackbarActionType, SnackbarType, UserAction, UserActionType } from '@contexts';
import { httpsService } from '@services';
import { UserProps } from 'src/contexts/UserContext';

interface UpdateMyUserDataProps {
  userDispatch: UserAction;
  snackbarDispatch: SnackbarAction;
  newUser: UserProps;
}

const updateMyUserData = async ({ userDispatch, snackbarDispatch, newUser }: UpdateMyUserDataProps) => {
  try {
    const { status } = await httpsService.put<UserProps>(Endpoint.UPDATE_MY_USER_DATA, {
      name: newUser.name,
      surname: newUser.surname,
      email: newUser.email,
      role: newUser.roles,
    });

    if (status === 204) {
      userDispatch({
        type: UserActionType.GET_USER_DATA,
        payload: newUser,
      });
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: 'Zaktualizowano',
          type: SnackbarType.SUCCESS,
        },
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export default updateMyUserData;
