import { DeviceAlertsProps } from '@api/types';
import { Box, Divider } from '@mui/material';
import { ListMobileWrapper, ListMobileItem } from './ListMobile.styles';
import { ItemSignature, ItemInfo } from './Item';
import { CustomNoRowsOverlay } from '@components';
import { SkeletonLoader } from '@components';

interface ListMobileProps {
  alerts: DeviceAlertsProps[];
  isDeviceAlertsLoading: boolean;
  pagination: JSX.Element;
}

interface ListMobileItemProps {
  alert: DeviceAlertsProps;
}

const ListItem: React.FC<ListMobileItemProps> = ({ alert }) => {
  return (
    <ListMobileItem>
      <ItemSignature alert={alert} />
      <ItemInfo alert={alert} />
    </ListMobileItem>
  );
};

const ListMobile: React.FC<ListMobileProps> = ({ alerts, isDeviceAlertsLoading, pagination }) => {
  return (
    <ListMobileWrapper>
      {alerts.length > 0 ? (
        alerts.map((alert) => {
          return (
            <Box key={alert.id}>
              <ListItem alert={alert} />
              <Divider />
            </Box>
          );
        })
      ) : isDeviceAlertsLoading ? (
        <SkeletonLoader loadersCount={5} />
      ) : (
        <CustomNoRowsOverlay />
      )}
      {alerts.length > 0 && pagination}
    </ListMobileWrapper>
  );
};

export default ListMobile;
