export enum SnackbarActionType {
  SHOW = 'show',
  DISMISS = 'dismiss',
}

export enum SnackbarType {
  INFO = 'info',
  ERROR = 'error',
  SUCCESS = 'success',
}
