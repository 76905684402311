import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';

export interface IEditLocator {
  locatorId: string;
  name?: string | null;
  surname?: string | null;
  email?: string | null;
  password?: string | null;
}

export const editLocator = ({ locatorId, name, email, surname, password }: IEditLocator): Promise<AxiosResponse> => {
  return httpsService.put(Endpoint.EDIT_LOCATOR.replace(':locatorId', locatorId), {
    name,
    email,
    surname,
    password,
  });
};
