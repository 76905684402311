import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';

import { ILocationDashboardParams, ILocationDashboardResponse } from './model';

export const getLocationsDashboard = async (
  payload: ILocationDashboardParams
): Promise<AxiosResponse<ILocationDashboardResponse>> => {
  return httpsService.get(
    Endpoint.LOCATION_DASHBOARD.replace(':limit', payload.limit.toString())
      .replace(':offset', payload.offset.toString())
      .replace(':cityId', payload.params.cityId || '')
      .replace(':streetId', payload.params.streetId || '')
      .replace(':buildingId', payload.params.buildingId || '')
      .replace(':flatId', payload.params.flatId || '')
      .replace(':onlyAlerts', payload.params.onlyAlerts || 'false')
  );
};
