import { useQuery } from 'react-query';
import { useContext } from 'react';
import { getStoredCurrentCustomerData } from '@helpers';
import { UserContext } from '@contexts';

import { getLocationsDashboard } from '../infrastructure/get-locations-dashboard';
import { ILocationDashboardParams } from '../infrastructure/model';

export const useGetLocationsDashboard = (params: ILocationDashboardParams) => {
  const { userState } = useContext(UserContext);
  const storedCustomer = getStoredCurrentCustomerData();

  return useQuery({
    queryFn: () => getLocationsDashboard(params),
    queryKey: ['locations-dashboard', params, userState.currentCustomer, userState.id],
    keepPreviousData: false,
    enabled: !!storedCustomer?.id,
  });
};
