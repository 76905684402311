import { Endpoint } from '@api';
import { httpsService } from '@services';
import { Dispatch, SetStateAction } from 'react';
import { UserProps } from 'src/contexts/UserContext';
import { Pagination } from './types';

interface GetUsersListProps {
  setUsersListState: Dispatch<SetStateAction<UserProps[]>>;
}

const getUsersList = async ({ setUsersListState }: GetUsersListProps) => {
  try {
    const { data, status } = await httpsService.get<Pagination<UserProps[]>>(`${Endpoint.ADMINS}/?limit=100`);

    if (status === 200) {
      setUsersListState(data.results);
    }
  } catch (err) {
    console.error(err);
  }
};

export default getUsersList;
