import { Box, styled } from '@mui/material';

const PasswordContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignContent: 'center',
  margin: '50px 10px',
}));

export { PasswordContainer };
