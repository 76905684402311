import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';
import { ILocationMetersParams, ILocationMetersResponse } from './model';

export const getLocationMeters = async (
  payload: ILocationMetersParams
): Promise<AxiosResponse<ILocationMetersResponse>> => {
  return httpsService.get(Endpoint.LOCATION_METERS.replace(':locationId', payload.locationId.toString()));
};
