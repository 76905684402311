import { useGetClients } from '@api';
import { CustomNoRowsOverlay } from '@components';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import { Chip } from '@mui/material';
import { ModalActionType, ModalContext, UserContext, UserProps } from '@contexts';
import { useContext } from 'react';

import UserProfile from '../Users/UserProfile/UserProfile';

export const ListColumns = (): GridColDef[] => {
  return [
    {
      field: 'name',
      headerName: 'Imie',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      flex: 1,
    },
    {
      field: 'surname',
      headerName: 'Nazwisko',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      flex: 1,
    },
    {
      field: 'isActive',
      headerName: 'Aktywny',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<UserProps>) =>
        params.row.isActive ? (
          <Chip
            label="Aktywny"
            style={{
              backgroundColor: '#E9FCD4',
              borderColor: '#AAF27F',
              border: 'solid',
              borderWidth: '1px',
              color: '#229A16',
            }}
          />
        ) : (
          <Chip
            label="Nieaktywny"
            style={{
              backgroundColor: '#FFF7CD',
              borderColor: '#FFE16A',
              border: 'solid',
              borderWidth: '1px',
              color: '#B78103',
            }}
          />
        ),
    },
  ];
};

export const ClientsList = () => {
  const { data, isLoading } = useGetClients();
  const { modalDispatch } = useContext(ModalContext);
  const { userState } = useContext(UserContext);

  const handleRowClick = (params: GridRowParams) => {
    modalDispatch({
      type: ModalActionType.SHOW,
      payload: {
        content: <UserProfile variant="client" user={params.row} />,
      },
    });
  };

  return (
    <div>
      <DataGrid
        rows={data?.data.results || []}
        initialState={{
          pagination: {
            pageSize: 10,
            page: 0,
          },
        }}
        hideFooterSelectedRowCount={true}
        headerHeight={50}
        rowBuffer={0}
        rowHeight={100}
        columnBuffer={0}
        columns={ListColumns()}
        showColumnRightBorder={false}
        autoHeight={true}
        disableColumnMenu={true}
        disableColumnFilter={true}
        checkboxSelection={false}
        disableSelectionOnClick
        onRowClick={userState.roles === 'Administrator' ? handleRowClick : undefined}
        loading={isLoading}
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        autoPageSize={true}
        paginationMode="server"
        rowCount={data?.data.totalSize || 0}
      />
    </div>
  );
};
