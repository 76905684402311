import { UserAction, UserActionType, SnackbarAction, SnackbarType, SnackbarActionType } from '@contexts';
import { PATH } from '@router';
import { NavigateFunction } from 'react-router-dom';

import { queryClient } from '../react-query';

interface Logout {
  userDispatch: UserAction;
  snackbarDispatch: SnackbarAction;
  navigate: NavigateFunction;
}

const logout = ({ userDispatch, snackbarDispatch, navigate }: Logout) => {
  userDispatch({
    type: UserActionType.LOG_OUT,
  });

  queryClient.clear();

  navigate(PATH.LOGIN, { replace: true });

  snackbarDispatch({
    type: SnackbarActionType.SHOW,
    payload: {
      message: 'Bezpiecznie wylogowano',
      type: SnackbarType.INFO,
    },
  });
};

export default logout;
