import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';

export interface IAddAdminRequest {
  name: string;
  surname: string;
  email: string;
  password: string;
}

export const addAdmin = ({ name, surname, email, password }: IAddAdminRequest): Promise<AxiosResponse> => {
  return httpsService.post(Endpoint.ADD_ADMIN, { name, surname, email, password });
};
