import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';

export interface IPatchBuildingRequest {
  name: string;
  buildingId: string;
}

export const updateBuildingName = ({ name, buildingId }: IPatchBuildingRequest): Promise<AxiosResponse> => {
  return httpsService.patch(Endpoint.BUILDINGS_COMMON, { name, buildingId });
};
