import { styled, Button, IconButton } from '@mui/material';

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  color: theme.palette.grey[900],
  borderRadius: '8px',
  height: 50,
  textTransform: 'none',
  width: '100%',
  '&:hover': {
    backgroundColor: theme.palette.grey[200],
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  color: theme.palette.grey[900],
  height: 50,
  borderRadius: '8px',
  textTransform: 'none',
  width: '100%',
  '&:hover': {
    backgroundColor: theme.palette.grey[200],
  },
}));

export { StyledButton, StyledIconButton };
