import { styled } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const Grid = styled(DataGrid)(() => ({
  border: 0,
  marginLeft: 5,
  marginRight: 5,
}));

export { Grid };
