import { styled } from '@mui/material';
import { NavLink } from 'react-router-dom';

const StyledBreadcrumb = styled(NavLink)(({ theme }) => ({
  ...theme.typography.subtitle2,
  textDecoration: 'none',
  '&:link': {
    color: theme.palette.common.black,
  },
  '&:active': {
    color: theme.palette.common.black,
  },
  '&:visited': {
    color: theme.palette.common.black,
  },
  '&:hover': {
    color: theme.palette.common.black,
  },
}));

export default StyledBreadcrumb;
