import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';

export interface IPostStreetRequest {
  name: string;
  cityId: string;
}

export const addNewStreet = ({ name, cityId }: IPostStreetRequest): Promise<AxiosResponse> => {
  return httpsService.post(Endpoint.STREETS_COMMON, { name, cityId });
};
