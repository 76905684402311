import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';

export interface IPatchExpirationRequest {
  expirationDate: string;
  meterId: string;
}

export const updateHeatMeterExpirationDate = ({
  meterId,
  expirationDate,
}: IPatchExpirationRequest): Promise<AxiosResponse> => {
  return httpsService.patch(Endpoint.HEAT_METER_EXPIRATION_DATE.replace(':meterId', meterId), { expirationDate });
};
