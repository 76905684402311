import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import moment from 'moment';
import { Icon, Button } from '@components';
import { iconSet } from '@assets';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router-dom';
import { PATH } from '@router';

interface IHeatMeter {
  id: string;
  alert: string | null;
  lastEnergyMeasurementDate: string;
  lastEnergyMeasurementValue: number;
  serialNumber: string;
  meterType: string;
  measurementUnit: string;
}

interface ITableDetailsProps {
  meter?: IHeatMeter[];
}

const renderMeasurementEnergyValue = (value: number | null) => {
  if (value === null) return 'Brak pomiaru';
  if (value === 0) return `0 GJ`;
  if (value !== undefined)
    return (
      <>
        {value} <i>GJ</i>
      </>
    );
};

export const TableDetailsHeater = ({ meter }: ITableDetailsProps) => {
  const navigate = useNavigate();
  const handleNavigateToDeviceDetails = (id: string) => {
    navigate(`/panel/${PATH.DEVICE.replace(':id', id).replace(':type', 'heater')}`);
  };

  if (!meter) {
    return null;
  }
  return (
    <TableContainer component={Paper}>
      <Typography variant="h6" align="center">
        Ciepłomierze
      </Typography>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="center">ID</TableCell>
            <TableCell align="center">Data</TableCell>
            <TableCell align="center">Pomiar</TableCell>
            <TableCell align="center">Typ</TableCell>
            <TableCell align="center">Alert</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {meter.map((row) => (
            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="center">{row.serialNumber}</TableCell>
              <TableCell align="center">
                {row.lastEnergyMeasurementDate && `${moment.utc(row.lastEnergyMeasurementDate).local().format('lll')}`}
              </TableCell>
              <TableCell align="center">{renderMeasurementEnergyValue(row.lastEnergyMeasurementValue)}</TableCell>
              <TableCell align="center">
                <Icon iconKey={iconSet.heatMeterRed} />
              </TableCell>
              <TableCell align="center">{row.alert}</TableCell>
              <TableCell align="center">
                <Button onClick={() => handleNavigateToDeviceDetails(row.id)} startIcon={<SettingsIcon />}>
                  Szczegóły
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
