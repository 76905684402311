import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import PermContactCalendarRoundedIcon from '@mui/icons-material/PermContactCalendarRounded';
import LogoutIcon from '@mui/icons-material/Logout';
import SearchIcon from '@mui/icons-material/Search';
import TextSnippetRoundedIcon from '@mui/icons-material/TextSnippetRounded';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import MapsHomeWorkRoundedIcon from '@mui/icons-material/MapsHomeWorkRounded';
import AccessibilityRoundedIcon from '@mui/icons-material/AccessibilityRounded';
import AssignmentReturnRoundedIcon from '@mui/icons-material/AssignmentReturnRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import { PATH } from '@router';

import { ISideNavOption } from './types';

export const optionMap = {
  overview: {
    to: `/${PATH.DASHBOARD}/${PATH.PANEL}`,
    Icon: <SearchIcon />,
    title: 'Podgląd odczytów',
  },
  locations: {
    to: `/${PATH.DASHBOARD}/${PATH.LOCATIONS_LIST}`,
    Icon: <LocationSearchingIcon />,
    title: 'Lokalizacje',
  },
  reports: {
    to: `/${PATH.DASHBOARD}/${PATH.REPORTS}`,
    Icon: <TextSnippetRoundedIcon />,
    title: 'Raporty',
    disabled: true,
    nestedOptions: [
      {
        to: `/${PATH.DASHBOARD}/${PATH.REPORTS}/${PATH.MONTHLY_REPORT}`,
        Icon: <CalendarViewMonthIcon />,
        title: 'Miesięczny',
      },
    ],
  },
  admins: {
    to: `/${PATH.DASHBOARD}/${PATH.ADMIN_LIST}`,
    Icon: <ManageAccountsRoundedIcon />,
    title: 'Konta systemowe',
    nestedOptions: [
      {
        to: `/${PATH.DASHBOARD}/${PATH.ADD_USER}`,
        Icon: <AddBoxRoundedIcon />,
        title: 'Dodaj',
      },
    ],
  },
  locators: {
    to: `/${PATH.DASHBOARD}/${PATH.LOCATORS_LIST}`,
    Icon: <AccessibilityRoundedIcon />,
    title: 'Konta lokatorów',
    nestedOptions: [
      {
        to: `/${PATH.DASHBOARD}/${PATH.ADD_LOCATOR}`,
        Icon: <AddBoxRoundedIcon />,
        title: 'Dodaj',
      },
      {
        to: `/${PATH.DASHBOARD}/${PATH.ASSIGN_LOCATIONS}`,
        Icon: <AssignmentReturnRoundedIcon />,
        title: 'Przypisz lokacje',
      },
    ],
  },
  clientsSystemAdminPerspective: {
    to: `/${PATH.DASHBOARD}/${PATH.CLIENTS_LIST}`,
    Icon: <PeopleRoundedIcon />,
    title: 'Administratorzy',
    nestedOptions: [
      {
        to: `/${PATH.DASHBOARD}/${PATH.ADD_CLIENT}`,
        Icon: <AddBoxRoundedIcon />,
        title: 'Dodaj',
      },
    ],
  },
  clientsOrgAdminPerspective: {
    to: `/${PATH.DASHBOARD}/${PATH.CLIENTS_LIST}`,
    Icon: <PeopleRoundedIcon />,
    title: 'Administratorzy',
  },
  my: {
    to: `/${PATH.DASHBOARD}/${PATH.MY_PROFILE}`,
    Icon: <PermContactCalendarRoundedIcon />,
    title: 'Profil',
  },
  customers: {
    to: `/${PATH.DASHBOARD}/${PATH.CUSTOMER_LIST}`,
    Icon: <MapsHomeWorkRoundedIcon />,
    title: 'Organizacje',
    nestedOptions: [
      {
        to: `/${PATH.DASHBOARD}/${PATH.ADD_CUSTOMER}`,
        Icon: <AddBoxRoundedIcon />,
        title: 'Dodaj',
      },
    ],
  },
  logout: {
    to: '/',
    Icon: <LogoutIcon />,
    title: 'Wyloguj',
  },
};

export const useSideNavOptions = () => {
  const topNavLinks: ISideNavOption[] = [
    optionMap.overview as ISideNavOption,
    optionMap.my as ISideNavOption,
    optionMap.reports as ISideNavOption,
  ];

  const adminNavLinks: ISideNavOption[] = [
    optionMap.admins as ISideNavOption,
    optionMap.customers as ISideNavOption,
    optionMap.clientsSystemAdminPerspective as ISideNavOption,
  ];

  const middleNavLinks: ISideNavOption[] = [optionMap.locations as ISideNavOption];

  const clientNavLinks: ISideNavOption[] = [optionMap.clientsOrgAdminPerspective as ISideNavOption, optionMap.locators as ISideNavOption];

  const bottomNavLinks: ISideNavOption[] = [optionMap.logout as ISideNavOption];

  return {
    topNavLinks,
    adminNavLinks,
    bottomNavLinks,
    clientNavLinks,
    middleNavLinks,
  };
};
