import { UseMutationResult, useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

import { IPatchBuildingRequest, updateBuildingName } from '../infrastructure/patch-building';

export const useUpdateBuildingName = (): UseMutationResult<AxiosResponse, unknown, IPatchBuildingRequest> => {
  return useMutation<AxiosResponse, unknown, IPatchBuildingRequest>({
    mutationFn: updateBuildingName,
  });
};
