import { Navigate, useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '@contexts';

import { PATH } from './path';

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { userState } = useContext(UserContext);
  let location = useLocation();

  if (userState.termsAccepted === false) {
    return <Navigate to={`/${PATH.TERMS}`} replace />;
  }

  if (!userState.isAutheticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};

export { RequireAuth };
