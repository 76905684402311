import { useQuery } from 'react-query';

import { queryKey } from './query-key.enum';
import { getStreets } from '../infrastructure/get-streets';

type TStreetProps = {
  cityId: string;
  enabled?: boolean;
};

export const useGetStreetsQuery = ({ cityId, enabled = false }: TStreetProps) => {
  return useQuery({
    queryKey: queryKey.STREETS_BY_CITY(cityId),
    queryFn: () => getStreets({ cityId }),
    keepPreviousData: true,
    enabled,
  });
};
