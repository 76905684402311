import { Row } from '@components';
import { Box, styled } from '@mui/material';

const ButtonsContainer = styled(Row)(({ theme }) => ({
  minWidth: 300,
  justifyContent: 'flex-end',
  [theme.breakpoints.down('md')]: {
    paddingTop: 10,
    width: '100%',
    justifyContent: 'flex-start',
    marginLeft: 0,
    marginRight: 0,
  },
  [theme.breakpoints.down('xs')]: {
    width: '100%',
    paddingTop: 10,
    justifyContent: 'space-around',
    marginRight: 0,
  },
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  paddingLeft: 10,
  paddingRight: 10,
  [theme.breakpoints.down('md')]: {
    paddingLeft: 0,
  },
}));

export { ButtonsContainer, ButtonWrapper };
