import { TableContainer, Table, Divider } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { IFilters, LocalStorageKey } from '@api/types';
import { getItem, setItem } from '@services';
import { getStoredCurrentCustomerData } from '@helpers';

import { useStyles } from './wodmar-collapssed-table.styles';
import { TableBodyComponent } from './table-body/table-body.component';
import { TableHeader } from './table-header/table-header.component';
import { useLocationSummary } from '../../screens/Dashboard/location-summary/use-get-location-summary.hook';
import { CustomPagination } from '../pagination';
import { FilterBar } from './filter-bar/filter-bar.component';

const limit = 10;

const defaultParams: IFilters = {
  cityId: '',
  streetId: '',
  buildingId: '',
  flatId: '',
};

export const WodmarLocationsDashboardTable = () => {
  let filters = getItem<IFilters>(LocalStorageKey.FILTERS) || defaultParams;
  const { classes } = useStyles();
  const [page, setPage] = useState<number>(0);
  const [params, setParams] = useState<IFilters>(defaultParams);
  const storedCustomer = getStoredCurrentCustomerData();

  const { data, pagination } = useLocationSummary({
    limit: limit,
    offset: page === 0 ? 0 : page * limit,
    params: filters || params,
  });

  const handleParamsChange = useCallback(
    (newParams: IFilters) => {
      setPage(0);
      setItem(LocalStorageKey.FILTERS, newParams);
      setParams(newParams);
    },
    [setParams, setPage]
  );

  useEffect(() => {
    // TODO: Set first page when customer changed
    setPage(0);
  }, [storedCustomer?.id]);

  return (
    <div className={classes.container}>
      <FilterBar
        onChangeParams={handleParamsChange}
        initialParams={defaultParams}
        filters={params}
        isReportBar={false}
        isLocatorAssign={false}
      />
      <Divider />
      <TableContainer className={classes.table}>
        <Table>
          <TableHeader />
          <TableBodyComponent rows={data} />
        </Table>
        <CustomPagination totalSize={pagination?.totalSize || 0} limit={limit} page={page} setPage={setPage} />
      </TableContainer>
    </div>
  );
};
