import { UseMutationResult, useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

import { IChangeNetworkRequest, changeNetworkHeatMeter } from '../infrastructure/change-heat-meter-network';

export const useChangeHeatMeterNetwork = (): UseMutationResult<AxiosResponse, unknown, IChangeNetworkRequest> => {
  return useMutation<AxiosResponse, unknown, IChangeNetworkRequest>({
    mutationFn: changeNetworkHeatMeter,
  });
};
