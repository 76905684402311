import { UseMutationResult, useMutation } from 'react-query';
import { AxiosResponse } from 'axios';
import { SnackbarActionType, SnackbarContext, SnackbarType } from '@contexts';
import { useContext } from 'react';

import { addNewLocator, IAddLocatorRequest } from '../infrastructure/add-locator';
import HttpServiceError from '../../../services/httpService/types';

export const useAddLocator = (): UseMutationResult<AxiosResponse, HttpServiceError, IAddLocatorRequest> => {
  const { snackbarDispatch } = useContext(SnackbarContext);

  return useMutation<AxiosResponse, HttpServiceError, IAddLocatorRequest>({
    mutationFn: addNewLocator,
    onError: (error: HttpServiceError) => {
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: error.response?.data.title || 'Nie udało się utworzyć nowego lokatora',
          type: SnackbarType.ERROR,
        },
      });
    },
    onSuccess: () => {
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: 'Utworzono nowego lokatora',
          type: SnackbarType.SUCCESS,
        },
      });
    },
  });
};
