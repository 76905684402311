import { useContext, useEffect, useState } from 'react';
import { Outlet, Params, useLocation, useNavigate, useParams } from 'react-router-dom';
import { DeviceContainer, DeviceContainerHeader, DeviceSignature } from './Device.styles';
import { Box, Typography } from '@mui/material';
import { Icon, SkeletonLoader } from '@components';
import { iconSet } from '@assets';
import { PATH } from '@router';
import { Buttons } from './components';
import { Tabs } from '../components/Tabs';
import { TabProps } from '../components/Tabs/Tabs';
import { Tab } from './Tabs';
import { DeviceContext } from '@contexts';
import { getDeviceDetails } from '@api';

const Device = () => {
  const [currentSubPage, setCurrentSubPage] = useState(Tab.RESUME);
  const params = useParams<Params>();
  const location = useLocation();
  const navigate = useNavigate();
  const { id, type } = params;
  const { deviceDispatch, device } = useContext(DeviceContext);
  const [isDeviceDetailsLoading, setDeviceDetailsLoading] = useState(false);

  useEffect(() => {
    if (id && type) {
      getDeviceDetails({
        deviceDispatch,
        deviceId: id,
        setDeviceDetailsLoading,
        type: type,
      });
    }
  }, [type, id, deviceDispatch]);

  useEffect(() => {
    if (location.pathname.includes(PATH.DEVICE_DETAILS_ALERTS)) {
      setCurrentSubPage(Tab.ALERTS);
      navigate(PATH.DEVICE_DETAILS_ALERTS);
      return;
    } else if (location.pathname.includes(PATH.DEVICE_DETAILS_MEASUREMENTS)) {
      setCurrentSubPage(Tab.MEASUREMENTS);
      navigate(PATH.DEVICE_DETAILS_MEASUREMENTS);
      return;
    }
    setCurrentSubPage(Tab.RESUME);
    navigate(PATH.DEVICE_DETAILS_OVERVIEW);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, location.pathname]);

  const handleChange = (event: any, page: number) => {
    setCurrentSubPage(page);
  };

  const tabs: TabProps[] = [
    {
      label: 'Podsumowanie',
      iconKey: iconSet.tabDeviceResume,
      path: `/panel/${PATH.DEVICE.replace(':id', id || '').replace(':type', type || '')}/${
        PATH.DEVICE_DETAILS_OVERVIEW
      }`,
    },
    {
      label: 'Alerty',
      iconKey: iconSet.tabDeviceAlert,
      path: `/panel/${PATH.DEVICE.replace(':id', id || '').replace(':type', type || '')}/${PATH.DEVICE_DETAILS_ALERTS}`,
    },
    {
      label: 'Pomiary',
      iconKey: iconSet.tabDeviceMeasurement,
      path: `/panel/${PATH.DEVICE.replace(':id', id || '').replace(':type', type || '')}/${
        PATH.DEVICE_DETAILS_MEASUREMENTS
      }`,
    },
  ];

  return (
    <DeviceContainer>
      <DeviceContainerHeader>
        <DeviceSignature>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              width: '100%',
              alignItems: 'center',
            }}
          >
            {isDeviceDetailsLoading ? (
              <Box sx={{ width: 200 }}>
                <SkeletonLoader loadersCount={1} />
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  {device?.type === 'wodomierz' ? (
                    device?.parameters?.type === 'Zimna woda' ? (
                      <Icon width={'30'} height={'30'} iconKey={iconSet.dropBlue} />
                    ) : (
                      <Icon width={'30'} height={'30'} iconKey={iconSet.redDrop} />
                    )
                  ) : (
                    <Icon width={'30'} height={'30'} iconKey={iconSet.heatMeterRed} />
                  )}
                </Box>
                <Box>
                  <Typography variant="h6">
                    {device?.type === 'wodomierz' ? 'Wodomierz' : 'Ciepłomierz'} [{device?.name}]
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </DeviceSignature>
        <Box>
          <Buttons deviceId={id ?? ''} />
        </Box>
      </DeviceContainerHeader>
      <Tabs tabs={tabs} value={currentSubPage} handleChange={handleChange} />
      <Outlet />
    </DeviceContainer>
  );
};

export default Device;
