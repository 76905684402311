import { Accordion, AccordionSummary, useTheme } from '@mui/material';
import React, { useContext } from 'react';
import { ModalActionType, ModalContext } from '@contexts';

import { AddFlatUpsertForm } from './forms/add-flat.form';
import { ManageButtons } from './manage-buttons';

interface FlatAccordionProps {
  name: string;
  id: string;
  buildingId: string;
}

export const FlatAccordion = ({ name, id, buildingId }: FlatAccordionProps) => {
  const theme = useTheme();
  const { modalDispatch } = useContext(ModalContext);

  const openEditFlatgModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    modalDispatch({
      type: ModalActionType.SHOW,
      payload: {
        content: <AddFlatUpsertForm flat={{ id, name, buildingId }} />,
      },
    });
  };

  return (
    <Accordion disableGutters sx={{ backgroundColor: theme.palette.grey[400] }}>
      <AccordionSummary>
        <ManageButtons name={name} onEdit={openEditFlatgModal} isFlat />
      </AccordionSummary>
    </Accordion>
  );
};
