import { UseMutationResult, useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

import {
  IPatchLegalizationRequest,
  updateWaterMeterLegalizationDate,
} from '../infrastructure/update-water-meter-legalization-date';

export const useUpdateWaterMeterLegalizationDateCommand = (): UseMutationResult<
  AxiosResponse,
  unknown,
  IPatchLegalizationRequest
> => {
  return useMutation<AxiosResponse, unknown, IPatchLegalizationRequest>({
    mutationFn: updateWaterMeterLegalizationDate,
  });
};
