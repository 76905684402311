import { httpsService } from '@services';
import { Endpoint } from '@api';

interface IReportProps {
  meterId: string;
}

export const getHeatMetersReport = ({ meterId }: IReportProps) => {
  return httpsService.get(Endpoint.HEAT_METER_REPORT.replace(':meterId', meterId || ''));
};
