import { UseMutationResult, useMutation } from 'react-query';
import { AxiosResponse } from 'axios';
import { SnackbarActionType, SnackbarContext, SnackbarType } from '@contexts';
import { useContext } from 'react';

import { addAdmin, IAddAdminRequest } from '../infrastructure/add-admin';
import HttpServiceError from '../../../services/httpService/types';

export const useAddAdmin = (): UseMutationResult<AxiosResponse, HttpServiceError, IAddAdminRequest> => {
  const { snackbarDispatch } = useContext(SnackbarContext);

  return useMutation<AxiosResponse, HttpServiceError, IAddAdminRequest>({
    mutationFn: addAdmin,
    onSuccess: () => {
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: 'Utworzono nowego administratora',
          type: SnackbarType.SUCCESS,
        },
      });
    },
    onError: (error) => {
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: error?.response?.data.title || 'Wystąpił błąd',
          type: SnackbarType.ERROR,
        },
      });
    },
  });
};
