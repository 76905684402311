import { useQuery } from 'react-query';

import { getLocators, ILocatorParams } from '../infrastructure/get-locators';

export const useGetLocators = (params: ILocatorParams) => {
  return useQuery({
    queryFn: () => getLocators(params),
    queryKey: ['locators', params],
    cacheTime: 0,
    staleTime: 0,
  });
};
