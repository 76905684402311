import { Endpoint } from '@api';
import { httpsService, setItem } from '@services';
import { Device, LocalStorageKey, Pagination } from '@api/types';
import { Dispatch, SetStateAction } from 'react';

export enum DeviceType {
  WATER_METER = 'WaterMeter',
  HEAT_METER = 'HeatMeter',
}
interface DeviceList {
  limit: number;
  offset: number;
  currentData?: Device[];
  setDevices: Dispatch<SetStateAction<Device[]>>;
  setTotalSize: Dispatch<SetStateAction<number>>;
  setDeviceListLoading: Dispatch<SetStateAction<boolean>>;
  cityId?: string;
  streetId?: string;
  addressId?: string;
  deviceTypeFilter?: DeviceType;
}

const getDeviceList = async ({
  limit,
  offset,
  setDevices,
  setDeviceListLoading,
  cityId,
  streetId,
  addressId,
  currentData,
  setTotalSize,
  deviceTypeFilter,
}: DeviceList) => {
  if (!currentData) {
    setDevices([]);
  }
  setDeviceListLoading(true);
  try {
    const devicesListResponse = await httpsService.get<Pagination<Device[]>>(
      `${Endpoint.DEVICE_LIST}/?limit=${limit}&offset=${offset}&cityId=${cityId ?? ''}&streetId=${
        streetId ?? ''
      }&addressId=${addressId ?? ''}&type=${deviceTypeFilter ?? ''}`
    );

    if (devicesListResponse.status === 200) {
      if (devicesListResponse.data.results.length > 0) {
        setDevices(devicesListResponse.data.results);
        setTotalSize((prevTotalSize) =>
          devicesListResponse.data.totalSize !== undefined ? devicesListResponse.data.totalSize : prevTotalSize
        );
      } else {
        setDevices([]);
        setTotalSize(0);
      }
      setItem(LocalStorageKey.FILTERS, {
        cityId: cityId,
        addressId: addressId,
        streetId: streetId,
        type: deviceTypeFilter,
        alerts: false,
      });
    }
  } catch (err) {
    console.error(err);
  }
  setDeviceListLoading(false);
};

export default getDeviceList;
