import { Box, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

import { AddCustomerStyles } from './AddCustomer.styles';
import { AddCustomerForm } from './AddCustomer.form';

export const AddCustomer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <AddCustomerStyles>
      <Paper
        elevation={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '20px',
          padding: '70px 40px',
        }}
      >
        <Typography variant="h4" textAlign="center">
          Dodaj nową organizację
        </Typography>
        <Box sx={{ width: isMobile ? '220px' : '500px' }}>
          <AddCustomerForm />
        </Box>
      </Paper>
    </AddCustomerStyles>
  );
};
