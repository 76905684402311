// Pagination
interface Pagination<T> {
  limit: number;
  offset: number;
  totalSize: number;
  results: T;
}

// Devices
interface DeltaVolumes {
  item1: string; // datetime
  item2: number; // double
}

interface ILastMeasurement {
  date: string;
}

interface LastMeasurementWaterMeter extends ILastMeasurement {
  value: number;
}

interface LastMearuementHeatMeter extends ILastMeasurement {
  volumeValue: number;
  energyValue: number;
  energyDeltaValues: DeltaVolumes[];
  volumeDeltaValues: DeltaVolumes[];
}

interface DeviceMeasurement extends LastMearuementHeatMeter, LastMeasurementWaterMeter {}

interface Device {
  id: string;
  name: string;
  type: string;
  flatNumber?: string;
  buildingNumber: string;
  lastMeasurement: DeviceMeasurement;
  meterState: 'Active' | 'Inactive' | 'Alert';
  waterMeterType?: string;
  heatMeterType?: string;
  address: string;
}

interface LocalisationProps {
  addressId: string;
  name: string;
}

interface WaterMeterProps {
  type?: string;
  diameter: string;
  measurementClass: string;
  flow: string;
  producer: string;
}

enum AssemblyMethod {
  POWER = 'Power',
  RETURN = 'Return',
  EMPTY = '',
}

interface HeatMeterProps {
  diameter: string;
  measurementClass: string;
  flow: string;
  length?: string;
  producer: string;
  assemblyMethod: AssemblyMethod;
}

interface AlertProps {
  date: string;
  errors: string[] | null;
}

export enum NetworkType {
  TTN,
  Emitel,
}

interface RadioParametersProps {
  deviceEui: string | null;
  joinEui: string | null;
  appKey: string | null;
  networkType: NetworkType;
}

interface DeviceDetailsProps {
  id: string;
  name: string;
  description: string;
  type: string;
  alert: AlertProps | undefined;
  localisation: LocalisationProps | undefined;
  lastMeasurement: DeviceMeasurement | undefined;
  messageGenerationPeriod?: string;
  parameters?: (HeatMeterProps & WaterMeterProps) | undefined;
  measurementPeriod?: string;
  expirationDate: string | null;
  legalizationEndDate: string | null;
  radioParameters: RadioParametersProps | undefined;
}

interface DeviceAlertsProps {
  id: string;
  date: string;
  isActive: boolean;
  errors: string[];
}

enum LocalStorageKey {
  LOGIN_RESPONSE_BODY = 'loginResponseBody',
  MY_PROFILE_DATA = 'myProfileData',
  FILTERS = 'filters',
  CURRENT_CUSTOMER = 'currentCustomer',
}

export { Device, Pagination, LocalStorageKey, DeviceDetailsProps, DeviceAlertsProps };

export interface NewUserProps {
  name: string;
  surname: string;
  email: string;
  password: string;
  confirmPassword: string;
}

export interface ActivatingUserProps {
  userId: string;
  isActive: boolean;
}

export interface UserDeviceProps {
  assignedTo: string | null;
  deviceAddress: string;
  deviceName: string;
  deviceId: string;
}

export interface UserDeviceUsersProps {
  userId: string;
  name: string;
}

export interface ChangeMyPasswordApiProps {
  oldPassword: string;
  password: string;
  confirmPassword: string;
}

export interface ResetPasswordInitApiProps {
  email: string;
}

export interface ResetPasswordApiProps {
  token: string;
  password: string;
  confirmPassword: string;
}

export type MeasurementType = 'Heat' | 'ColdWater' | 'HotWater';

export interface IFilters {
  cityId?: string;
  streetId?: string;
  buildingId?: string;
  flatId?: string;
  onlyAlerts?: string;
  forDay?: number;
  measurementTypes?: MeasurementType[];
}

export interface IFiltersParams {
  cityId: string;
  addressId: string;
  streetId: string;
  type: string;
  alerts: string;
}

export interface ICustomer {
  id: string;
  name: string;
}

export interface ICreateCustomerProps {
  name: string;
  title?: string;
}
