import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';

export interface IPostFlatRequest {
  name: string;
  buildingId: string;
}

export const addNewFlat = ({ name, buildingId }: IPostFlatRequest): Promise<AxiosResponse> => {
  return httpsService.post(Endpoint.FLATS_COMMON, { name, buildingId });
};
