import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Input } from '@components';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Typography, useTheme, Box } from '@mui/material';
import React, { useContext } from 'react';
import { ModalActionType, ModalContext, SnackbarActionType, SnackbarContext, SnackbarType } from '@contexts';
import { useAddCity, useUpdateCityName } from '@api/locations';
import * as yup from 'yup';

interface FormInputProps {
  name: string;
}

interface IAddCityFormProps {
  city?: {
    name: string;
    id: string;
  };
}

const newCityValidationSchema = yup.object().shape({
  name: yup.string().max(255).required('Pole obowiązkowe'),
});
export const AddCityUpsertForm = ({ city }: IAddCityFormProps) => {
  const theme = useTheme();
  const { modalDispatch } = useContext(ModalContext);
  const { snackbarDispatch } = useContext(SnackbarContext);
  const { mutateAsync: updateCity } = useUpdateCityName();
  const { mutateAsync: addCity } = useAddCity();
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm<FormInputProps>({
    resolver: yupResolver(newCityValidationSchema),
    defaultValues: {
      name: city?.name || '',
    },
  });

  const onUpdateSubmit: SubmitHandler<FormInputProps> = async ({ name }) => {
    if (!city) return;
    try {
      const res = await updateCity({ name, cityId: city?.id });
      if (res.status === 204) {
        snackbarDispatch({
          type: SnackbarActionType.SHOW,
          payload: {
            message: `Zaktualizowano nazwę miasta ${name}`,
            type: SnackbarType.SUCCESS,
          },
        });
      }
      modalDispatch({ type: ModalActionType.DISMISS });
    } catch (error) {
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: `Błąd aktualizowania miasta ${name}`,
          type: SnackbarType.ERROR,
        },
      });
    }
  };

  const onAddSubmit: SubmitHandler<FormInputProps> = async ({ name }) => {
    try {
      const res = await addCity({ name });
      if (res.status === 204) {
        snackbarDispatch({
          type: SnackbarActionType.SHOW,
          payload: {
            message: `Dodano nowe miasto ${name}`,
            type: SnackbarType.SUCCESS,
          },
        });
      }
    } catch (error) {
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: `Błąd dodawania miasta ${name}`,
          type: SnackbarType.ERROR,
        },
      });
    }
    modalDispatch({ type: ModalActionType.DISMISS });
  };

  return (
    <form>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, padding: 4 }}>
        <Typography variant="h5" textAlign="center">
          {!!city?.name ? `Edytuj miasto` : `Dodaj nowe miasto`}
        </Typography>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              error={!!errors.name}
              helperText={errors.name && errors.name?.message && errors.name.message}
              label="Nazwa miasta"
              type="text"
              ref={null}
            />
          )}
        />
        <Button
          variant="contained"
          sx={{
            height: '48px',
            borderRadius: '8px',
            backgroundColor: theme.palette.primary.dark,
            alignSelf: 'flex-end',
          }}
          onClick={!!city?.name ? handleSubmit(onUpdateSubmit) : handleSubmit(onAddSubmit)}
          disabled={!isDirty}
        >
          <Typography variant="button" color="white">
            Zatwierdź
          </Typography>
        </Button>
      </Box>
    </form>
  );
};
