import { Dispatch, SetStateAction } from 'react';
import { Endpoint } from '@api';
import { httpsService } from '@services';
import { DeviceMeasurements } from '../screens/Dashboard/Devices/DeviceList/Device/Screens/Measurements/types';
import { Pagination } from '@api/types';

interface DeviceMeasurementProps {
  deviceId: string;
  limit: number;
  offset: number;
  currentData?: DeviceMeasurements[];
  setDeviceMeasurements: Dispatch<SetStateAction<any>>;
  setDeviceMeasurementsLoading: Dispatch<SetStateAction<boolean>>;
  setTotalSize: Dispatch<SetStateAction<number>>;
  type: string;
}

const getDeviceMeasurement = async ({
  deviceId,
  limit,
  offset,
  currentData,
  setDeviceMeasurements,
  setDeviceMeasurementsLoading,
  setTotalSize,
  type,
}: DeviceMeasurementProps) => {
  if (!currentData) {
    setDeviceMeasurements([]);
  }
  setDeviceMeasurementsLoading(true);
  try {
    if (type === 'water') {
      const deviceMeasurementsResponse = await httpsService.get<Pagination<DeviceMeasurements[]>>(
        `${Endpoint.DEVICE_WATER_MESUREMENTS.replace(':id', deviceId)}/?limit=${limit}&offset=${offset}`
      );

      if (deviceMeasurementsResponse.status === 200) {
        if (deviceMeasurementsResponse.data.results.length > 0) {
          const devMeasurements = deviceMeasurementsResponse.data.results.map(
            (measurement: DeviceMeasurements, i: number) => ({
              ...measurement,
              id: i,
            })
          );
          setDeviceMeasurements(devMeasurements);
          setTotalSize((prevTotalSize) =>
            deviceMeasurementsResponse.data.totalSize !== undefined
              ? deviceMeasurementsResponse.data.totalSize
              : prevTotalSize
          );
        } else {
          setDeviceMeasurements([]);
          setTotalSize(0);
        }
      }
    } else if (type === 'heater') {
      const deviceMeasurementsResponse = await httpsService.get<Pagination<DeviceMeasurements[]>>(
        `${Endpoint.DEVICE_HEATER_MEASUREMENTS.replace(':id', deviceId)}/?limit=${limit}&offset=${offset}`
      );

      if (deviceMeasurementsResponse.status === 200) {
        if (deviceMeasurementsResponse.data.results.length > 0) {
          const devMeasurements = deviceMeasurementsResponse.data.results.map(
            (measurement: DeviceMeasurements, i: number) => ({
              ...measurement,
              id: i,
            })
          );
          setDeviceMeasurements(devMeasurements);
          setTotalSize((prevTotalSize) =>
            deviceMeasurementsResponse.data.totalSize !== undefined
              ? deviceMeasurementsResponse.data.totalSize
              : prevTotalSize
          );
        } else {
          setDeviceMeasurements([]);
          setTotalSize(0);
        }
      }
    }
  } catch (err) {
    console.error(err);
  }
  setDeviceMeasurementsLoading(false);
};

export default getDeviceMeasurement;
