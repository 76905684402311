import { useGetLocationsAssigment } from '@api';
import { DataGrid } from '@mui/x-data-grid';
import { CustomNoRowsOverlay, CustomPagination, FilterBar } from '@components';
import { ModalActionType, ModalContext } from '@contexts';
import { IFilters } from '@api/types';

import { useStyles } from './assign-locator.styles';
import { ListColumns } from './columns';
import { useCallback, useContext, useState } from 'react';
import { AssignModal } from './assign-modal/assign-modal.component';

const limit = 10;

const defaultParams: IFilters = {
  cityId: '',
  streetId: '',
  buildingId: '',
  flatId: '',
};

export const AssignLocator = () => {
  const { classes } = useStyles();
  const [page, setPage] = useState<number>(0);
  const [params, setParams] = useState<IFilters>(defaultParams);

  const { data, isLoading } = useGetLocationsAssigment({
    limit,
    offset: page === 0 ? 0 : page * limit,
    params,
  });
  const { modalDispatch } = useContext(ModalContext);

  const handleParamsChange = useCallback(
    (newParams: IFilters) => {
      setPage(0);
      setParams(newParams);
    },
    [setParams, setPage]
  );

  const handleRowClick = (params: { locationId: string; address: string }) => {
    console.log(params);
    modalDispatch({
      type: ModalActionType.SHOW,
      payload: {
        content: <AssignModal locationId={params.locationId} locationName={params.address} />,
      },
    });
  };

  const Pagination = () => (
    <CustomPagination totalSize={data?.data.totalSize || 0} page={page} setPage={setPage} limit={limit} />
  );

  return (
    <div className={classes.container}>
      <FilterBar
        onChangeParams={handleParamsChange}
        initialParams={defaultParams}
        isReportBar={false}
        isLocatorAssign={true}
        filters={params}
      />
      <DataGrid
        getRowId={(row) => row.locationId}
        rows={data?.data.results || []}
        initialState={{
          pagination: {
            pageSize: 10,
            page: 0,
          },
        }}
        hideFooterSelectedRowCount={true}
        headerHeight={50}
        rowBuffer={0}
        rowHeight={70}
        getRowHeight={() => 'auto'}
        columnBuffer={0}
        columns={ListColumns({ onRowClick: handleRowClick })}
        showColumnRightBorder={false}
        autoHeight={true}
        disableColumnMenu={true}
        disableColumnFilter={true}
        checkboxSelection={false}
        disableSelectionOnClick
        loading={isLoading}
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
          Pagination: Pagination,
        }}
        onPageChange={(page: number) => {
          setPage(page);
        }}
        autoPageSize={true}
        paginationMode="server"
        rowCount={data?.data.totalSize || 0}
        pageSize={limit}
        sx={{
          '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
        }}
      />
    </div>
  );
};
