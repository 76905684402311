import { useContext, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Grid, useMediaQuery, useTheme, Drawer, Divider, Typography, Box } from '@mui/material';
import { PATH } from '@router';
import { Breadcrumbs, OrganizationSwitcher } from '@components';
import { useCurrentCustomer } from '@api/customers';
import { UserContext } from '@contexts';

import { MobileTopbar, MenuContainer } from './Menu';

export const Dashboard: React.FC = () => {
  useCurrentCustomer();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const location = useLocation();
  const isEmptyDashboard = location.pathname === `/${PATH.DASHBOARD}`;
  const { userState } = useContext(UserContext);

  const toggleMobileMenu = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setMobileMenuVisible(open);
  };

  return (
    <Grid container direction={isMobile ? 'column' : 'row'}>
      <Grid container item xs="auto">
        {isMobile ? (
          !mobileMenuVisible && (
            <MobileTopbar currentCustomer={userState.currentCustomer} handleMenu={toggleMobileMenu} />
          )
        ) : (
          <MenuContainer />
        )}
      </Grid>
      <Grid item xs>
        <Drawer anchor={'left'} open={mobileMenuVisible} onClose={toggleMobileMenu(false)}>
          <MenuContainer />
        </Drawer>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Breadcrumbs />
          {!isMobile && <OrganizationSwitcher currentCustomer={userState.currentCustomer} />}
        </Box>
        <Divider />
        {isEmptyDashboard ? (
          <Box
            sx={{
              height: '87.5vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h4" color={theme.palette.common.black}>
              Witamy w Wodmar Platform
            </Typography>
          </Box>
        ) : (
          <Outlet />
        )}
      </Grid>
    </Grid>
  );
};
