import { UseMutationResult, useMutation } from 'react-query';
import { AxiosResponse } from 'axios';
import { getStoredCurrentCustomerData } from '@helpers';

import { queryKey } from './query-key.enum';
import { IPatchCityRequest, updateCityName } from '../infrastructure/patch-city';
import { queryClient } from '../../../react-query';

export const useUpdateCityName = (): UseMutationResult<AxiosResponse, unknown, IPatchCityRequest> => {
  return useMutation<AxiosResponse, unknown, IPatchCityRequest>({
    onSuccess: (data) => {
      const storedCustomer = getStoredCurrentCustomerData();
      queryClient.invalidateQueries([queryKey.CITIES(), storedCustomer?.id]);
    },
    mutationFn: updateCityName,
  });
};
