import { UseMutationResult, useMutation } from 'react-query';
import { AxiosResponse } from 'axios';
import { SnackbarActionType, SnackbarContext, SnackbarType } from '@contexts';
import { useContext } from 'react';

import { activateClient, IActivateClient } from '../infrastructure/activate-client';
import HttpServiceError from '../../../services/httpService/types';
import { queryClient } from '../../../react-query';

export const useActivateClient = (): UseMutationResult<AxiosResponse, HttpServiceError, IActivateClient> => {
  const { snackbarDispatch } = useContext(SnackbarContext);

  return useMutation<AxiosResponse, HttpServiceError, IActivateClient>({
    mutationFn: activateClient,
    onError: (error: HttpServiceError) => {
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: error.response?.data.title || 'Wystąpił błąd podczas aktywacji klienta',
          type: SnackbarType.ERROR,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['clients']);
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: 'Akcja wykonana pomyślnie',
          type: SnackbarType.SUCCESS,
        },
      });
    },
  });
};
