import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';

export interface IPatchExpirationRequest {
  expirationDate: string;
  meterId: string;
}

export const updateWaterMeterExpirationDate = ({
  meterId,
  expirationDate,
}: IPatchExpirationRequest): Promise<AxiosResponse> => {
  return httpsService.patch(Endpoint.WATER_METER_EXPIRATION_DATE.replace(':meterId', meterId), {
    expirationDate,
  });
};
