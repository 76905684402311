import { useEffect, useState } from 'react';
import { Params, useParams } from 'react-router-dom';
import { DeviceAlertsProps } from '@api/types';
import { AlertGrid } from './components';
import getDeviceAlerts from 'src/api/getDeviceAlerts';
import { SkeletonLoader } from '@components';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

const limit = 5;

const Alerts = () => {
  const [deviceAlerts, setDeviceAlerts] = useState<DeviceAlertsProps[]>([]);
  const { id, type } = useParams<Params>();
  const [isDeviceAlertsLoading, setDeviceAlertsLoading] = useState(false);
  const [page, setPage] = useState<number>(0);
  const [totalSize, setTotalSize] = useState<number>(0);
  const [pageMobile, setPageMobile] = useState<number>(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (isMobile) return;
    if (id && type) {
      const offset = page === 0 ? 0 : page * limit;
      getDeviceAlerts({
        deviceId: id,
        limit,
        offset,
        setTotalSize,
        setDeviceAlertsLoading,
        setDeviceAlerts,
        type,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, page]);

  useEffect(() => {
    if (id && type && isMobile) {
      const offset = pageMobile === 0 ? 0 : pageMobile * limit;
      getDeviceAlerts({
        deviceId: id,
        limit,
        offset,
        currentData: deviceAlerts,
        setTotalSize,
        setDeviceAlertsLoading,
        setDeviceAlerts,
        type,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, pageMobile]);

  return (
    <Box>
      <Box sx={{ marginTop: 5, marginBottom: 5 }}>
        <Typography variant="h5"> Wykaz alertów</Typography>
      </Box>
      <Box sx={{ width: '100%' }}>
        {isDeviceAlertsLoading && !isMobile ? (
          <Box sx={{ marginLeft: 10, marginRight: 10 }}>
            <SkeletonLoader loadersCount={16} />
          </Box>
        ) : (
          <AlertGrid
            alerts={deviceAlerts}
            totalSize={totalSize}
            limit={limit}
            page={page}
            pageMobile={pageMobile}
            isDeviceAlertsLoading={isDeviceAlertsLoading}
            setPage={setPage}
            setPageMobile={setPageMobile}
          />
        )}
      </Box>
    </Box>
  );
};

export default Alerts;
