import React, { useContext, useEffect } from 'react';
import { Snackbar as StyledSnackbar, Alert } from './snackbar.styles';
import { SnackbarActionType, SnackbarContext } from '@contexts';

const SNACKBAR_DURATION = 5000;

const Snackbar: React.FC = () => {
  const {
    snackbarState: { visible, message, type },
    snackbarDispatch,
  } = useContext(SnackbarContext);

  useEffect(() => {
    if (visible)
      setTimeout(() => {
        snackbarDispatch({ type: SnackbarActionType.DISMISS });
      }, SNACKBAR_DURATION);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return visible ? (
    <StyledSnackbar>
      <Alert severity={type}>{message}</Alert>
    </StyledSnackbar>
  ) : null;
};

export default Snackbar;
