import { createContext, Dispatch, Reducer } from 'react';
import { SnackbarActionType, SnackbarType } from './types';

type Action =
  | {
      type: SnackbarActionType.SHOW;
      payload: { message: string; type: SnackbarType };
    }
  | {
      type: SnackbarActionType.DISMISS;
    };

type SnackbarState = {
  visible: boolean;
  message: string;
  type: SnackbarType | undefined;
};

type SnackbarAction = Dispatch<Action>;

interface SnackbarContextProps {
  snackbarState: SnackbarState;
  snackbarDispatch: SnackbarAction;
}

const initialState: SnackbarState = {
  visible: false,
  message: '',
  type: undefined,
};

const SnackbarContext = createContext<SnackbarContextProps>({
  snackbarState: initialState,
  snackbarDispatch: () => initialState,
});

const snackbarReducer: Reducer<SnackbarState, Action> = (state, action) => {
  console.log('Action', action.type);

  switch (action.type) {
    case SnackbarActionType.SHOW: {
      const { message, type } = action.payload;
      return {
        ...state,
        message,
        type,
        visible: true,
      };
    }
    case SnackbarActionType.DISMISS: {
      return {
        ...state,
        visible: false,
        message: '',
        type: undefined,
      };
    }
  }
};

export { snackbarReducer, SnackbarContext, initialState };
export { SnackbarAction, SnackbarState };
