import { UseMutationResult, useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

import { changeNetworkWaterMeter, IChangeNetworkRequest } from '../infrastructure/change-water-meter-network';

export const useChangeWaterMeterNetwork = (): UseMutationResult<AxiosResponse, unknown, IChangeNetworkRequest> => {
  return useMutation<AxiosResponse, unknown, IChangeNetworkRequest>({
    mutationFn: changeNetworkWaterMeter,
  });
};
