import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Button } from '@components';
import { useGeneralReport } from '@api/reports';
import { getItem } from '@services';
import { IFilters, LocalStorageKey } from '@api/types';

import { ButtonsContainer, ButtonWrapper } from './buttons.styles';

interface IButtonsProps {
  isReportBar: boolean;
  filters: IFilters;
}

export const Buttons = ({ isReportBar, filters }: IButtonsProps) => {
  let params = isReportBar ? filters || {} : getItem<IFilters>(LocalStorageKey.FILTERS);
  const { mutateAsync: getGeneralReport } = useGeneralReport();

  const handleGenerateReport = () => {
    getGeneralReport(params as IFilters);
  };

  if (!isReportBar) {
    return null;
  }

  return (
    <ButtonsContainer>
      <ButtonWrapper>
        <Button onClick={handleGenerateReport} startIcon={<FileDownloadOutlinedIcon />}>
          Generuj raport
        </Button>
      </ButtonWrapper>
    </ButtonsContainer>
  );
};
