import { UseMutationResult, useMutation } from 'react-query';
import { AxiosResponse } from 'axios';
import { SnackbarActionType, SnackbarContext, SnackbarType } from '@contexts';
import { useContext } from 'react';

import HttpServiceError from '../../../services/httpService/types';
import { queryClient } from '../../../react-query';
import { editClient, IEditClient } from '../infrastructure/edit-client';

export const useEditClient = (): UseMutationResult<AxiosResponse, HttpServiceError, IEditClient> => {
  const { snackbarDispatch } = useContext(SnackbarContext);

  return useMutation<AxiosResponse, HttpServiceError, IEditClient>({
    mutationFn: editClient,
    onError: (error: HttpServiceError) => {
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: error.response?.data.title || 'Wystąpił błąd podczas edycji klienta',
          type: SnackbarType.ERROR,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['clients']);
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: 'Akcja wykonana pomyślnie',
          type: SnackbarType.SUCCESS,
        },
      });
    },
  });
};
