import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';

export interface IPostCityRequest {
  name: string;
}

export const addNewCity = ({ name }: IPostCityRequest): Promise<AxiosResponse> => {
  return httpsService.post(Endpoint.CITIES, { name });
};
