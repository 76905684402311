import { createTheme } from '@mui/material';

const wodmarTheme = createTheme({
  breakpoints: {
    values: {
      xs: 565,
      sm: 930,
      md: 1260,
      lg: 1536,
      xl: 2000,
    },
  },
  palette: {
    primary: {
      light: '#74CAFF',
      main: '#0C53B7',
      dark: '#04297A',
    },
    warning: {
      light: '#FFE16A',
      main: '#B78103',
      dark: '#7A4F01',
    },
    success: {
      light: '#AAF27F',
      main: '#229A16',
      dark: '#08660D',
    },
    error: {
      light: '#FFA48D',
      main: '#B72136',
      dark: '#7A0C2E',
    },
    grey: {
      50: '#FFFFFF',
      100: '#F9FAFB',
      200: '#F4F6F8',
      300: '#DFE3E8',
      400: '#C4CDD5',
      500: '#919EAB',
      600: '#637381',
      700: '#454F5B',
      800: '#212B36',
      900: '#161C24',
    },
    background: {
      default: '#FFFFFF',
    },
  },

  typography: {
    h1: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: '36px',
      fontStyle: 'normal',
      fontWeight: 300,
      lineHeight: '44px',
      letterSpacing: '-1.5px',
    },
    h2: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: '32px',
      fontStyle: 'normal',
      fontWeight: 300,
      lineHeight: '40px',
      letterSpacing: '-0.5px',
    },
    h3: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: '28px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '36px',
      letterSpacing: '0px',
    },
    h4: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '32px',
      letterSpacing: '0px',
    },
    h5: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: '22px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '28px',
      letterSpacing: '0.2px',
    },
    h6: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    subtitle1: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0.1px',
    },
    body1: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.5px',
    },
    body2: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.25px',
    },
    button: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '0.1px',
    },
    caption: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '16px',
      letterSpacing: '0.4px',
    },
    overline: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: '10px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '16px',
      letterSpacing: '1.5px',
    },
  },
});

export default wodmarTheme;
