import { DeviceMeasurements } from '../../types';
import { StyledMeasurementGrid } from './MeasurementsGrid.styles';
import { MeasurementGridColumnsWaterMeter } from './MeasurementsGridColumnsWaterMeter';
import { MeasurementGridColumnsHeatMeter } from './MeasurementsGridColumnsHeatMeter';
import {
  useTheme,
  useMediaQuery,
  DialogContent,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from '@mui/material';
import { ListMobile } from '../ListMobile';
import { CustomNoRowsOverlay, CustomPagination } from '@components';
import { ModalActionType, ModalContext } from 'src/contexts/ModalContext';
import { Dispatch, SetStateAction, useContext } from 'react';
import { GridRowParams } from '@mui/x-data-grid';
import moment from 'moment';
import 'moment/locale/pl';

interface MeasurementGridProp {
  measurements: DeviceMeasurements[];
  deviceType: string;
  limit: number;
  totalSize: number;
  page: number;
  pageMobile: number;
  isDeviceMeasurementsLoading: boolean;
  setPage: Dispatch<SetStateAction<number>>;
  setPageMobile: Dispatch<SetStateAction<number>>;
}

const MeasurementsGrid = ({
  deviceType,
  measurements,
  limit,
  totalSize,
  page,
  pageMobile,
  isDeviceMeasurementsLoading,
  setPage,
  setPageMobile,
}: MeasurementGridProp) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { modalDispatch } = useContext(ModalContext);

  const handleRowClick = (params: GridRowParams) => {
    modalDispatch({
      type: ModalActionType.SHOW,
      payload: {
        content: (
          <DialogContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Data</TableCell>
                  <TableCell>Godzina</TableCell>
                  <TableCell>{deviceType === 'wodomierz' ? 'Przyrost' : 'Stan'}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {deviceType === 'wodomierz'
                  ? measurements[params.row.id].deltaValues.map((increase, i) => {
                      return (
                        <TableRow key={i} hover>
                          <TableCell>{moment.utc(increase.item1).local().format('l')}</TableCell>
                          <TableCell>{moment.utc(increase.item1).local().format('LTS')}</TableCell>
                          <TableCell>{increase.item2} m³</TableCell>
                        </TableRow>
                      );
                    })
                  : measurements[params.row.id].energyDeltaValues.map((increase, i) => {
                      return (
                        <TableRow key={i} hover>
                          <TableCell>{moment.utc(increase.item1).local().format('l')}</TableCell>
                          <TableCell>{moment.utc(increase.item1).local().format('LTS')}</TableCell>
                          <TableCell>{increase.item2} GJ</TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </DialogContent>
        ),
      },
    });
  };

  const Pagination = () => <CustomPagination totalSize={totalSize} page={page} setPage={setPage} limit={limit} />;

  return (
    <>
      {isMobile ? (
        <ListMobile
          deviceType={deviceType}
          measurements={measurements}
          pagination={
            <CustomPagination totalSize={totalSize} page={pageMobile} setPage={setPageMobile} limit={limit} />
          }
          isDeviceMeasurementsLoading={isDeviceMeasurementsLoading}
        />
      ) : (
        <StyledMeasurementGrid
          rows={measurements}
          initialState={{
            pagination: {
              pageSize: limit,
              page: 0,
            },
          }}
          hideFooterSelectedRowCount={true}
          headerHeight={50}
          rowBuffer={0}
          rowHeight={100}
          columnBuffer={0}
          columns={deviceType === 'wodomierz' ? MeasurementGridColumnsWaterMeter : MeasurementGridColumnsHeatMeter}
          showColumnRightBorder={false}
          autoHeight={true}
          disableColumnMenu={true}
          disableColumnFilter={true}
          checkboxSelection={false}
          disableSelectionOnClick
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
            Pagination: Pagination,
          }}
          rowsPerPageOptions={[limit]}
          onRowClick={handleRowClick}
          onPageChange={(page: number) => {
            setPage(page);
          }}
          page={page}
          rowCount={totalSize}
          autoPageSize={false}
          paginationMode="server"
        />
      )}
    </>
  );
};

export default MeasurementsGrid;
