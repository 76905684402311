import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';

export interface IOnboardingInitRequest {
  oldToken: string;
}

export const onboardUserInit = ({ oldToken }: IOnboardingInitRequest): Promise<AxiosResponse> => {
  return httpsService.post(Endpoint.ONBOARD_USER_INIT, { oldToken });
};
