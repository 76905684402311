import { styled, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const DataGridContainer = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  margin: '0 auto',
}));

const StyledDatGrid = styled(DataGrid)(() => ({
  border: 0,
  marginLeft: 5,
  marginRight: 5,
}));

export { DataGridContainer, StyledDatGrid };
