import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Button } from '@mui/material';

import { LocatorChips } from './locator-chips';

interface IListColumnsProps {
  onRowClick: (params: { locationId: string; address: string }) => void;
}

export const ListColumns = ({ onRowClick }: IListColumnsProps): GridColDef[] => {
  return [
    {
      field: 'address',
      headerName: 'Adres',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      flex: 1,
    },
    {
      field: 'locators',
      headerName: 'Przypisani lokatorzy',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <LocatorChips
            locators={params.row.locators}
            locationId={params.row.locationId}
            locationName={params.row.address}
          />
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Akcje',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Button variant="contained" sx={{ width: 200, padding: 1 }} onClick={() => onRowClick(params.row)}>
            Wybierz lokatora
          </Button>
        );
      },
    },
  ];
};
